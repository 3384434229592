import Loader from "../../../Global/Loader";
import DefaultDialog from "../../../Global/DefaultDialog";
import {PageHeaderContainer} from "../CompaniesPage/styledComponents.tsx";
import CTAButton from "../../../Global/Buttons/CTAButton";
import {
 DefaultTable,
 DefaultTableBox, DefaultTableCell,
 DefaultTableHeader,
 DefaultTableHeaderContainer, DefaultTableRow
} from "../../../Global/DefaultTable/styledComponents.tsx";
import {Avatar, IconButton} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomDialog from "../../../Global/CustomDialog";
import {useEffect, useState} from "react";
import {handleRTKError} from "../../../../Shared/Functions/RTKErrorHandler.ts";
import {useGetCompanyBrandsQuery} from "../../../../features/company/companyApiSlice.ts";
import {useSelector} from "react-redux";
import {RootState} from "../../../../Redux/store.ts";
import {IUserInfo} from "../../../../Shared/Interfaces/IUserInfo.ts";
import AddEditBrandSideForm from "./AddEditBrandSideForm.tsx";
import {IBrand} from "../../../../Shared/Interfaces/IBrand.ts";
import {useDeleteBrandMutation} from "../../../../features/brands/brandApiSlice.ts";
import EditIcon from "@mui/icons-material/Edit";

function BrandsPage() {
 const [dialogOpen, setDialogOpen] = useState(false);
 const [deletedBrandID, setDeletedBrandID] = useState<string | number | null>();
 const [isEdit, setIsEdit] = useState(false);
 const [brandItem, setBrandItem] = useState<IBrand | null>(null);
 const [openSideForm, setOpenSideForm] = useState(false);

 const [
  deleteBrand,
  {
   isError: isDeleteError,
   isLoading: isDeleteLoading,
   isSuccess: isDeleteSuccess,
   error: deleteError,
  },
 ] = useDeleteBrandMutation();
 useEffect(() => {
  handleRTKError(isDeleteError, deleteError);
 }, [isDeleteError, deleteError]);

 const handleClickOpen = () => {
  setOpenSideForm(true);
 };

 const handleClose = () => {
  refetch();
  setOpenSideForm(false);
  setIsEdit(false)
 };
 const openDialHandler = (brandID: string | number) => {
  setDialogOpen(true);
  setDeletedBrandID(brandID);
 };
 const deleteBrandHandler = (brandID: string | number) => {
  deleteBrand({brandID, companyID:userInfo.companyID});
 };

 const confirmDeleteBrand = async () => {
  setDialogOpen(false);
  deleteBrandHandler(deletedBrandID as string);
 };
 const userInfo = useSelector(
     (state: RootState) => state.auth.userInfo
 ) as IUserInfo;

 const { data, isError, isFetching, isLoading, error, refetch } =
     useGetCompanyBrandsQuery(userInfo.companyID);
 useEffect(() => {
  handleRTKError(isError, error);
 }, [isError, error]);
 useEffect(() => {
  if (isDeleteSuccess) {
   refetch();
  }
 }, [isDeleteSuccess]);

 const onEditBrand = (brandID: string) => {
  const brandData = data.find((brand: IBrand) => brand?._id?.toString() === brandID);
  setOpenSideForm(true);
  setIsEdit(true);
  setBrandItem(brandData);
 }
 return (
     <div>
      {(isFetching || isLoading || isDeleteLoading) && <Loader />}
      <DefaultDialog
          open={dialogOpen}
          setOpen={setDialogOpen}
          dialogTitle="Do You Want Delete This Brand ?"
          dialogText="If you deleted a brand, you won't able to restore it"
          confirmFn={confirmDeleteBrand}
      />
      <PageHeaderContainer>
       {["owner", "company"].includes(userInfo.userRole) && (
           <CTAButton action={handleClickOpen} buttonText={"Add Brand"} />
       )}
      </PageHeaderContainer>
      <DefaultTableBox>
       <DefaultTableHeaderContainer>
        <DefaultTableHeader style={{ width: "calc(100% / 4)" }}>
         Image
        </DefaultTableHeader>
        <DefaultTableHeader style={{ width: "calc(100% / 4)" }}>
         Name
        </DefaultTableHeader>
        <DefaultTableHeader style={{ width: "calc(100% / 4)" }}>
         Email
        </DefaultTableHeader>
        <DefaultTableHeader style={{ width: "calc(100% / 4)" }}>
         Actions
        </DefaultTableHeader>
       </DefaultTableHeaderContainer>
       <DefaultTable>
        <tbody>
        {data?.map((brand: IBrand, idx: number) => {
         return (
             <DefaultTableRow key={idx}>
              <DefaultTableCell>
               <Avatar src={brand?.logoPath}></Avatar>
              </DefaultTableCell>
              <DefaultTableCell>
               {brand?.name}
              </DefaultTableCell>
              <DefaultTableCell>{brand?.brandAdminEmail}</DefaultTableCell>
              <DefaultTableCell>
               <IconButton onClick={() => openDialHandler(brand?._id)}>
                <DeleteIcon />
               </IconButton>
               <IconButton>
                <EditIcon onClick={() => onEditBrand(brand?._id?.toString() as string)}/>
               </IconButton>
              </DefaultTableCell>
             </DefaultTableRow>
         );
        })}
        </tbody>
       </DefaultTable>
       {/*<DefaultTableFooter count={data?.pagesCount} setPageNumber={setPageNumber} />*/}
      </DefaultTableBox>

      <CustomDialog open={openSideForm} handleClose={handleClose}  titleText={`${isEdit ? "Edit" : "Add"} Brand`}>
       <AddEditBrandSideForm handleClose={handleClose} existingBrands={data?.length}  isEdit={isEdit}  brandItem={brandItem as IBrand}/>
      </CustomDialog>
     </div>
 );
}

export default BrandsPage;

import { apiSlice } from "../apiSlice";

export const categoriesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllCategories: builder.query({
      query: () => "/categories",
    }),
    deleteCategory: builder.mutation({
      query: (categoryID: string) => ({
        url: `/categories/${categoryID}`,
        method: 'DELETE',
      })
    }),
    updateCategory: builder.mutation({
      query: (payload) => ({
        url: '/categories',
        method: 'PUT',
        body: payload
      })
    }),
    addCategory: builder.mutation({
      query: (payload) => ({
        url: "/categories",
        method: 'POST',
        body: payload,
      })
    })
  }),
});

export const { 
  useGetAllCategoriesQuery, 
  useDeleteCategoryMutation, 
  useUpdateCategoryMutation,
  useAddCategoryMutation,
} = categoriesApiSlice;

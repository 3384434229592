import Lottie from "lottie-react";
import { Stack, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { Wheel } from "spin-wheel";

import { HeaderText } from "./styledComponents";
import { IWheelOption } from "../../../../Shared/Interfaces/IWheel";
import { handleRTKError } from "../../../../Shared/Functions/RTKErrorHandler";
import ActiveAmazing from "../../../../Assets/RatingPage/Rating/amazingLottie.json";
import {
  useGetWheelOptionsQuery,
  useSendingPrizeToEmailMutation,
  useUpdateRatingMutation,
} from "../../../../features/customerRating/customerRatingApiSlice";
import DefaultAmazing from "../../../../Assets/RatingPage/Rating/defaultAmazing.svg?react";
import { WheelOptionType } from "../../../../Shared/Constants/enums";
import DefaultDialog from "../../../Global/DefaultDialog";
import CustomerDataForm from "./customerDataForm";
import { IRating } from "../../../../Shared/Interfaces/IRating";

interface IProp {
  rating: IRating;
  customerDataFilled: boolean;
}

const WheelPage = ({ rating, customerDataFilled }: IProp) => {
  console.log("THE RATING : ", rating);
  const [wheel, setWheel] = useState<any>();
  const [open, setOpen] = useState(false);
  const [wheelVisible, setWheelVisible] = useState(true);
  const [wheelClicked, setWheelClicked] = useState(false);

  const containerRef = useRef(null);
  const emailRef = useRef<HTMLInputElement | null>(null);
  const fullNameRef = useRef<HTMLInputElement | null>(null);
  const mobileNumberRef = useRef<HTMLInputElement | null>(null);

  const { data, isError, error } = useGetWheelOptionsQuery({});
  const [updateRating, { isError: ratingIsError, error: ratingError }] =
    useUpdateRatingMutation();
  const [sendPrize] = useSendingPrizeToEmailMutation();

  const { t } = useTranslation();

  const handleOption = (option: IWheelOption) => {
    switch (option.type) {
      case WheelOptionType.COOKIE:
        setWheelVisible(false);
        break;
      case WheelOptionType.DISCOUNT:
        if (customerDataFilled) {
          sendPrize({
            email: rating.customerInfo?.email,
            prizeType: "prize",
          });
        } else {
          setOpen(true);
        }
        break;
      case WheelOptionType.PRIZE:
        if (customerDataFilled) {
          sendPrize({
            email: rating.customerInfo?.email,
            prizeType: "prize",
          });
        } else {
          setOpen(true);
        }
        break;
      case WheelOptionType.GOOD_LUCK:
      default:
        break;
    }
  };

  const onClickHandler = () => {
    const duration = 4000;
    wheel.spinToItem(data?.winnerIdx, duration, true, 2, 1);
    setWheelClicked(true);
    const handler = setTimeout(() => {
      const idx = data.winnerIdx;
      handleOption(data.options.items[idx]);
      clearTimeout(handler);
    }, duration);
  };

  const submitHandler = () => {
    const idx = data.winnerIdx;
    const option = data.options.items[idx] as IWheelOption;
    if (
      fullNameRef.current?.value &&
      emailRef.current?.value &&
      mobileNumberRef.current?.value
    ) {
      updateRating({
        ratingID: rating?._id?.toString(),
        payload: {
          wheelOption: option.type,
          customerInfo: {
            name: fullNameRef.current.value,
            email: fullNameRef.current.value,
            phone: fullNameRef.current.value,
          },
        },
      });

      sendPrize({
        email: emailRef.current.value,
        prizeType: "prize",
      });

      cancelHandler();
    } else {
      alert("Complete all fields");
    }
  };

  const cancelHandler = () => {
    setOpen(false);
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container && data?.options) {
      setWheel(new Wheel(container, data?.options));
    }
  }, [data]);

  useEffect(() => {
    handleRTKError(isError, error);
  }, [isError, error]);

  useEffect(() => {
    handleRTKError(ratingIsError, ratingError);
  }, [ratingError, ratingIsError]);

  return (
    <>
      {wheelVisible && (
        <>
          {!customerDataFilled && (
            <DefaultDialog
              open={open}
              setOpen={setOpen}
              confirmText="Submit"
              confirmFn={submitHandler}
              cancelFn={cancelHandler}
              content={
                <CustomerDataForm
                  isOptional={false}
                  subHeader="We take this info to send you the prize to your email"
                  fullNameRef={fullNameRef}
                  mobileNumberRef={mobileNumberRef}
                  emailRef={emailRef}
                />
              }
            />
          )}
          <Stack gap={5} sx={{ paddingTop: 8 }}>
            <Stack>
              <HeaderText>{t("wheelPage.header")} </HeaderText>
              <br />
              {/* <BodyText>{t("wheelPage.subHeader")}</BodyText> */}
            </Stack>
            <Box ref={containerRef} className="wheel-container">
              <Stack
                justifyContent="space-between"
                alignItems="center"
                className="overlay"
              >
                <Box className="pointer"></Box>
                <Box className="center-container" onClick={onClickHandler}>
                  {wheelClicked ? (
                    <Lottie
                      style={{ width: "55px", height: "55px" }}
                      loop={false}
                      animationData={ActiveAmazing}
                    />
                  ) : (
                    <DefaultAmazing />
                  )}
                </Box>
                <Box
                  sx={{
                    width: "50px",
                    height: "50px",
                  }}
                ></Box>
              </Stack>
            </Box>
          </Stack>
        </>
      )}
    </>
  );
};

export default WheelPage;

import { styled } from '@mui/material/styles';
import { ICardContainer } from './../../../Shared/Interfaces/ICardContainer';


export const CardContainerDiv = styled("div", 
  {
    shouldForwardProp: () => true,
    name: 'CardContainerDiv',
    slot: 'Root',
  })<ICardContainer>(({ flexDirection = 'column', height = 'auto', justifyContent = 'space-between', theme }) => ({
  padding: theme.spacing(5),
  gap: theme.spacing(4),
  borderRadius: theme.spacing(4),
  boxSizing: 'border-box',
  backgroundColor: theme.palette.common.white,
  boxShadow: "0px 4px 20px rgba(153, 153, 153, 0.4)",
  width: '100%',
  height: height,
  display: 'flex',
  flexDirection: flexDirection,
  justifyContent: justifyContent,
  color: theme.palette.common.black,
}));
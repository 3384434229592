import { apiSlice } from "../apiSlice";

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addUser: builder.mutation({
      query: (payload) => ({
        url: "/users",
        method: "POST",
        body: payload,
      }),
    }),
    activateDeactivateUser: builder.mutation({
      query: ({ id, isActive }) => ({
        url: `/users/${id}/active/${isActive}`,
        method: "PATCH",
      }),
    }),
    getAllUsers: builder.query({
      query: (queryParams) => `/users${queryParams}`,
    }),
    deleteUser: builder.mutation({
      query: (userID) => ({
        url: `/users/${userID}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useAddUserMutation,
  useActivateDeactivateUserMutation,
  useGetAllUsersQuery,
  useDeleteUserMutation,
} = userApiSlice;

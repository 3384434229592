import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, Select, TextField, styled } from "@mui/material";
import { useState } from "react";

const CssTextField = styled(TextField)(() => ({
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderWidth: "3px",
            borderRadius: "12px",
            transition: "0.3s border-color ease-in-out",
        },
        "&:hover fieldset": {
            borderWidth: "3px",
        },
        "&.Mui-focused fieldset": {
            borderWidth: "3px",
        },
    },
}));

function DefaultInput({
    label,
    error = false,
    passwordCheck = false,
    disabled = false,
    register,
    errorText,
    type = "text",
    select= false,
    onChange = () => {},
    children,
}: {
    label: string;
    error?: boolean;
    passwordCheck?: boolean;
    disabled?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    register: any,
    errorText?: string,
    type?: string,
    select?: boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange?: (...params: any) => void,
    children?: React.ReactNode | React.ReactNode[],
}) {
    const [passwordVisibility, setPasswordVisibility] = useState(false);

    function handleClickShowPassword() {
        setPasswordVisibility(!passwordVisibility);
    }

    return (
        <>
            {!select && passwordCheck === false && (
                <CssTextField
                    style={{ transition: "0.2s ease-in-out border-color" }}
                    fullWidth
                    label={label}
                    variant="outlined"
                    error={error}
                    {...register}
                    helperText={error ? errorText : ""}
                    disabled={disabled}
                    type={type}
                />
            )}
            {!select && passwordCheck === true && (
                <FormControl fullWidth>
                    <InputLabel error={error} htmlFor="outlined-adornment-password">
                        {label}
                    </InputLabel>
                    <OutlinedInput
                        {...register}
                        type={passwordVisibility ? "text" : "password"}
                        fullWidth
                        error={error}
                        sx={{
                            "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                borderWidth: "3px",
                                borderRadius: "12px",
                                transition: "0.3s border-color ease-in-out",
                            },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                                borderWidth: "3px",
                            },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderWidth: "3px",
                            },
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    // onMouseDown={handleMouseDownPassword}
                                    edge="end">
                                    {passwordVisibility ? <VisibilityOff /> : <Visibility color="primary" />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Password"
                    />
                </FormControl>
            )}
            { select && (
                <FormControl fullWidth>
                    <InputLabel error={error}>{label}</InputLabel>
                    <Select
                        {...register}
                        error={error}
                        fullWidth
                        label={label}
                        sx={{
                            borderWidth: "3px",
                            borderRadius: "12px",
                            transition: "0.3s border-color ease-in-out",
                        }}
                        onChange={onChange}
                    >
                        {children}
                    </Select>
                    {error && <FormHelperText sx={{ color: 'red' }}>{error ? errorText : ""}</FormHelperText>}
                </FormControl>
            )}
        </>
    );
}

export default DefaultInput;

// endAdornment={
//     <InputAdornment position="end">
//       <IconButton
//       aria-label="toggle password visibility"
//       onClick={handleClickShowPassword}
//       // onMouseDown={handleMouseDownPassword}
//       >
//       {passwordVisibility ? <Visibility /> : <VisibilityOff />}
//       </IconButton>
//     </InputAdornment>
//   }

import { styled } from "@mui/material/styles";

export const ForgotPasswordBoxContainer = styled("div")(({ theme }) => ({
    width: 600,
    minHeight: 100,
    padding: theme.spacing(8),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    backgroundColor: "white",
    boxShadow: "0px 0px 4px #E6E6E6",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
    justifyContent: "center",
    alignItems: "center",
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
        margin: "24px",
    },
}));
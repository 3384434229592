import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Card = styled(Stack)(({theme}) => ({
  flexDirection: "column",
  padding: theme.spacing(5),
  gap: theme.spacing(6),
  borderRadius: theme.spacing(2),
  boxSizing: 'border-box',
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
  boxShadow: "0px 4px 20px rgba(153, 153, 153, 0.4)",
  // maxWidth: "25%",
  
}));
import { styled } from "@mui/material/styles";

export const DefaultTableBox = styled("div")(() => ({
    width: "100%",
    boxSizing: "border-box",
    backgroundColor: "#FDFDFD",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    boxShadow: "0px 4px 20px rgba(153, 153, 153, 0.4)",
    borderRadius: "16px",
}));

export const DefaultTableHeaderContainer = styled("div")(() => ({
    width: "100%",
    boxSizing: "border-box",
    height: "52px",
    borderBottom: "2px solid #F2F2F2",
    display: "flex",
    alignItems: "center",
    paddingLeft: "24px",
    paddingRight: "24px",
    marginTop: "8px",
}));

export const DefaultTableHeader = styled("div")(({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "13px",
    color: theme.palette.primary.main,
    marginBottom: "0",
    border: "none",
    fontFamily: theme.typography.fontFamily,
    display: "flex",
    alignItems: "center",
}));

export const DefaultTable = styled("table")(() => ({
    width: "100%",
    borderCollapse: "separate",
    height: "fit-content",
    tableLayout: "fixed",
    paddingLeft: "24px",
    paddingRight: "24px",
    background: "#fdfdfd",
    border: "none",
    marginTop: "8px",
    marginBottom: "8px",
}));

export const DefaultTableRow = styled("tr")(() => ({
    background: "#FDFDFD",
    transition: "all ease-in-out 0.4s",
    borderBottom: "none",
    padding: "16px 0 !important",
}));

export const DefaultTableCell = styled("td")(({ theme }) => ({
    margin: 0,
    padding: "12px 0",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    borderBottom: "1px solid #f2f2f2",
    background: "none",
    color: "#4d4d4d",
    overflowWrap: "break-word",
    transition: "all 0.3s ease-in-out",
    fontFamily: theme.typography.fontFamily,
}));
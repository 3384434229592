import Cookies from "universal-cookie";

export function setLanguage(language: string, name: string = 'lang') {
    const cookie = new Cookies();
    const currentLang = cookie.get(name);
    if (currentLang) {
        cookie.remove(name);
        cookie.set(name, language);
    } else {
        cookie.set(name, language);
    }
    window.location.reload();
}

export function getLanguage(name: string = 'lang') {
    const cookie = new Cookies();
    const lang = cookie.get(name);
    if (lang === undefined) {
        setLanguage("en");
        return "en";
    } else {
        return lang;
    }
}

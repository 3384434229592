import { createSlice } from "@reduxjs/toolkit";
import { IUserDetails } from '../../Shared/Interfaces/IUser';


export const userSlice = createSlice({
    name: "user",
    initialState: {
        usersList: [] as IUserDetails[],
    },
    reducers: {
        setUsersList: (state, action) => {
            state.usersList = action.payload;
        },
        toggleUserActivationInList: (state, action) => {
            state.usersList[action.payload.index].isActive = action.payload.newActive;
        },
        
    },
});

export const { setUsersList, toggleUserActivationInList } = userSlice.actions;

export default userSlice.reducer;

import { apiSlice } from '../apiSlice';

export const packagesApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        addPackage: builder.mutation({
            query: (payload) => ({
                url: '/packages',
                method: 'POST',
                body: payload,
            }),
        }),
        deletePackage: builder.mutation({
            query: (packageID: string) => ({
                url: `/packages/${packageID}`,
                method: 'DELETE'
            })
        }),
        updatePackage: builder.mutation({
            query: (payload) => ({
                url: '/packages',
                method: 'PUT',
                body: payload,
            }),
        }),
        getAllPackages: builder.query({
            query: () => '/packages',
        }),
        getPackageByName: builder.query({
            query: ({ lang, packageName }) => `/${lang}/${packageName}`,
        }),
        getPackageById: builder.query({
            query: (packageID) => `/packages/${packageID}`,
        }),
    }),
});

export const {
    useAddPackageMutation,
    useDeletePackageMutation,
    useUpdatePackageMutation,
    useGetAllPackagesQuery,
    useGetPackageByNameQuery,
    useGetPackageByIdQuery,
} = packagesApiSlice;

import { useEffect } from 'react'
import { CustomDialogSubContainer } from '../../../Global/CustomDialog/styledComponents'
import Loader from '../../../Global/Loader'
import DefaultInput from '../../../Global/DefaultInput'
import DefaultForm from '../../../Global/DefaultForm'
import CTAButton from '../../../Global/Buttons/CTAButton'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { handleRTKError } from '../../../../Shared/Functions/RTKErrorHandler'
import { ICategory } from '../../../../Shared/Interfaces/ICategory'
import { useAddCategoryMutation, useUpdateCategoryMutation } from '../../../../features/category/categoryApiSlice'
import { enqueueSnackbar } from 'notistack'

const addUpdateUserSchema = z.object({
  categoryName: z.string().min(1, "Required"),
  categoryNameAr: z.string().min(1, "Required"),
});

type FormType = z.infer<typeof addUpdateUserSchema>;

interface IProp {
  actionAfterSubmit: () => void;
  categoryItem?: ICategory;
  isEdit?: boolean;
}

const AddEditCategorySideForm = ({actionAfterSubmit, categoryItem, isEdit = false}: IProp) => {

  const [addCategory, {isError, isSuccess, error, isLoading}] = useAddCategoryMutation();
  const [ updateCategory, {isSuccess: isUpdateSuccess} ] = useUpdateCategoryMutation();

  const {register, handleSubmit, getValues, setValue, formState:{isValid}} = useForm<FormType>({
      resolver: zodResolver(addUpdateUserSchema),
  });


  const handleAddEditPackage = () => {
    const payload: ICategory = {
      name: getValues('categoryName'),
      nameAr: getValues('categoryNameAr'),
    };

    if (isEdit){
      updateCategory({...payload, id: categoryItem?._id });
    }
    else if(isValid){
      addCategory(payload)
    }
  }

  useEffect(() => {

    if (isEdit && categoryItem) {
      setValue("categoryName", categoryItem?.name as string);
      setValue("categoryNameAr", categoryItem?.nameAr as string);
    }
  }, [isEdit, categoryItem]);

  useEffect(() => {
    handleRTKError(isError, error);
  }, [isError, error])

  useEffect(() => {
    if (isSuccess){
      actionAfterSubmit();
      enqueueSnackbar("Added Successfully", {variant: 'success'});
    }
  }, [isSuccess])

  useEffect(()=>{
    if (isUpdateSuccess){
      actionAfterSubmit();
      enqueueSnackbar("Updated Successfully", {variant: 'success'});
    }
  }, [isUpdateSuccess]);

  return (
    <>
    {isLoading && <Loader />}
    {
      <CustomDialogSubContainer>
        {/* {isAddUserLoading && <Loader/>} */}
        <DefaultForm onSubmit={handleSubmit(handleAddEditPackage)}>
            <DefaultInput
                label={"Category Name"}
                register={register("categoryName")}
            />
            <DefaultInput
                label={"Category Name Ar"}
                register={register("categoryNameAr")}
            />
            
            <CTAButton
                buttonText="Submit"
                buttonType="submit"
                fullWidthCheck
            />
        </DefaultForm>
      </CustomDialogSubContainer>
    }
  </>
  )
}

export default AddEditCategorySideForm
import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

// Thankyou Page
export const ThankYouPageContainer = styled(Stack)(() => ({
  width: "375px",
  gap: "24px",
  // border: "1px solid black",
  boxSizing: "border-box",
  alignItems: "center",
  paddingTop: "20px",
}));

export const BodyText = styled("div")(() => ({
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  textAlign: "center",
  color: "#5C5C5C",
}));

export const CookieContainer = styled("div")(() => ({
  boxSizing: "border-box",
  borderRadius: "24px",
  backgroundColor: "#DEEDDF",
  border: "none",
  width: "335px",
  height: "251px",
  padding: "20px 0px",
  textAlign: "center",
}));

// Home Page
export const RatingHomePageContainer = styled(Stack)(() => ({
  width: "375px",
  gap: "32px",
  height:"100%",
  // border: "1px solid black",
  boxSizing: "border-box",
  alignItems: "center",
  padding: "10px 0px 0px 0px",
}));

export const SelectedBranchContainer = styled("div")(() => ({
  boxSizing: "border-box",
  width: "335px",
  height: "56px",
  borderRadius: "16px",
  border: "1px solid #E6E6E6",
  backgroundColor: "#E6E6E688",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "row",
  gap: "4px",
  fontWeight: 400,
  color: "#5C5C5C",
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "-0.5px",
  padding: "0px 12px",
}));

export const BranchName = styled("div")(() => ({
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "-0.5px",
  color: "#0C100C",
}));

export const HeaderContainer = styled(Stack)(() => ({
  gap: "16px",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "center",
  color: "#0C100C",
}));

export const HeaderText = styled("div")(() => ({
  fontSize: "32px",
  fontWeight: 600,
  lineHeight: "24px",
  textAlign: "center",
  letterSpacing: "-0.5px",
}));

export const FortuneContainer = styled(Stack)(() => ({
  boxSizing: "border-box",
  backgroundColor: "#DEEDDF",
  borderRadius: "24px",
  width: "335px",
  gap: "16px",
  alignItems: "center",
  padding: "50px 0px 10px 0px",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
  color: "#5C5C5C",
  marginTop: "40px",
}));

export const SubHeader = styled("div")(() => ({
  fontWeight: 600,
  fontSize: "24px",
  lineHeight: "32px",
  letterSpacing: "-0.24px",
  color: "#0C100C",
}));

export const InfoContainer = styled(Stack)(() => ({
  gap: "8px",
  width: "303px",
  flexDirection: "row",
  justifyContent: "space-between",
  color: "#0C100C",
}));

export const IconContainer = styled(Stack)(() => ({
  gap: "4px",
  alignItems: "center",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
}));

// Rating Form
export const RatingFormContainer = styled(Stack)(() => ({
  width: "375px",
  gap: "32px",
  // border: "1px solid black",
  boxSizing: "border-box",
  paddingTop: "10px",
}));

export const FormFieldContainer = styled(Stack)(() => ({
  width: "100%",
  gap: "24px",
  boxSizing: "border-box",
  padding: "0px 20px 4px 20px",
}));

export const CustomerSectoinContainer = styled(Stack)(() => ({
  width: "100%",
  boxSizing: "border-box",
  padding: "0px 20px 0px 20px",
  gap: "32px",
}));

export const CustomerInfoFormContainer = styled(Stack)(() => ({
  boxSizing: "border-box",
  width: "100%",
  gap: "16px",
}));

export const CustomerInfoFormHeaderContainer = styled(Stack)(() => ({
  boxSizing: "border-box",
  width: "100%",
}));

export const CustomerInfoFormHeaderTextOptional = styled("div")(() => ({
  boxSizing: "border-box",
  width: "100%",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "24px",
  textAlign: "center",
  color: "#AEAEAE",
}));

export const CustomerInfoFormHeaderText = styled("div")(() => ({
  boxSizing: "border-box",
  width: "100%",
  fontWeight: 600,
  fontSize: "24px",
  lineHeight: "36px",
  letterSpacing: "-0.5px",
  textAlign: "center",
}));

export const CustomerTextFieldsContainer = styled(Stack)(() => ({
  boxSizing: "border-box",
  gap: "24px",
}));

export const TextFieldContainer = styled(Stack)(() => ({
  boxSizing: "border-box",
  gap: "4px",
}));

export const TextFieldLabel = styled("label")(() => ({
  boxSizing: "border-box",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "-0.5px",
  textTransform: "capitalize",
}));

export const TextFieldInput = styled("input")(() => ({
  boxSizing: "border-box",
  height: "56px",
  borderRadius: "16px",
  border: "1px solid #E6E6E6",
  backgroundColor: "#E6E6E688",
  padding: "12px 16px 12px 16px",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "-0.5px",
  "&::placeholder": {
    fontWeight: 400,
  },
}));

interface IButtonProps {
  color?: string;
  width?: string;
  background?: string;
  border?: string;
}

export const Button = styled("button")<IButtonProps>(
  ({
    color = "#fff",
    width = "335px",
    background = "linear-gradient(87.83deg, #425D48 -13.75%, #558357 97.93%)",
    border = "none",
  }) => ({
    boxSizing: "border-box",
    width: width,
    height: "56px",
    borderRadius: "16px",
    // padding: "13px 124px 13px 124px",
    background: background,
    border: border,
    cursor: "pointer",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: color,
    textAlign: "center",
  })
);

export const Divider = styled("div")(() => ({
  border: "3px solid #e6e6e6",
  width: "100%",
  boxSizing: "border-box",
}));

import { Button } from "@mui/material";

function TextButton({
    buttonText,
    action = () => {},
    fontSize = "14px",
    buttonType = "button",
    color = 'white',
}: {
    buttonText: string;
    action?: () => void;
    fontSize?: string;
    buttonType?: string;
    color?: string;
}) {
    return (
        <Button
            id="button"
            type={buttonType === "submit" ? "submit" : "button"}
            variant="text"
            style={{ fontSize: fontSize, textTransform: "none" }}
            onClick={action}
            sx={{
                color: {color}
            }}
            >
            {buttonText}
        </Button>
    );
}

export default TextButton;

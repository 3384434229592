/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import React, { Dispatch, ReactNode, SetStateAction } from "react";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IDefaultDialog {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  dialogTitle?: string;
  dialogText?: string;
  content?: ReactNode;
  confirmText?: string;
  cancelText?: string;
  confirmFn: () => void;
  cancelFn?: () => void;
}

const DefaultDialog = ({
  open,
  setOpen,
  dialogTitle,
  dialogText,
  confirmText = "Confirm",
  confirmFn,
  cancelText = "Cancel",
  cancelFn,
  content,
}: IDefaultDialog) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogText}</DialogContentText>
        {content}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={confirmFn}>
          {confirmText}
        </Button>
        <Button onClick={cancelFn ? cancelFn : handleClose}>
          {cancelText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DefaultDialog;

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IUserInfo } from "../../../../Shared/Interfaces/IUserInfo";
import { IUserDetails } from "../../../../Shared/Interfaces/IUser";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import CTAButton from "../../../Global/Buttons/CTAButton";
import CustomDialog from "../../../Global/CustomDialog";
import DefaultTableFooter from "../../../Global/DefaultTable/DefaultPaginator";
import {
  DefaultTableBox,
  DefaultTableHeaderContainer,
  DefaultTableHeader,
  DefaultTable,
  DefaultTableRow,
  DefaultTableCell,
} from "../../../Global/DefaultTable/styledComponents";
import Loader from "../../../Global/Loader";
import { PageHeaderContainer } from "../CompaniesPage/styledComponents";
import { getLanguage } from "../../../../Shared/Functions/language";
import { setTranslatedText } from "../../../../Shared/Functions/setTranslatedText";
import AddEditUserSideForm from "./AddEditUserSideForm";
import { RootState } from "../../../../Redux/store";
import DefaultDialog from "../../../Global/DefaultDialog";
import { handleRTKError } from "../../../../Shared/Functions/RTKErrorHandler.ts";
import ActiveSwitch from "../../../../features/ActiveSwitch.tsx";
import {
  useActivateDeactivateUserMutation,
  useDeleteUserMutation,
  useGetAllUsersQuery,
} from "../../../../features/user/userApiSlice.ts";

interface ITranslation {
  addUser: string;
  name: string;
  email: string;
  userType: string;
  active: string;
  actions: string;
}

function UsersPage() {
  const lang = getLanguage();
  const staticTextKeys = [
    "addUser",
    "name",
    "email",
    "userType",
    "active",
    "actions",
  ];
  const staticText = setTranslatedText(lang, staticTextKeys) as ITranslation;

  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deletedUserID, setDeletedUserID] = useState<string | number | null>();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(10);

  const userInfo = useSelector(
    (state: RootState) => state.auth.userInfo
  ) as IUserInfo;

  const [
    deleteUser,
    {
      isError: isDeleteError,
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
      error: deleteError,
    },
  ] = useDeleteUserMutation();
  useEffect(() => {
    handleRTKError(isDeleteError, deleteError);
  }, [isDeleteError, deleteError]);
  const queryParams =
    "?companyID=" +
    userInfo.companyID +
    "&pageSize=" +
    pageSize +
    "&pageNumber=" +
    pageNumber;
  const { data, isError, isFetching, isLoading, error, refetch } =
    useGetAllUsersQuery(queryParams);
  useEffect(() => {
    handleRTKError(isError, error);
  }, [isError, error]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (_event: Event, reason: string) => {
    if (reason && reason === "backdropClick") return;
    refetch();
    setOpen(false);
  };

  const openDialHandler = (userID: string | number) => {
    setDialogOpen(true);
    setDeletedUserID(userID);
  };

  const deleteUserHandler = (userID: string | number) => {
    deleteUser(userID);
  };

  const confirmDeleteUser = async () => {
    setDialogOpen(false);
    deleteUserHandler(deletedUserID as string);
  };
  useEffect(() => {
    if (isDeleteSuccess) {
      refetch();
    }
  }, [isDeleteSuccess]);

  return (
    <div>
      {(isFetching || isLoading || isDeleteLoading) && <Loader />}
      <DefaultDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        dialogTitle="Do You Want Delete This User ?"
        dialogText="If you deleted a user, you won't able to restore it"
        confirmFn={confirmDeleteUser}
      />
      <PageHeaderContainer>
        {["owner", "company"].includes(userInfo.userRole) && (
          <CTAButton action={handleClickOpen} buttonText={staticText.addUser} />
        )}
      </PageHeaderContainer>
      <DefaultTableBox>
        <DefaultTableHeaderContainer>
          <DefaultTableHeader style={{ width: "calc(100% / 5)" }}>
            {staticText.name}
          </DefaultTableHeader>
          <DefaultTableHeader style={{ width: "calc(100% / 5)" }}>
            {staticText.email}
          </DefaultTableHeader>
          <DefaultTableHeader style={{ width: "calc(100% / 5)" }}>
            Company
          </DefaultTableHeader>
          <DefaultTableHeader style={{ width: "calc(100% / 5)" }}>
            {staticText.userType}
          </DefaultTableHeader>
          <DefaultTableHeader style={{ width: "calc(100% / 5)" }}>
            {staticText.active}
          </DefaultTableHeader>
          <DefaultTableHeader style={{ width: "calc(100% / 5)" }}>
            {staticText.actions}
          </DefaultTableHeader>
        </DefaultTableHeaderContainer>
        <DefaultTable>
          <tbody>
            {data?.result?.map((user: IUserDetails, idx: number) => {
              return (
                <DefaultTableRow key={idx}>
                  <DefaultTableCell>
                    {user.firstName + " " + user.lastName}
                  </DefaultTableCell>
                  <DefaultTableCell>{user.email}</DefaultTableCell>
                  <DefaultTableCell>
                    {user.companyName ?? "--"}
                  </DefaultTableCell>
                  <DefaultTableCell>{user.userRole}</DefaultTableCell>
                  <DefaultTableCell>
                    <ActiveSwitch
                      entity={user}
                      mutation={useActivateDeactivateUserMutation}
                    />
                  </DefaultTableCell>
                  <DefaultTableCell>
                    <IconButton onClick={() => openDialHandler(user._id)}>
                      <DeleteIcon />
                    </IconButton>
                  </DefaultTableCell>
                </DefaultTableRow>
              );
            })}
          </tbody>
        </DefaultTable>
        <DefaultTableFooter count={data?.pagesCount} setPageNumber={setPageNumber} />
      </DefaultTableBox>

      {/* Dialog Area */}
      <CustomDialog open={open} handleClose={handleClose} titleText="Add User">
        <AddEditUserSideForm handleClose={handleClose} existingUsers={data?.result?.length}/>
      </CustomDialog>
    </div>
  );
}

export default UsersPage;

import { useTranslation } from "react-i18next";
import { Box, Stack, Typography } from "@mui/material";

import EvalurateLogo from "../../../../Assets/RatingPage/logo.png";
import { Button } from "./styledComponents";

const RatingHeader = () => {
  const { i18n } = useTranslation();

  const toggleLanguage = () => {
    const newLanguage = i18n.language === "en" ? "ar" : "en";
    i18n.changeLanguage(newLanguage);
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        padding: "5px 0px",
        boxSizing: "border-box",
        borderBottom: "1px solid #E6E6E6",
      }}
    >
      <Stack
        width="335px"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="row" alignItems="baseline" gap={1}>
          <img
            src={EvalurateLogo}
            width="50px"
            height="40px"
            alt="evalurate logo"
          />
          <Typography
            sx={{
              fontWeight: 700,
              letterSpacing: "-0.7px",
              fontFamily: ["Noto Sans", "sans-serif"],
            }}
          >
            EVALURATE
          </Typography>
        </Stack>
        <Box width="40px" height="40px">
          <Button
            sx={{ width: "40px", height: "40px", marginTop: "2px" }}
            onClick={toggleLanguage}
          >
            {i18n.language === "en" ? "ع" : "En"}
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default RatingHeader;

import {configureStore} from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./rootReducer";
import {apiSlice} from "../features/apiSlice.ts";


const persistConfig = {
    key: "root",
    storage,
    blacklist: ['company', 'user', 'ratingPage', 'dashboard', 'analytics', 'api']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export type RootState = ReturnType<typeof rootReducer>;

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }).concat(apiSlice.middleware),
});

export const persistor = persistStore(store);

// export const Reduxium = createWrapper(makeStore, { debug: true });

import { useEffect } from "react";
import DefaultInput from "../../../Global/DefaultInput";
import { LoginBoxContainer } from "./styledComponents";
import "./styles.css";
import CTAButton from "../../../Global/Buttons/CTAButton";
import { useSnackbar } from "notistack";
import {
  getLanguage,
  setLanguage,
} from "../../../../Shared/Functions/language";
import Loader from "../../../Global/Loader";
import { useDispatch } from "react-redux";
import {
  getAccessToken,
  setAccessToken,
} from "../../../../Shared/Functions/validateToken";
import { useNavigate } from "react-router-dom";
import { setTranslatedText } from "../../../../Shared/Functions/setTranslatedText";
import DefaultForm from "../../../Global/DefaultForm";
import TextButton from "../../../Global/Buttons/TextButton";
import { setUserInfo } from "../../../../Redux/Reducers/authReducer";
import { customNavigator } from "../../../../Shared/Functions/customNavigator";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { TitleText } from "../../../Global/Text/TitleText";
import { useLoginMutation } from "../../../../features/auth/authApiSlice.ts";
import { IUserInfo } from "../../../../Shared/Interfaces/IUserInfo.ts";
import { handleRTKError } from "../../../../Shared/Functions/RTKErrorHandler.ts";

interface ITranslation {
  email: string;
  password: string;
  login: string;
  emailIsRequired: string;
  passwordIsRequired: string;
  invalidEmail: string;
  welcome: string;
  signup: string;
}

const loginFormSchema = z.object({
  email: z.string().min(1, "required").email("not email"),
  password: z.string().min(1, "required"),
});

function LoginPage() {
  const lang = getLanguage();
  const staticTextKeys = [
    "email",
    "password",
    "login",
    "emailIsRequired",
    "passwordIsRequired",
    "invalidEmail",
    "welcome",
    "signup",
  ];
  const staticText = setTranslatedText(lang, staticTextKeys) as ITranslation;

  // const { loading, setLoading } = useLoading();
  // setLoading(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { register, handleSubmit, formState } = useForm({
    defaultValues: { email: "", password: "" },
    resolver: zodResolver(loginFormSchema),
  });
  const { errors } = formState;

  function setError(fieldName: string, errorMessage: string) {
    switch (fieldName) {
      case "email":
        if (errorMessage === "required") {
          return staticText.emailIsRequired;
        } else {
          return staticText.invalidEmail;
        }
      case "password":
      default:
        return staticText.passwordIsRequired;
    }
  }

  useEffect(() => {
    if (getAccessToken()) {
      customNavigator(navigate, dispatch, "firstrun/admin/dashboard");
    }
  }, []);

  const [login,{error,
    isError,isLoading}] = useLoginMutation();

  useEffect(() => {
    handleRTKError(isError, error);
  }, [isError, error]);

  async function handleLoginClick(formData: {
    email: string;
    password: string;
  }) {
    const response = await login(formData).unwrap();
    const userData: IUserInfo = response.userInfo;

    const userInfo = {
      ...userData,
      accessToken: response.accessToken,
      pages: response.pages,
      meta: response.meta
    };

    setAccessToken(response.accessToken);
    dispatch(setUserInfo(userInfo));
    customNavigator(navigate, dispatch, "/admin/dashboard");
    if (isError && error) {
      if ("status" in error) {
        const errorMessage =
          "error" in error ? error.error : JSON.stringify(error.data);
        enqueueSnackbar(errorMessage, { variant: "error" });
      }
    }
  }
  function goToSignUpPage() {
    navigate("/signup");
  }

  function goToForgotPassword() {
    navigate("/forgot-password");
  }

  return (
    <div className="loginPageContainer">
      <LoginBoxContainer>
        {isLoading && <Loader />}
        <div className="loginHeaderContainer">
          <TitleText>{staticText.welcome}</TitleText>
          <TextButton
            buttonText={lang === "ar" ? "EN" : "AR"}
            action={() => setLanguage(lang === "ar" ? "en" : "ar")}
          ></TextButton>
        </div>
        <DefaultForm onSubmit={handleSubmit(handleLoginClick)}>
          <DefaultInput
            label={staticText.email}
            register={register("email")}
            error={errors.email?.message ? true : false}
            errorText={
              errors.email?.message
                ? setError("email", errors.email?.message)
                : ""
            }
          ></DefaultInput>
          <DefaultInput
            label={staticText.password}
            register={register("password")}
            passwordCheck={true}
            error={errors.password?.message ? true : false}
            errorText={
              errors.password?.message
                ? setError("password", errors.password?.message)
                : ""
            }
          ></DefaultInput>
          <CTAButton
            buttonType="submit"
            buttonText={staticText.login}
            fullWidthCheck={true}
            fontSize="18px"
          ></CTAButton>
          <CTAButton
            buttonText={staticText.signup}
            action={goToSignUpPage}
            fullWidthCheck={true}
            fontSize="18px"
          ></CTAButton>
          <CTAButton
            buttonText="Forgot Password"
            action={goToForgotPassword}
            fullWidthCheck={true}
            fontSize="18px"
          ></CTAButton>
        </DefaultForm>
      </LoginBoxContainer>
    </div>
  );
}

export default LoginPage;

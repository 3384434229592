import { Box, Stack } from "@mui/material";
import {
  HeaderText,
  RatingHomePageContainer,
} from "../RatingPage/styledComponents.tsx";
import { useTranslation } from "react-i18next";
import { HeaderContainer, PageContainer } from "./styledComponents.tsx";
import RatingHeader from "../RatingPage/ratingHeader.tsx";

const TermsAndConditions = () => {
  const { t } = useTranslation();

  return (
    <Box style={{ height: "100%" }}>
      <Stack
        direction="column"
        sx={{
          width: "380px",
          position: "absolute",
          top: "0",
          bottom: "0",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <Box>
          <RatingHeader />
        </Box>
        <PageContainer>
          <HeaderContainer>
            <HeaderText>{t("termsAndConditions")}</HeaderText>
          </HeaderContainer>
          <RatingHomePageContainer>
            <span>
              These terms and conditions outline the rules and regulations for
              the use of iVoiceUp's Website, located at www.ivoiceup.com. By
              accessing this website we assume you accept these terms and
              conditions. Do not continue to use iVoiceUp if you do not agree to
              take all of the terms and conditions stated on this page. The
              following terminology applies to these Terms and Conditions,
              Privacy Statement and Disclaimer Notice and all Agreements:
              "Client", "You" and "Your" refers to you, the person log on this
              website and compliant to the Company's terms and conditions. "The
              Company", "Ourselves", "We", "Our" and "Us", refers to our
              Company. "Party", "Parties", or "Us", refers to both the Client
              and ourselves. All terms refer to the offer, acceptance and
              consideration of payment necessary to undertake the process of our
              assistance to the Client in the most appropriate manner for the
              express purpose of meeting the Client's needs in respect of
              provision of the Company's stated services, in accordance with and
              subject to, prevailing law of Netherlands. Any use of the above
              terminology or other words in the singular, plural, capitalization
              and/or he/she or they, are taken as interchangeable and therefore
              as referring to same. Cookies We employ the use of cookies. By
              accessing iVoiceUp, you agreed to use cookies in agreement with
              the iVoiceUp;s Privacy Policy. Most interactive websites use
              cookies to let us retrieve the user;s details for each visit.
              Cookies are used by our website to enable the functionality of
              certain areas to make it easier for people visiting our website.
              Some of our affiliate/advertising partners may also use cookies.
              License Unless otherwise stated, iVoiceUp and/or its licensors own
              the intellectual property rights for all material on iVoiceUp. All
              intellectual property rights are reserved. You may access this
              from iVoiceUp for your own personal use subjected to restrictions
              set in these terms and conditions. You must not: Republish
              material from iVoiceUp Sell, rent or sub-license material from
              iVoiceUp Reproduce, duplicate or copy material from iVoiceUp
              Redistribute content from iVoiceUp This Agreement shall begin on
              the date hereof Parts of this website offer an opportunity for
              users to post and exchange opinions and information in certain
              areas of the website. iVoiceUp does not filter, edit, publish or
              review Comments prior to their presence on the website. Comments
              do not reflect the views and opinions of iVoiceUp,its agents
              and/or affiliates. Comments reflect the views and opinions of the
              person who post their views and opinions. To the extent permitted
              by applicable laws, iVoiceUp shall not be liable for the Comments
              or for any liability, damages or expenses caused and/or suffered
              as a result of any use of and/or posting of and/or appearance of
              the Comments on this website. iVoiceUp reserves the right to
              monitor all Comments and to remove any Comments which can be
              considered inappropriate, offensive or causes breach of these
              Terms and Conditions. You warrant and represent that: You are
              entitled to post the Comments on our website and have all
              necessary licenses and consents to do so; The Comments do not
              invade any intellectual property right, including without
              limitation copyright, patent or trademark of any third party; The
              Comments do not contain any defamatory, libelous, offensive,
              indecent or otherwise unlawful material which is an invasion of
              privacy The Comments will not be used to solicit or promote
              business or custom or present commercial activities or unlawful
              activity. You hereby grant iVoiceUp a non-exclusive license to
              use, reproduce, edit and authorize others to use, reproduce and
              edit any of your Comments in any and all forms, formats or media.
              Hyperlinking to our Content The following organizations may link
              to our Website without prior written approval: Government
              agencies; Search engines; News organizations; Online directory
              distributors may link to our Website in the same manner as they
              hyperlink to the Websites of other listed businesses; and System
              wide Accredited Businesses except soliciting non-profit
              organizations, charity shopping malls, and charity fundraising
              groups which may not hyperlink to our Web site. These
              organizations may link to our home page, to publications or to
              other Website information so long as the link: (a) is not in any
              way deceptive; (b) does not falsely imply sponsorship, endorsement
              or approval of the linking party and its products and/or services;
              and (c) fits within the context of the linking party;s site. We
              may consider and approve other link requests from the following
              types of organizations: commonly-known consumer and/or business
              information sources; dot.com community sites; associations or
              other groups representing charities; online directory
              distributors; internet portals; accounting, law and consulting
              firms; and educational institutions and trade associations. We
              will approve link requests from these organizations if we decide
              that: (a) the link would not make us look unfavorably to ourselves
              or to our accredited businesses; (b) the organization does not
              have any negative records with us; (c) the benefit to us from the
              visibility of the hyperlink compensates the absence of iVoiceUp;
              and (d) the link is in the context of general resource
              information. These organizations may link to our home page so long
              as the link: (a) is not in any way deceptive; (b) does not falsely
              imply sponsorship, endorsement or approval of the linking party
              and its products or services; and (c) fits within the context of
              the linking party;s site.
            </span>
          </RatingHomePageContainer>
        </PageContainer>
      </Stack>
    </Box>
  );
};

export default TermsAndConditions;

import { styled } from "@mui/material/styles";

export const AddEditCompanyFormListsContainer = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: theme.spacing(5),
    width: "100%",
    marginInlineStart: theme.spacing(4),
}));

export const AddEditCompanyFormListsSubContainer = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "80%",
    gap: theme.spacing(3),
}));

export const PageHeaderContainer = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginBottom: theme.spacing(5)
}));
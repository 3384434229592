import { useEffect, useState } from "react";
import DefaultTableFooter from "../../../Global/DefaultTable/DefaultPaginator";
import {
  DefaultTable,
  DefaultTableBox,
  DefaultTableCell,
  DefaultTableHeader,
  DefaultTableHeaderContainer,
  DefaultTableRow,
} from "../../../Global/DefaultTable/styledComponents";
import { useGetAllReportersDataQuery } from "../../../../features/customerRating/customerRatingApiSlice";
import Loader from "../../../Global/Loader";
import { handleRTKError } from "../../../../Shared/Functions/RTKErrorHandler";

// interface IContacts {
//   companyName: string;
//   branchName: string;
//   averageRating: number;
//   reporterFullName: string;
//   reporterPhone: string;
// }

const ContactsPage = () => {
  const [currentpage, setCurrentPage] = useState(1);

  const {
    data: reporters,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetAllReportersDataQuery(currentpage);

  useEffect(() => {
    handleRTKError(isError, error);
  }, [isError, error]);

  return (
    <>
      {(isLoading || isFetching) && <Loader />}
      <div>
        <DefaultTableBox>
          <DefaultTableHeaderContainer>
            <DefaultTableHeader style={{ width: "calc(100% / 6)" }}>
              Name
            </DefaultTableHeader>
            <DefaultTableHeader style={{ width: "calc(100% / 6)" }}>
              Phone
            </DefaultTableHeader>
            <DefaultTableHeader style={{ width: "calc(100% / 6)" }}>
              Company Name
            </DefaultTableHeader>
            <DefaultTableHeader style={{ width: "calc(100% / 6)" }}>
              Brand Name
            </DefaultTableHeader>
            <DefaultTableHeader style={{ width: "calc(100% / 6)" }}>
              Branch Name
            </DefaultTableHeader>
            <DefaultTableHeader style={{ width: "calc(100% / 6)" }}>
              Rating
            </DefaultTableHeader>
          </DefaultTableHeaderContainer>
          <DefaultTable>
            <tbody>
              {reporters?.result?.map((reporter: any, idx: number) => {
                return (
                  <DefaultTableRow key={idx}>
                    <DefaultTableCell>
                      {reporter.customerInfo.name ?? ""}
                    </DefaultTableCell>
                    <DefaultTableCell>
                      {reporter.customerInfo.phone}
                    </DefaultTableCell>
                    <DefaultTableCell>{reporter.companyName}</DefaultTableCell>
                    <DefaultTableCell>{reporter.brandName}</DefaultTableCell>
                    <DefaultTableCell>{reporter.branchName}</DefaultTableCell>
                    <DefaultTableCell>{reporter.avgRating}</DefaultTableCell>
                  </DefaultTableRow>
                );
              })}
            </tbody>
          </DefaultTable>
          <DefaultTableFooter
            count={reporters?.pagesCount}
            setPageNumber={setCurrentPage}
          />
        </DefaultTableBox>
      </div>
    </>
  );
};

export default ContactsPage;

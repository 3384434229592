import {styled} from "@mui/material/styles";
import {Stack} from "@mui/material";

export const PageContainer = styled(Stack)(({ theme }) => ({
  width: "100%",
  minHeight: "100vh",
  overflowY: "auto",
  justifyContent: "flex-start",
  alignItems: "center",
  paddingTop: theme.spacing(4),
}));
export const HeaderContainer = styled(Stack)(() => ({
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "center",
  paddingTop: "1rem",
  paddingBottom: "1rem",
  color: "#0C100C",
}));

import { CardContainerDiv } from "./styledComponents";
import { ICardContainer } from './../../../Shared/Interfaces/ICardContainer';

interface ICardContainerProps extends ICardContainer {
  children?: React.ReactNode;
}

const CardContainer = ({ flexDirection = 'column', height = 'auto', justifyContent = 'space-between', children }: ICardContainerProps) => {
  return (
    <CardContainerDiv 
      height={height}
      flexDirection={flexDirection}
      justifyContent={justifyContent}
     >
      {children}
    </CardContainerDiv>
  )
}

export default CardContainer
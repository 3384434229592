import { useSelector, useDispatch } from "react-redux";

import { Grid, Tooltip, Typography } from "@mui/material";
import TrendingUp from "@mui/icons-material/TrendingUp";
import TrendingDown from "@mui/icons-material/TrendingDown";
import InsightsIcon from "@mui/icons-material/Insights";
import StorefrontIcon from "@mui/icons-material/Storefront";

import CardContainer from "../../../../Global/CardContainer";
import LineChartGraph from "../../../../Global/LineChart";
import BarChartGraph from "../../../../Global/BarChart";
import {
  DashboardDataColoredContainer,
  DashboardDataColoredDataContainer,
  DashboardDataColoredDataHeaderText,
  DashboardDataColoredDataValueText,
  DashboardDataColoredLogoContiainer,
} from "../../DashboardPage/styledComponents";

import {IBranchInfo} from "../../../../../Shared/Interfaces/IAnalytics";

import {
  setCategoriesSuggestions,
  setCompareItems,
} from "../../../../../Redux/Reducers/analyticsReducer";
import { RefObject } from "react";
import { RootState } from "../../../../../Redux/store";
import { useGetAllCategoriesQuery } from "../../../../../features/category/categoryApiSlice";

interface IHeadingCard {
  Icon: React.ReactNode;
  mainText: string | number;
  subText: string;
  cursor?: "default" | "pointer";
  onClickFn?: (categoryName: string) => void;
}

const HeadingCard = ({
  Icon,
  mainText,
  subText,
  cursor = "default",
  onClickFn = () => {},
}: IHeadingCard) => {
  return (
    <DashboardDataColoredContainer
      sx={{
        cursor: cursor,
        "&:hover": {
          backgroundColor: cursor === "pointer" ? "#749c76" : null,
        },
      }}
      onClick={() => {
        onClickFn(mainText as string);
      }}
    >
      <DashboardDataColoredDataContainer>
        <Tooltip
          title={<Typography variant="subtitle2">{mainText}</Typography>}
          arrow
          followCursor
        >
          <DashboardDataColoredDataValueText style={{ fontSize: "40px" }}>
            {mainText?.toString()?.length > 10
              ? mainText?.toString()?.slice(0, 8) + "..."
              : mainText}
          </DashboardDataColoredDataValueText>
        </Tooltip>
        <DashboardDataColoredDataHeaderText
          style={{ textTransform: "capitalize" }}
        >
          {subText}
        </DashboardDataColoredDataHeaderText>
      </DashboardDataColoredDataContainer>
      <DashboardDataColoredLogoContiainer
        style={{ marginLeft: "5px", padding: "5px" }}
      >
        {Icon}
      </DashboardDataColoredLogoContiainer>
    </DashboardDataColoredContainer>
  );
};

interface ISection3Props {
  categorySuggestionsRef: RefObject<HTMLDivElement>;
  section3Ref: RefObject<HTMLDivElement>;
  branchesInfo: IBranchInfo[];
}

const AnalyticsSection1 = ({
  categorySuggestionsRef,
  section3Ref,
  branchesInfo,
}: ISection3Props) => {
  const dispatch = useDispatch();
  const compareItem2 = useSelector(
    (state: RootState) => state.analytics.compareItems[1]
  );

  const { data: categories } = useGetAllCategoriesQuery("");

  const topRatedBranch = branchesInfo?.reduce((acc, currBranch) => {
    if (currBranch.totalAvg >= acc.totalAvg) return currBranch;
    else return acc;
  }, branchesInfo[0]);

  let highestRatedCategory = "";
  let lowestRatedCategory = "";

  const avgCategories: { [key: string]: number } = {};

  branchesInfo?.forEach((branch) => {
    branch.categories.forEach((cat) => {
      avgCategories[cat.name] = (avgCategories[cat.name] ?? 0) + cat.totalAvg;
    });
  });

  const catArr = Object.values(avgCategories) as number[];
  const maxVal = Math.max(...catArr);
  const minVal = Math.min(...catArr);

  Object.keys(avgCategories).forEach((key) => {
    if (avgCategories[key] === maxVal) highestRatedCategory = key;

    if (avgCategories[key] === minVal) lowestRatedCategory = key;
  });

  const avgRatings =
    (branchesInfo?.reduce(
      (acc, currBranch) => acc + currBranch.totalAvg,
      0
    ) as number) / branchesInfo?.length;

  const onHeaderCardClick = async (categoryName: string) => {
    const category = (
      categories as {
        _id: string;
        name: string;
        nameAr: string;
      }[]
    )?.find((category) =>
      category?.name.toLowerCase().includes(categoryName.toLowerCase())
    );
 
    const categoryID = category?._id.toString();
    dispatch(
      setCategoriesSuggestions({
        categoryName: "*" + categoryName,
        categoryID: categoryID,
      })
    );

    categorySuggestionsRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onBranchBarClick = (bar: any) => {
    const branchName = bar.name;
    if (branchName === "Total") return;

    dispatch(setCompareItems([branchName, compareItem2]));
    section3Ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    branchesInfo && (
      <>
        {/* {loading && <Loader />} */}
        <Grid container spacing={8}>
          {/* SECTION HEADER */}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CardContainer flexDirection="row" justifyContent="space-around">
              <HeadingCard
                mainText={highestRatedCategory}
                subText="Highest Rated Category"
                Icon={<TrendingUp style={{ fontSize: "40px" }} />}
                cursor="pointer"
                onClickFn={onHeaderCardClick}
              />
              <HeadingCard
                mainText={lowestRatedCategory}
                subText="lowest rated category"
                Icon={<TrendingDown style={{ fontSize: "40px" }} />}
                cursor="pointer"
                onClickFn={onHeaderCardClick}
              />
              <HeadingCard
                mainText={avgRatings?.toFixed(2)}
                subText="average"
                Icon={<InsightsIcon style={{ fontSize: "40px" }} />}
              />
              <HeadingCard
                mainText={
                  // topRatedBranch?.branchName.length > 10 ?
                  //   topRatedBranch.branchName.slice(0, 10) + '...' :
                  topRatedBranch?.name
                }
                subText="top rated branch"
                Icon={<StorefrontIcon style={{ fontSize: "40px" }} />}
              />
            </CardContainer>
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6}>
            <LineChartGraph
              titleText="Company Average"
              titleTextSize="28px"
              line1DataKey="avg"
              xAxisDataKey="unit"
              graphHeight="600px"
            />
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6}>
            {branchesInfo.length > 0 && <BarChartGraph
              graphData={
                [
                  ...branchesInfo,
                  { totalAvg: avgRatings, name: "Total" },
                ] as unknown as {
                  [key: string]: string;
                }[]
              }
              titleText="Branches Average"
              bar1DataKey="totalAvg"
              xAxisDataKey="name"
              graphHeight="600px"
              showRatingCount={true}
              onClickFn={onBranchBarClick}
            />}
          </Grid>
        </Grid>
      </>
    )
  );
};

export default AnalyticsSection1;

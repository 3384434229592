import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

export const SideBarWebContainer = styled("div")(({ theme }) => ({
    minWidth: "275px",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    backgroundColor: "white",
    boxShadow: "0px 0px 4px #E6E6E6",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    transition: "0.3s ease-in-out min-width",
    
    [theme.breakpoints.down("md")]: {
        width: "60px",
        padding: theme.spacing(2),
    },
}));

export const SideBarListItem = styled(Link)(({ theme }) => ({
    width: "100%",
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: theme.spacing(3),
    backgroundColor: "transparent",
    borderRadius: theme.spacing(2),
    cursor: "pointer",
    textDecoration: "none",
    transition: "0.3s ease-in-out background-color",
    "& .MuiTypography-root": {
        transition: "0.3s ease-in-out color",
    },
    "&:hover": {
        backgroundColor: theme.palette.primary.light,
        "& .MuiTypography-root": {
            color: theme.palette.primary.dark,
        },
    },
}));

export const SideBarListItemText = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.dark,
    fontSize: "18px",
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down("md")]: {
        display: "none",
    },
}));

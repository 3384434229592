import { styled } from "@mui/material/styles";
import { Box, Stack } from "@mui/material";

// Rating Form
export const FormFieldContainer = styled(Stack)(() => ({
  gap: "16px",
  padding: "0px 0px 8px 0px",
}));

export const QuestionContainer = styled(Stack)(() => ({
  width: "100%",
  minHeight: "56px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  boxSizing: "border-box",
}));

export const QuestionCounter = styled(Box)(() => ({
  boxSizing: "border-box",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "24px",
  textAlign: "center",
  color: "#749c76",
}));

export const QuestionText = styled(Box)(() => ({
  boxSizing: "border-box",
  fontWeight: 600,
  fontSize: "24px",
  lineHeight: "32px",
  letterSpacing: "-0.24px",
  textAlign: "center",
}));

export const RatingIconsContainer = styled(Stack)(() => ({
  width: "100%",
  height: "76px",
  flexDirection: "row",
  justifyContent: "space-between",
}));

export const SuggestionsContainer = styled(Stack)(() => ({
  gap: "8px",
}));

export const SuggestionQuestion = styled(Box)(() => ({
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  textAlign: "center",
}));

export const Suggestions = styled("div")(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  gap: "8px",
}));

export const SuggestionTag = styled("div")(() => ({
  boxSizing: "border-box",
  minHeight: "32px",
  borderRadius: "21px",
  padding: "0px 10px",
  backgroundColor: "#DEEDDF",
  color: "#425D48",
  textTransform: "capitalize",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "-0.5px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  textAlign: "center",
}));

export const SelectedSuggestionTag = styled("div")(() => ({
  boxSizing: "border-box",
  minHeight: "32px",
  borderRadius: "21px",
  padding: "0px 10px",
  color: "#DEEDDF",
  backgroundColor: "#425D48",
  textTransform: "capitalize",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "-0.5px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  textAlign: "center",
}));

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { customNavigator } from "../Functions/customNavigator";

const Protected = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const accessToken = localStorage.getItem('accessToken');
    const location = useLocation();

    useEffect(() => {
        customNavigator(navigate, dispatch, location.pathname);
    }, []);

    return accessToken ? <Outlet /> : <Navigate to="/login" />;
};
export default Protected;

import { Dispatch } from "react";
import { NavigateFunction } from "react-router-dom";
import { validateAccessToken } from "./validateToken";
import { AnyAction } from "@reduxjs/toolkit";

export function customNavigator(navigation: NavigateFunction, dispatch: Dispatch<AnyAction>, path: string) {
    const auth = validateAccessToken(dispatch);
    if (auth) {
        if (path.includes("firstrun")) {
            path = path.replace("firstrun", "");
        }
        navigation(path);
    } else {
        navigation("/login");
    }
}

import {
  FormFieldContainer,
  QuestionContainer,
  QuestionCounter,
  QuestionText,
  RatingIconsContainer,
  SelectedSuggestionTag,
  SuggestionQuestion,
  SuggestionTag,
  Suggestions,
  SuggestionsContainer,
} from "./styledComponents";

import DefaultAmazing from "../../../Assets/RatingPage/Rating/defaultAmazing.svg?react";
import DefaultMeh from "../../../Assets/RatingPage/Rating/defaultMeh.svg?react";
import DefaultGood from "../../../Assets/RatingPage/Rating/defaultGood.svg?react";
import DefaultTerrible from "../../../Assets/RatingPage/Rating/defaultTerrible.svg?react";
import DefaultOkay from "../../../Assets/RatingPage/Rating/defaultOkay.svg?react";

// import ActiveAmazing from "../../../Assets/RatingPage/Rating/activeAmazing.svg?react";
// import ActiveMeh  from "../../../Assets/RatingPage/Rating/activeMeh.svg?react";
// import ActiveGood  from "../../../Assets/RatingPage/Rating/activeGood.svg?react";
// import ActiveTerrible  from "../../../Assets/RatingPage/Rating/activeTerrible.svg?react";
// import ActiveOkay  from "../../../Assets/RatingPage/Rating/activeOkay.svg?react";

import TerribleLottie from "../../../Assets/RatingPage/Rating/terribleLottie.json";
import MehLottie from "../../../Assets/RatingPage/Rating/mehLottie.json";
import GoodLottie from "../../../Assets/RatingPage/Rating/goodLottie.json";
import AmazingLottie from "../../../Assets/RatingPage/Rating/amazingLottie.json";
import OkayLottie from "../../../Assets/RatingPage/Rating/okayLottie.json";

import { ICustomerFormFieldSuggestion } from "../../../Shared/Interfaces/ICustomerRatingPage";
import { Box, Stack, Typography } from "@mui/material";
import { useState } from "react";
import {
  setRatingValue,
  setSuggestionBool,
} from "../../../Redux/Reducers/ratingPageReducer";
import { useDispatch } from "react-redux";
import Lottie from "lottie-react";
import {useTranslation} from "react-i18next";

interface IAnimatedIcon {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lottieJson: any;
}

const AnimatedIcon = ({ lottieJson }: IAnimatedIcon) => {
  return (
    <Stack sx={{marginBottom: "3px"}}>
      <Box width="60px" height="56px">
        <Lottie loop={false} animationData={lottieJson} />
      </Box>
    </Stack>
  );
};

interface IFormFieldRating {
  formFieldIdx: number;
  counter: string;
  question: string;
  suggestionQuestion: string;
  positiveSuggestions: ICustomerFormFieldSuggestion[];
  negativeSuggestions: ICustomerFormFieldSuggestion[];
}

const FormFieldRating = ({
  formFieldIdx,
  counter,
  question,
  suggestionQuestion,
  positiveSuggestions,
  negativeSuggestions,
}: IFormFieldRating) => {
  const dispatch = useDispatch();
  const {i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [iconClick, setIconClick] = useState(Array(5).fill(false));
  /*
   * 0 => No Suggestions
   * 1 => Positive Suggestions
   * 2 => Negative Suggestions
   */
  const [showSuggestions, setShowSuggestions] = useState(0);
  const [selectedSuggestion, setSelectedSuggestion] = useState<
    boolean[] | null
  >(null);

  const { t } = useTranslation();

  const icons = [
    {
      default: <DefaultTerrible />,
      active: TerribleLottie,
      text: 'terrible',
    },
    {
      default: <DefaultMeh />,
      active: MehLottie,
      text: 'meh',
    },
    {
      default: <DefaultOkay />,
      active: OkayLottie,
      text: 'okay',
    },
    {
      default: <DefaultGood />,
      active: GoodLottie,
      text: 'good',
    },
    {
      default: <DefaultAmazing />,
      active: AmazingLottie,
      text: 'amazing',
    },
  ];
  const iconClickHandler = (iconIdx: number) => {
    const newArr = Array(5).fill(false);
    newArr[iconIdx] = true;

    if (iconIdx > 1) {
      // SHOW POSITIVE SUGGESTIONS
      showSuggestions !== 1 &&
        setSelectedSuggestion(Array(positiveSuggestions.length).fill(false));
      setShowSuggestions(1);
    } else {
      // SHOW NEGATIVE SUGGESTIONS
      showSuggestions !== 2 &&
        setSelectedSuggestion(Array(negativeSuggestions.length).fill(false));
      setShowSuggestions(2);
    }
    setIconClick([...newArr]);

    selectRating(formFieldIdx, iconIdx + 1);
  };

  const suggestionClickHandler = (suggestionIdx: number) => {
    const newSuggestions = [...(selectedSuggestion as boolean[])];
    newSuggestions[suggestionIdx] = !newSuggestions[suggestionIdx];
    setSelectedSuggestion([...newSuggestions]);
    const suggestionType = showSuggestions === 1 ? "positive" : "negative";
    selectSuggestion(suggestionType, formFieldIdx, newSuggestions);
  };

  const selectRating = (fieldIndex: number, rateVal: number) => {
    const payload = {} as { [key: string]: string | number };
    payload.index = fieldIndex;
    payload.ratingProvided = rateVal;
    dispatch(setRatingValue(payload));
  };

  const selectSuggestion = (
    suggestionType: "positive" | "negative",
    fieldIndex: number,
    suggestionsBooleans: boolean[]
  ) => {
    const payload = {} as { [key: string]: string | number | boolean[] };
    payload.posneg = suggestionType;
    payload.index = fieldIndex;
    payload.suggestions = suggestionsBooleans;
    dispatch(setSuggestionBool(payload));
  };

  return (
    <FormFieldContainer>
      <QuestionContainer>
        <QuestionCounter>{counter}</QuestionCounter>
        <QuestionText>{question}</QuestionText>
      </QuestionContainer>
      <RatingIconsContainer>
        {icons.map((icon, idx) => {
          return (
            <Box
              key={idx}
              sx={{ cursor: "pointer" }}
              onClick={() => iconClickHandler(idx)}
            >
              {iconClick[idx] ? (
                <AnimatedIcon lottieJson={icon.active}  />
              ) : (
                  <div >{icon.default}</div>
              )}
              <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "12px",
                    lineHeight: "20px",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
              >
                {t(icon.text)}
              </Typography>
            </Box>
          );
        })}
      </RatingIconsContainer>
      <SuggestionsContainer>
        {showSuggestions &&
        (showSuggestions === 1
          ? positiveSuggestions.length
          : negativeSuggestions.length) > 0 ? (
          <>
            <SuggestionQuestion>{suggestionQuestion}</SuggestionQuestion>
            <Suggestions>
              {(showSuggestions === 1
                ? positiveSuggestions
                : negativeSuggestions
              ).map((suggestion, idx) => {
                return (
                  <Box key={idx} onClick={() => suggestionClickHandler(idx)}>
                    {(selectedSuggestion as boolean[])[idx] ? (
                      <SelectedSuggestionTag>
                        {/*TODO: replace all suggestion.suggestionName*/}
                        {currentLanguage=='ar'?(suggestion.nameAr):(suggestion.name)}
                      </SelectedSuggestionTag>
                    ) : (
                      <SuggestionTag>{currentLanguage=='ar'?(suggestion.nameAr):(suggestion.name)}</SuggestionTag>
                    )}
                  </Box>
                );
              })}
            </Suggestions>
          </>
        ) : (
          ""
        )}
      </SuggestionsContainer>
    </FormFieldContainer>
  );
};

export default FormFieldRating;

import { MoreVert } from "@mui/icons-material";
import { IconButton, Menu} from "@mui/material";
import React, { Fragment, useState } from "react";

function ActionsMenu({children}: {children: React.ReactNode}) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);
    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };
    return (
        <Fragment>
            <IconButton onClick={handleOpenMenu}>
                <MoreVert color="primary" />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleCloseMenu}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}>
                    <div onClick={handleCloseMenu}>
                        {children}
                    </div>
            </Menu>
        </Fragment>
    );
}

export default ActionsMenu;

import { RefObject } from "react";

import { t } from "i18next";
import {
  CustomerInfoFormContainer,
  CustomerInfoFormHeaderContainer,
  CustomerInfoFormHeaderTextOptional,
  CustomerInfoFormHeaderText,
  CustomerTextFieldsContainer,
  TextFieldContainer,
  TextFieldLabel,
  TextFieldInput,
} from "./styledComponents";

interface IProps {
  fullNameRef: RefObject<HTMLInputElement>;
  mobileNumberRef: RefObject<HTMLInputElement>;
  emailRef: RefObject<HTMLInputElement>;
  subHeader?: string;
  isOptional?: boolean;
}

const CustomerDataForm = ({
  fullNameRef,
  mobileNumberRef,
  emailRef,
  subHeader,
  isOptional = true,
}: IProps) => {
  return (
    <CustomerInfoFormContainer>
      <CustomerInfoFormHeaderContainer>
        {isOptional && (
          <CustomerInfoFormHeaderTextOptional>
            {t("optional")}
          </CustomerInfoFormHeaderTextOptional>
        )}
        <CustomerInfoFormHeaderText>
          {t("tellUsAboutYou")}
        </CustomerInfoFormHeaderText>
        <CustomerInfoFormHeaderTextOptional>
          {subHeader}
        </CustomerInfoFormHeaderTextOptional>
      </CustomerInfoFormHeaderContainer>

      <form>
        <CustomerTextFieldsContainer>
          <TextFieldContainer>
            <TextFieldLabel>{t("fullName")}</TextFieldLabel>
            <TextFieldInput
              ref={fullNameRef}
              id="fullName"
              name="fullName"
              type="text"
              autoComplete="name"
              placeholder={t("fullName")}
            />
          </TextFieldContainer>
          <TextFieldContainer>
            <TextFieldLabel>{t("phoneNumber")}</TextFieldLabel>
            <TextFieldInput
              ref={mobileNumberRef}
              id="phone"
              name="phone"
              type="text"
              autoComplete="tel"
              placeholder="0100 123 4567"
            />
          </TextFieldContainer>
          <TextFieldContainer>
            <TextFieldLabel>{t("emailAddress")}</TextFieldLabel>
            <TextFieldInput
              ref={emailRef}
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              placeholder="example@email.com"
            />
          </TextFieldContainer>
        </CustomerTextFieldsContainer>
      </form>
    </CustomerInfoFormContainer>
  );
};

export default CustomerDataForm;

export enum staticTextEN {
    email = "Email",
    password = "Password",
    login = "Login",
    emailIsRequired = "Email is required",
    passwordIsRequired = "Password is required",
    invalidEmail = "Invalid Email",
    welcome = "Welcome",
    addUser = "Add User",
    name = "Name",
    userType = "User Type",
    active = "Active",
    actions = "Actions",
    addField = "Add Field",
    editField = "Edit Field",
    fieldName = "Field Name",
    fieldType = "Field Type",
    category = "Category",
    positiveSuggestions = "Positive Suggestion",
    negativeSuggestions = "Negative Suggestion",
    signup = "Sign Up",
    otp = "OTP Code",
    enterEmail = "Enter Email",
    verifyOTP = "Verify OTP Code",
    createPassword = "Create Password",
    googleMapsURL = "Google Maps URL",
    submit = "Submit"
}

export enum staticTextAR {
    email = "بريد إلكتروني",
    password = "كلمة السر",
    login = "تسجيل الدخول",
    emailIsRequired = "البريد الالكتروني مطلوب",
    passwordIsRequired = "كلمة المرور مطلوبة",
    invalidEmail = "بريد إلكتروني خاطئ",
    welcome = "مرحباً",
    addUser = "إضافة مستخدم",
    name = "اسم",
    userType = "نوع المستخدم",
    active = "مفعل",
    actions = "عمليات",
    addField = "إضافة حقل",
    editField = "عدل حقل",
    fieldName = "إسم الحقل",
    fieldType = "نوع الحقل",
    category = "فئة",
    positiveSuggestions = "اقتراح إيجابي",
    negativeSuggestions = "اقتراح سلبي",
    signup = "اشتراك",
    otp = "OTPكود ال",
    enterEmail = "أدخل البريد الإلكتروني",
    verifyOTP = "OTPأكد كود ال",
    createPassword = "إنشاء كلمة المرور",
    googleMapsURL = "عنوان URL لخرائط Google",
    submit = "قدم"
}

import {apiSlice} from '../apiSlice';

export const brandsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        addCompanyBrand: builder.mutation({
            query: (payload) => {
                const formData = new FormData();
                formData.append('name', payload.name);
                formData.append('nameAr', payload.nameAr);
                formData.append('companyID', payload.companyID);
                formData.append('logoPath', payload.logoPath[0]);
                formData.append('brandAdminEmail', payload.brandAdminEmail);
                return {
                    url: `/companies/${payload.companyID}/brands`,
                    method: "POST",
                    body: formData,
                }
            },
        }),
        deleteBrand: builder.mutation({
            query: ({brandID, companyID}) => ({
                url: `companies/${companyID}/brands/${brandID}`,
                method: "DELETE",
            })
        }),
        activateDeactivateBrand: builder.mutation({
            query: ({ id, isActive }) => ({
                url: `/brands/${id}/active/${isActive}`,
                method: 'PATCH',
            }),
        }),
        updateBrand: builder.mutation({
            query: (payload) => {
                const formData = new FormData();
                formData.append('name', payload.name);
                formData.append('nameAr', payload.nameAr);
                formData.append('companyID', payload.companyID);
                formData.append('id', payload.id);
                formData.append('logoPath', payload.logoPath[0]);
                formData.append('brandAdminEmail', payload.brandAdminEmail);
                return {
                    url: `/companies/${payload.companyID}/brands`,
                    method: "PUT",
                    body: formData,
                }
            },
        }),
    }),
});

export const {
    useAddCompanyBrandMutation,
    useDeleteBrandMutation,
    useUpdateBrandMutation
} = brandsApiSlice;

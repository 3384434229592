import { createSlice } from "@reduxjs/toolkit";
import {
  IDashboardBranchData,
  IDashboardCategoryData,
  IDashboardCompanyData,
  IDashboardData,
  IDashboardFormFieldData,
  IDashboardPackageData,
  IDashboardSuggestionData,
} from "../../Shared/Interfaces/IDashboard";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    dashboardDataIsFetched: false,
    brandName: "",
    branchName: "",
    ratingCount: 0,
    averageRating: 0,
    companyCount: 0,
    companyData: [] as IDashboardCompanyData[],
    branchData: [] as IDashboardBranchData[],
    categoryData: [] as IDashboardCategoryData[],
    formFieldData: [] as IDashboardFormFieldData[],
    topPositiveSuggestions: [] as IDashboardSuggestionData[],
    topNegativeSuggestions: [] as IDashboardSuggestionData[],
    companyCountPerPackage: [] as IDashboardPackageData[],
  },
  reducers: {
    setDashboardData: (state, action) => {
      const data = action.payload as IDashboardData;
      state.companyCount = data?.companyCount ?? 0;
      state.companyData = data?.companyData ?? [];
      state.branchData = data?.branchData ?? [];
      state.categoryData = data?.categoryData ?? [];
      state.formFieldData = data?.formFieldData ?? [];
      state.ratingCount = data?.ratingCount ?? 0;
      state.averageRating = data?.averageRating ?? 0;
      state.topPositiveSuggestions = data?.topPositiveSuggestions ?? [];
      state.topNegativeSuggestions = data?.topNegativeSuggestions ?? [];
      state.companyCountPerPackage = data?.companyCountPerPackage ?? [];
      state.dashboardDataIsFetched = true;
    },
  },
});

export const { setDashboardData } = dashboardSlice.actions;

export default dashboardSlice.reducer;

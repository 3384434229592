import { styled } from "@mui/material/styles";

const DefaultFormContainer = styled("form")(({ theme }) => ({
    display: "inherit",
    flexDirection: "inherit",
    gap: theme.spacing(5),
    justifyContent: "inherit",
    alignItems: "inherit",
    width: "100%",
}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function DefaultForm({ children, onSubmit }: { children: React.ReactNode; onSubmit: (formValues: any) => void }) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function handleSubmit(formValues: any) {
        onSubmit(formValues);
    }
    return <DefaultFormContainer onSubmit={handleSubmit}>{children}</DefaultFormContainer>;
}

export default DefaultForm;

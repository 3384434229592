import {useEffect, useState} from "react";
import DefaultInput from "../../../Global/DefaultInput";
import {SignupBoxContainer} from "./styledComponents";
import "./styles.css";
import CTAButton from "../../../Global/Buttons/CTAButton";
import {getLanguage} from "../../../../Shared/Functions/language";
import Loader from "../../../Global/Loader";
import {useDispatch} from "react-redux";
import {getAccessToken} from "../../../../Shared/Functions/validateToken";
import {useNavigate} from "react-router-dom";
import {setTranslatedText} from "../../../../Shared/Functions/setTranslatedText";
import DefaultForm from "../../../Global/DefaultForm";
import {customNavigator} from "../../../../Shared/Functions/customNavigator";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {z} from "zod";
import {TitleText} from "../../../Global/Text/TitleText";
import {useSendOtpMutation, useSignupMutation, useVerifyOtpMutation} from "../../../../features/auth/authApiSlice.ts";
import {handleRTKError} from "../../../../Shared/Functions/RTKErrorHandler.ts";

interface ITranslation {
    email: string;
    otp: string;
    password: string;
    signup: string;
    emailIsRequired: string;
    passwordIsRequired: string;
    invalidEmail: string;
    enterEmail: string;
    verifyOTP: string;
    createPassword: string;
    firstName: string;
    lastName: string;
}

const signupFormSchema = z.object({
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    email: z.string().min(1, "required").email("not email"),
    otp: z.string().optional(),
    password: z.string().optional(),
});

const staticTextKeys = [
    "email",
    "otp",
    "password",
    "signup",
    "emailIsRequired",
    "passwordIsRequired",
    "invalidEmail",
    "enterEmail",
    "verifyOTP",
    "createPassword",
    "firstName",
    "lastName",
];
const lang = getLanguage();
const staticText = setTranslatedText(lang, staticTextKeys) as ITranslation;

function SignupPage() {
    const [signUpStage, setSignUpStage] = useState(1);
    const [sendOtp, {
        error: errorSendOtp,
        isError: isErrorSendOtp,
        isLoading: isLoadingSendOtp,
        isSuccess: isSuccessSendOtp
    }] = useSendOtpMutation();
    useEffect(() => {
        if (isErrorSendOtp) {
            handleRTKError(isErrorSendOtp, errorSendOtp);
        } else if (isSuccessSendOtp) {
            setSignUpStage(2)
        }
    }, [isErrorSendOtp, errorSendOtp, isSuccessSendOtp]);
    const [verifyOtp, {
        data: dataVerifyOtp,
        error: errorVerifyOtp,
        isError: isErrorVerifyOtp,
        isLoading: isLoadingVerifyOtp,
        isSuccess: isSuccessVerifyOtp
    }] = useVerifyOtpMutation();
    useEffect(() => {
        if (isErrorVerifyOtp) {
            handleRTKError(isErrorVerifyOtp, errorVerifyOtp);

        } else if (isSuccessVerifyOtp) {
            setSignUpStage(3)
        }
    }, [isErrorVerifyOtp, errorVerifyOtp, isSuccessVerifyOtp]);

    const [signUp,{error:errorSignup,isError:isErrorSignup,isLoading:isLoadingSignup, isSuccess:isSuccessSignup}] = useSignupMutation();
    useEffect(() => {
        handleRTKError(isErrorSignup, errorSignup);
    }, [isErrorSignup, errorSignup]);
    useEffect(() => {
        if (isSuccessSignup) navigate("/login");
    }, [isSuccessSignup]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { register, handleSubmit, formState, getValues, setValue } = useForm({
        defaultValues: { email: "", password: "", otp: "", firstName: "", lastName: "" },
        resolver: zodResolver(signupFormSchema),
    });
    const { errors } = formState;

    function setError(errorMessage: string) {
        if (errorMessage === "required") {
            return staticText.emailIsRequired;
        } else {
            return staticText.invalidEmail;
        }
    }

    useEffect(() => {
        if (getAccessToken()) {
            customNavigator(navigate, dispatch, "firstrun/admin/dashboard");
        }
    }, []);

    async function sendOTPToEmail() {
        sendOtp(getValues().email)
    }

    async function verifyOTP() {
        const email = getValues().email;
        const otp = getValues().otp;
        verifyOtp({email, otp})
        if (isSuccessVerifyOtp){
            const firstName = dataVerifyOtp.result.firstName;
            const lastName = dataVerifyOtp.result.lastName;
            setValue("firstName", firstName);
            setValue("lastName", lastName);
        }
    }

    async function updateUserPasswordAndActivate() {
        const body = {} as {[key: string]: string | number};
        body.email = getValues().email;
        body.password = getValues().password;
        body.firstName = getValues().firstName;
        body.lastName = getValues().lastName;
        signUp(body)
        }

    async function handleSignUp() {

        switch (signUpStage) {
            case 1:
                await sendOTPToEmail();
                break;
            case 2:
                await verifyOTP();
                break;
            case 3:
            default:
                await updateUserPasswordAndActivate();
                break;
        }
    }

    return (
        <div className="signupPageContainer">
            {(isLoadingSignup || isLoadingSendOtp || isLoadingVerifyOtp) && <Loader />}
            <SignupBoxContainer>
                <div className="signupHeaderContainer">
                    <TitleText>{staticText.signup}</TitleText>
                    {/*<TextButton*/}
                    {/*    buttonText={lang === "ar" ? "EN" : "AR"}*/}
                    {/*    action={() => setLanguage(lang === "ar" ? "en" : "ar")}></TextButton>*/}
                </div>
                <DefaultForm onSubmit={handleSubmit(handleSignUp)}>
                    {/* Stage 1, Enter Email */}
                    {signUpStage === 1 && (
                        <DefaultInput
                            label={staticText.email}
                            register={register("email")}
                            error={errors.email?.message ? true : false}
                            errorText={errors.email?.message ? setError(errors.email?.message) : ""}></DefaultInput>
                    )}
                    {/* Stage 1, Enter Email */}
                    {/* Stage 2, Enter OTP Code */}
                    {signUpStage === 2 && (
                        <DefaultInput label={staticText.otp} register={register("otp")}></DefaultInput>
                    )}
                    {/* Stage 2, Enter OTP Code */}
                    {/* Stage 3, Enter Password */}
                    {signUpStage === 3 && (
                        <>
                            <DefaultInput
                                label={"First Name"}
                                register={register("firstName")}
                            ></DefaultInput>

                            <DefaultInput
                                label={"Last Name"}
                                register={register("lastName")}
                            ></DefaultInput>
                            <DefaultInput
                                label={staticText.password}
                                register={register("password")}
                                passwordCheck={true}>
                            </DefaultInput>
                        </>

                    )}
                    {/* Stage 3, Enter Password */}
                    <CTAButton
                        buttonType="submit"
                        buttonText={
                            signUpStage === 1
                                ? staticText.enterEmail
                                : signUpStage === 2
                                ? staticText.verifyOTP
                                : staticText.createPassword
                        }
                        fullWidthCheck={true}
                        fontSize="18px"></CTAButton>
                </DefaultForm>
            </SignupBoxContainer>
        </div>
    );
}

export default SignupPage;

import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import "./styles.css";
import { useEffect, useState } from "react";
import { TitleText } from "../../../../Global/Text/TitleText";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../Redux/store";

interface IBarChartData {
    name: string;
    value: number;
    color?: string;
}

function DashboardPieChart({ data, title }: { data: IBarChartData[]; title: string }) {
    const [chartData, setChartData] = useState<[] | IBarChartData[]>([]);
    const colors = useSelector((state: RootState) => state.analytics.colors);

    function setBarChartInfo() {
        const tempData = [] as IBarChartData[];
        data.forEach((d, idx) => {
            const temp = {} as IBarChartData;
            temp.name = d.name;
            temp.value = d.value;
            temp.color = colors[idx % colors.length];
            tempData.push(temp);
        });

        setChartData(tempData);
    }

    useEffect(() => {
        setBarChartInfo();
    }, [data]);



    return (
        <div className="pieChartBox">
            <TitleText style={{marginInlineStart: "16px"}}>{title}</TitleText>
            <div className="chart">
                <ResponsiveContainer width="99%" height={300}>
                    <PieChart>
                        <Tooltip contentStyle={{ background: "white", borderRadius: "5px" }} />
                        <Pie data={chartData} innerRadius={"70%"} outerRadius={"90%"} paddingAngle={5} dataKey="value">
                            {chartData.map((item) => (
                                <Cell key={item.name} fill={item.color} />
                            ))}
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
            </div>
            <div className="options">
                {chartData.map((item) => (
                    <div className="option" key={item.name}>
                        <div className="title">
                            <div className="dot" style={{ backgroundColor: item.color }} />
                            <span>{item.name}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default DashboardPieChart;

import { Grid } from "@mui/material"
import { RefObject } from "react";

interface IAnalyticsSectionProps {
  children?: React.ReactNode;
  ref?: RefObject<HTMLDivElement>;
}

const AnalyticsSection = ({ children, ref }: IAnalyticsSectionProps ) => {
  return (
    <Grid
      ref={ref}
      container
      style={{ marginLeft: 0, marginTop: 0 }}
      justifyContent="space-between"
      alignItems="flex-start"
      spacing={4}
    >
      {children}
    </Grid>
  )
}

export default AnalyticsSection
import { useDispatch, useSelector } from "react-redux";
import { AnalyticsFilterContainer, HeaderContainer, UserDetailsBoxContainer, UserDetailsBoxUserName, UserIconBox } from "./styledComponents";
import { IUserInfo } from "../../../Shared/Interfaces/IUserInfo";
import { useEffect, useRef, useState } from "react";
import { Logout, Person } from "@mui/icons-material";
import { clearAccessToken } from "../../../Shared/Functions/validateToken";
import { useNavigate, useLocation } from "react-router-dom";
import GlobalAnalyticsFilter from "../../Pages/AdminPages/AnalyticsPage/GlobalFilterComponent";
import CompareBranchesSelectComponent from "../../Pages/AdminPages/AnalyticsPage/CompareBranchesComponent";
import { RootState } from "../../../Redux/store";
import { userTypes } from "../../../Shared/Constants/enums";

function useComponentVisible(initialIsVisible: boolean) {
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
    const ref = useRef<HTMLDivElement>(null);

    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsComponentVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, []);

    return { ref, isComponentVisible, setIsComponentVisible };
}

function Header() {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const userInfo = useSelector((state: RootState) => state.auth.userInfo) as IUserInfo;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    function handleLogout() {
        clearAccessToken(dispatch);
        navigate("/login");
        // window.location.reload();
    }

    const currLocation = useLocation();
    return (
        <HeaderContainer>
            {currLocation.pathname.includes('admin/analytics') &&
            <AnalyticsFilterContainer >
                <CompareBranchesSelectComponent />
                <GlobalAnalyticsFilter />
            </AnalyticsFilterContainer>}
            <UserIconBox onClick={() => setIsComponentVisible(!isComponentVisible)}>
                {userInfo?.fullName?.[0]}
                {isComponentVisible && (
                    <UserDetailsBoxContainer ref={ref}>
                        <UserDetailsBoxUserName>
                            <div>{
                                userInfo.userRole === userTypes.ownerType ?
                                "Owner" : 
                                <div>
                                    {userInfo.userRole + "Admin"}
                                    <br />
                                    <div>{userInfo.companyName}</div>
                                </div>
                            }
                            </div>
                            
                        </UserDetailsBoxUserName>
                        <UserDetailsBoxUserName>
                            <Person color="primary"/>
                            {userInfo.fullName}
                        </UserDetailsBoxUserName>
                        <UserDetailsBoxUserName onClick={handleLogout}>
                            <Logout sx={{ color: "rgba(153, 153, 153)" }}></Logout>Logout
                        </UserDetailsBoxUserName>
                    </UserDetailsBoxContainer>
                )}
            </UserIconBox>
        </HeaderContainer>
    );
}

export default Header;

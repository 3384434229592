import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const FormFieldsContainer = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: theme.spacing(5),
    width: "100%",
}));

export const FormFieldCardContainer = styled("div")(({ theme }) => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    borderRadius: theme.spacing(4),
    boxShadow: "0px 0px 4px 1px grey",
    padding: theme.spacing(5),
    boxSizing: "border-box",
    width: "100%",
    backgroundColor: theme.palette.common.white,
}));

export const FormFieldCardHeaderContainer = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    boxSizing: "border-box",
    gap: theme.spacing(10),
}));

export const FormFieldCardHeaderTextContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "20vw",
}));

export const FormFieldCardHeaderTitle = styled(Typography)(({ theme }) => ({
    color: "grey",
    fontSize: "14px",
    fontWeight: theme.typography.fontWeightBold,
}));

export const FormFieldCardHeaderTitleData = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.black,
    fontSize: "18px",
    fontWeight: theme.typography.fontWeightMedium,
}));

export const FormFieldSuggestionsBigContainer = styled("div")(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    boxSizing: "border-box",
}));

export const FormFieldPosNegSuggestionsBigContainer = styled("div")(({ theme }) => ({
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: theme.spacing(2)
}));

export const FormFieldPosNegSuggestionsTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.black,
    fontSize: "18px",
    fontWeight: theme.typography.fontWeightBold,
}));

export const FormFieldPosNegSuggestionsListContainer = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    boxSizing: "border-box",
    gap: theme.spacing(4)
}));

export const FormFieldPosNegSuggestionsListItem = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.white,
    fontSize: "16px",
    fontWeight: theme.typography.fontWeightMedium,
}));
export const FormFieldPosSuggestions = styled(Typography)(({ theme }) => ({
    background: theme.palette.success.dark,
    padding: "5px 11px",
    borderRadius:"5px",
}))
export const FormFieldNegSuggestions = styled(Typography)(({ theme }) => ({
    background: theme.palette.error.dark,
    padding: "5px 11px",
    borderRadius:"5px"
}))
export const TopLeftEditButton = styled("div")(({theme}) => ({
    position: "absolute",
    top: theme.spacing(4),
    right: theme.spacing(4)
}))
import { useEffect, useState } from "react";
import {
  BodyText,
  Button,
  CookieContainer,
  Divider,
  HeaderText,
  SubHeader,
  ThankYouPageContainer,
} from "./styledComponents";
import { Stack, Box, Typography } from "@mui/material";
import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";
import { handleRTKError } from "../../../../Shared/Functions/RTKErrorHandler";
import { useGetFortuneCookieQuery } from "../../../../features/customerRating/customerRatingApiSlice";

import ClosedCookie from "../../../../Assets/RatingPage/ThankYou/Cracked Cookie - Transparent.json";
import OpenedCookie from "../../../../Assets/RatingPage/ThankYou/Opened Cookie - Transparent.json";

const ThankYouPage = () => {
  const [cookieOpened, setCookieOpened] = useState(false);
  const [showFortune, setShowFortune] = useState(false);

  const { data, isError, error } = useGetFortuneCookieQuery({});
  const cookieClickHandler = () => {
    setCookieOpened(true);
  };
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  useEffect(() => {
    if (cookieOpened) {
      setTimeout(() => {
        setShowFortune(true);
      }, 800);
    }
  }, [cookieOpened]);

  useEffect(() => {
    handleRTKError(isError, error);
  }, [isError, error]);

  return (
    <ThankYouPageContainer dir={currentLanguage == "ar" ? "rtl" : "ltr"}>
      <Stack>
        <HeaderText>{t("thankYou.header")}</HeaderText>
        <br />
        <BodyText>{t("thankYou.submittedSuccessfully")}</BodyText>
        <BodyText>{t("thankYou.appreciateTime")}</BodyText>
      </Stack>

      <CookieContainer>
        <Stack gap="16px">
          <Box>
            <SubHeader>{t("thankYou.cookiesCracked.subHeader")}</SubHeader>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "20px",
                textTransform: "uppercase",
                color: "#5C5C5C",
              }}
            >
              {t("thankYou.cookiesCracked.tapToViewFortune")}
            </Typography>
            <Box
              sx={{
                position: "relative",
              }}
            >
              {showFortune && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "70px",
                    zIndex: "999",
                    left: 47,
                    height: 80,
                    width: 245,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: 500,
                    fontSize: 14,
                    animation: "dropAnimation 1s ease forwards",
                  }}
                >
                  {data?.fortune}
                </Box>
              )}
              <Lottie
                onClick={cookieClickHandler}
                style={{ marginTop: "-55px" }}
                animationData={cookieOpened ? OpenedCookie : ClosedCookie}
                loop={cookieOpened ? false : true}
              />
            </Box>
          </Box>
        </Stack>
      </CookieContainer>

      <Divider />

      <Stack gap="8px">
        <BodyText>{t("thankYou.interestedInRating.text")}</BodyText>
        <Button
          background="transparent"
          color="#425D48"
          border="1px solid #425D48"
        >
          {t("thankYou.interestedInRating.browseRestaurantsButton")}
        </Button>
      </Stack>
    </ThankYouPageContainer>
  );
};

export default ThankYouPage;

import styled from "@emotion/styled";
import { DatePicker } from "@mui/x-date-pickers";

export const DefaultDatePicker = styled(DatePicker)(() => ({
    width: "100%",
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderWidth: "3px",
            borderRadius: "12px",
            transition: "0.3s border-color ease-in-out",
        },
        "&:hover fieldset": {
            borderWidth: "3px",
        },
        "&.Mui-focused fieldset": {
            borderWidth: "3px",
        },
    },
}))

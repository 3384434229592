import { Typography, styled } from "@mui/material";

export const CustomDialogTitle = styled("div")(({ theme }) => ({
    height: "90px",
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingInlineStart: theme.spacing(5),
    paddingInlineEnd: theme.spacing(3),
}));

export const CustomDialogHeaderDivider = styled('div')(({ theme }) => ({
    height: "35px",
    width: "2px",
    backgroundColor: theme.palette.secondary.light
}))

export const CustomDialogTitleText = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.light,
    fontSize: "30px",
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: 0,
    marginInlineStart: theme.spacing(4)
}));

export const CustomDialogSubContainer = styled("div")(({ theme }) => ({
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
}));

import { createSlice } from "@reduxjs/toolkit";
import { IBranchCategoriesAvgs, IBranchInfo, ICategorySuggestion } from "../../Shared/Interfaces/IAnalytics";


export const analyticsSlice = createSlice({
    name: "analytics",
    initialState: {
        branchCategoriesAvgIsFetched: false,
        branchCategoriesAvg: [] as IBranchCategoriesAvgs[],
        branchesInfoIsFetched: false,
        branchesInfo: [] as IBranchInfo[],
        globalDateFilter: [] as Date[], // [fromDate, toDate]
        compareItems: ['', ''],
        categoriesSuggestions: [] as ICategorySuggestion[],
        categoriesSuggestionCategoryName: '',
        categoriesSuggestionCategoryID: '',
        // colors: ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'],
        colors: ['#425D48', '#749C76', '#83946B', '#95A597'],
    },
    reducers: {
        setBranchCategoriesAvg(state, payload){
            state.branchCategoriesAvg = payload.payload;
            state.branchCategoriesAvgIsFetched = true;
        },
        setBranchesInfo(state, payload){
            state.branchesInfo = payload.payload;
            state.branchesInfoIsFetched = true;
        },
        setGlobalFilterDate(state, payload){
            state.globalDateFilter = payload.payload;
        },
        setCompareItems(state, payload){
            state.compareItems = payload.payload;
        },
        setCategoriesSuggestions(state, payload){
            state.categoriesSuggestions = payload.payload.data;
            state.categoriesSuggestionCategoryName = payload.payload.categoryName;
            state.categoriesSuggestionCategoryID = payload.payload.categoryID;
        }
    },
});

export const { 
    setBranchCategoriesAvg, 
    setBranchesInfo, 
    setGlobalFilterDate,
    setCompareItems,
    setCategoriesSuggestions
 } = analyticsSlice.actions;

export default analyticsSlice.reducer;

import { useSelector, useDispatch } from "react-redux";

import { Grid, Stack, Divider, Box } from "@mui/material";
import StorefrontIcon from "@mui/icons-material/Storefront";

import CardContainer from "../../../../Global/CardContainer";
import PieChartGraph from "../../../../Global/PieChart";

import { IBranchInfo } from "../../../../../Shared/Interfaces/IAnalytics";
import { setCompareItems } from "../../../../../Redux/Reducers/analyticsReducer";
import { RootState } from "../../../../../Redux/store";
import { RefObject } from "react";

interface IBranchCard {
  branchName: string;
  numberOfRatings: number;
  color: string;
  scrollRef: RefObject<HTMLDivElement>;
}

const BranchCard = ({
  branchName,
  numberOfRatings,
  color,
  scrollRef,
}: IBranchCard) => {
  const dispatch = useDispatch();
  const compareItem2 = useSelector(
    (state: RootState) => state.analytics.compareItems[1]
  );

  return (
    <Stack
      direction="row"
      padding={2}
      spacing={4}
      divider={<Divider flexItem orientation="vertical" />}
      alignItems="center"
      justifyContent="space-between"
      onClick={() => {
        dispatch(setCompareItems([branchName, compareItem2]));
        scrollRef.current?.scrollIntoView({ behavior: "smooth" });
      }}
      sx={{
        border: "1px solid #abc6d2",
        borderRadius: "5px",
        width: "500px",
        "&:hover": {
          cursor: "pointer",
        },
      }}
    >
      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <div>
          <StorefrontIcon />
          <div
            style={{
              fontSize: "18px",
              fontWeight: "700",
            }}
          >
            {branchName}
          </div>
        </div>
        <Stack direction="column" alignItems="center" gap={2}>
          <span
            style={{
              color: "#637a84",
            }}
          >
            number of rating
          </span>
          <span
            style={{
              fontWeight: "500",
            }}
          >
            {numberOfRatings}
          </span>
        </Stack>
      </Stack>
      <div
        style={{
          width: "50px",
          height: "50px",
          borderRadius: "5px",
          backgroundColor: `${color}`,
          border: "1px solid #67818b",
        }}
      ></div>
    </Stack>
  );
};

interface ISection2Props {
  section3Ref: RefObject<HTMLDivElement>;
  branchesInfo: IBranchInfo[];
}

const AnalyticsSection2 = ({ section3Ref, branchesInfo }: ISection2Props) => {
  const totalBranchesRatings = branchesInfo?.reduce(
    (acc, currBranch) => acc + currBranch.totalRatings,
    0
  ) as number;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CardContainer
          flexDirection="row"
          justifyContent="space-around"
          height="600px"
        >
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <Stack
              spacing={4}
              alignItems="center"
              justifyContent="center"
              height="500px"
            >
              {branchesInfo.length > 0 && 
              <Box
                sx={{
                  // backgroundColor: "red",
                  borderLeft: "10px solid #e0e0e0",
                  // borderRadius: "15px",
                  width: "550px",
                  maxHeight: "450px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  // paddingTop: "20px",
                  overflowY: "scroll",
                  gap: "12px",
                }}
              >
                {branchesInfo?.map((branch, idx) => {
                  return (
                    <BranchCard
                      key={idx}
                      branchName={branch.name}
                      color={branch.color}
                      numberOfRatings={branch.totalRatings}
                      scrollRef={section3Ref}
                    />
                  );
                })}
              </Box>}
            </Stack>
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={6}>
            {branchesInfo?.length > 0 && <PieChartGraph
              dataGraph={branchesInfo}
              pieDataKey="totalRatings"
              // colors={COLORS}
              totalRatings={totalBranchesRatings}
            />}
          </Grid>
        </CardContainer>
      </Grid>
    </Grid>
  );
};

export default AnalyticsSection2;

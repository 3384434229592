import { useSelector } from "react-redux";
import { IUserInfo } from "../../../../Shared/Interfaces/IUserInfo";
import Loader from "../../../Global/Loader";
import {
  IDashboardBranchData,
  IDashboardPackageData,
  IDashboardSuggestionData,
} from "../../../../Shared/Interfaces/IDashboard";
import { UserRoles } from "../../../../Shared/Constants/enums";
import {
  DashboardDataColoredContainer,
  DashboardDataColoredDataContainer,
  DashboardDataColoredDataHeaderText,
  DashboardDataColoredDataValueText,
  DashboardDataColoredLogoContiainer,
  DashboardDataWhiteContainer,
  DashboardDataWhitePieChartContainer,
  DashboardSingleSuggestionContainer,
  DashboardSuggestionsContainer,
} from "./styledComponents";
import DashboardPieChart from "./PieChart";
import { Grid } from "@mui/material";
import { Equalizer, PieChart, TrendingUp } from "@mui/icons-material";
import { TitleText } from "../../../Global/Text/TitleText";
import DashboardBarchart from "./BarChart";
import { RootState } from "../../../../Redux/store";
import { useGetDashboardQuery } from "../../../../features/dashboard/dashboardApiSlice.ts";
import { handleRTKError } from "../../../../Shared/Functions/RTKErrorHandler.ts";
import { useEffect } from "react";

interface IBarChartData {
  name: string;
  value: number;
  color?: string;
}

function DashboardPage() {
  const { data, isLoading, isError, error } = useGetDashboardQuery({});

  useEffect(() => {
    handleRTKError(isError, error);
  }, [isError, error]);
  const userInfo = useSelector(
    (state: RootState) => state.auth.userInfo
  ) as IUserInfo;

  return (
    <div>
      {isLoading && <Loader />}
      {data && (
        <Grid
          container
          style={{ maxWidth: "100%", marginLeft: 0, marginTop: 0 }}
          spacing={4}
          justifyContent="flex-start"
        >
          <Grid
            container
            style={{ marginLeft: 0, marginTop: 0 }}
            justifyContent="space-between"
            alignItems="flex-start"
            item
            spacing={4}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <DashboardDataColoredContainer>
                <DashboardDataColoredDataContainer>
                  <DashboardDataColoredDataHeaderText>
                    Ratings Submitted
                  </DashboardDataColoredDataHeaderText>
                  <DashboardDataColoredDataValueText>
                    {data.ratingCount}
                  </DashboardDataColoredDataValueText>
                </DashboardDataColoredDataContainer>
                <DashboardDataColoredLogoContiainer>
                  <TrendingUp sx={{ fontSize: 50 }} />
                </DashboardDataColoredLogoContiainer>
              </DashboardDataColoredContainer>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <DashboardDataColoredContainer>
                <DashboardDataColoredDataContainer>
                  <DashboardDataColoredDataHeaderText>
                    {userInfo.userRole === UserRoles.OWNER
                      ? "Number Of Active Companies"
                      : "Average Rating"}
                  </DashboardDataColoredDataHeaderText>
                  <DashboardDataColoredDataValueText>
                    {userInfo.userRole === UserRoles.OWNER
                      ? data.companyCount
                      : data?.averageRating?.toFixed(2)}
                  </DashboardDataColoredDataValueText>
                </DashboardDataColoredDataContainer>
                <DashboardDataColoredLogoContiainer>
                  {userInfo.userRole === UserRoles.OWNER ? (
                    <PieChart sx={{ fontSize: 50 }} />
                  ) : (
                    <Equalizer sx={{ fontSize: 50 }} />
                  )}
                </DashboardDataColoredLogoContiainer>
              </DashboardDataColoredContainer>
            </Grid>
            {data?.companyData?.length > 0 && (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <DashboardBarchart
                  titleText="Average Per Company"
                  data={
                    data?.companyData as unknown as {
                      [key: string]: string | number;
                    }[]
                  }
                  xAxisDataKey="companyName"
                  barDataKey="average"
                />
              </Grid>
            )}
            {data?.branchData?.length > 0 && (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <DashboardBarchart
                  titleText="Average Per Branch"
                  data={
                    data.branchData as unknown as {
                      [key: string]: string | number;
                    }[]
                  }
                  xAxisDataKey="branchName"
                  barDataKey="average"
                />
              </Grid>
            )}

            {/* Put Count PieChart Here */}
            {data?.companyData?.length > 0 && (
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <DashboardDataWhitePieChartContainer>
                  <DashboardPieChart
                    data={
                      data?.companyData.map(
                        (comp: {
                          companyName: string | number;
                          count: string | number;
                        }) => {
                          const compPieChartData = {} as {
                            [key: string]: string | number;
                          };
                          compPieChartData.name = comp.companyName;
                          compPieChartData.value = comp.count;
                          return compPieChartData;
                        }
                      ) as unknown as IBarChartData[]
                    }
                    title="Companies"
                  />
                </DashboardDataWhitePieChartContainer>
              </Grid>
            )}
            {data.branchData?.length > 0 && (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <DashboardDataWhitePieChartContainer>
                  <DashboardPieChart
                    data={data.branchData?.map(
                      (branch: IDashboardBranchData) => {
                        const b = {} as IBarChartData;
                        b.name = branch.branchName;
                        b.value = branch.count;
                        return b;
                      }
                    )}
                    title="Branches"
                  />
                </DashboardDataWhitePieChartContainer>
              </Grid>
            )}
            {userInfo.userRole === UserRoles.OWNER &&
              data?.companyCountPerPackage?.length > 0 && (
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <DashboardDataWhitePieChartContainer>
                    {userInfo.userRole === UserRoles.OWNER && (
                      <DashboardPieChart
                        data={data?.companyCountPerPackage?.map((comp: IDashboardPackageData) => {
                          const compPieChartData = {} as IBarChartData;
                          compPieChartData.name = comp.packageName;
                          compPieChartData.value = comp.count;
                          return compPieChartData;
                        })}
                        title="Packages"
                      />
                    )}
                  </DashboardDataWhitePieChartContainer>
                </Grid>
              )}
            {data.categoryData?.length > 0 && (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <DashboardBarchart
                  titleText="Average Per Category"
                  data={
                    data.categoryData as unknown as {
                      [key: string]: string | number;
                    }[]
                  }
                  xAxisDataKey="categoryName"
                  barDataKey="average"
                />
              </Grid>
            )}
            {data.formFieldData?.length > 0 && (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <DashboardBarchart
                  titleText="Average Per Field"
                  data={
                    data.formFieldData as unknown as {
                      [key: string]: string | number;
                    }[]
                  }
                  xAxisDataKey="fieldName"
                  barDataKey="average"
                />
              </Grid>
            )}
            <Grid container justifyContent="center" spacing={4}>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}></Grid>
            </Grid>

            {/* TOP POSITIVE/NEGATIVE SUGGESTIONS CONTAINER */}
            {data?.topPositiveSuggestions?.length > 0 && (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <DashboardDataWhiteContainer
                  style={{ justifyContent: "flex-start" }}
                >
                  <TitleText style={{ marginInlineStart: "16px" }}>
                    Top 5 Positive Suggestions
                  </TitleText>
                  <DashboardSuggestionsContainer>
                    {data?.topPositiveSuggestions?.map(
                      (suggestion: IDashboardSuggestionData, index: number) => {
                        return (
                          <DashboardSingleSuggestionContainer key={index}>
                            {(index + 1).toString() +
                              ". " +
                              suggestion.suggestionName}
                          </DashboardSingleSuggestionContainer>
                        );
                      }
                    )}
                  </DashboardSuggestionsContainer>
                </DashboardDataWhiteContainer>
              </Grid>
            )}
            {data?.topNegativeSuggestions?.length > 0 && (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <DashboardDataWhiteContainer
                  style={{ justifyContent: "flex-start" }}
                >
                  <TitleText style={{ marginInlineStart: "16px" }}>
                    Top 5 Negative Suggestions
                  </TitleText>
                  <DashboardSuggestionsContainer>
                    {data?.topNegativeSuggestions?.map(
                      (suggestion: IDashboardSuggestionData, index: number) => {
                        return (
                          <DashboardSingleSuggestionContainer key={index}>
                            {(index + 1).toString() +
                              ". " +
                              suggestion.suggestionName}
                          </DashboardSingleSuggestionContainer>
                        );
                      }
                    )}
                  </DashboardSuggestionsContainer>
                </DashboardDataWhiteContainer>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default DashboardPage;

import { Menu, MenuItem, Box } from "@mui/material";
import Lottie from "lottie-react";
import { useSnackbar } from "notistack";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { IBranch } from "../../../../Shared/Interfaces/IBranch";
import { useGetCompanyBranchesQuery } from "../../../../features/branch/branchApiSlice";
import {
  RatingHomePageContainer,
  HeaderContainer,
  HeaderText,
  BranchName,
  SelectedBranchContainer,
  FortuneContainer,
  SubHeader,
  InfoContainer,
  IconContainer,
  Button,
} from "./styledComponents";

import Fortune from "../../../../Assets/RatingPage/HomePage/fortune.svg?react";
import ArrowDown from "../../../../Assets/RatingPage/HomePage/arrowDown.svg?react";
import Timer from "../../../../Assets/RatingPage/HomePage/time.svg?react";
import Cookie from "../../../../Assets/RatingPage/HomePage/AnimatedCookie.json";

interface IHomeScreen {
  selectedBranch: IBranch | null;
  setSeletectedBranch: Dispatch<SetStateAction<IBranch | null>>;
  setClasses: Dispatch<SetStateAction<string[]>>;
}

const HomeScreen = ({
  selectedBranch,
  setSeletectedBranch,
  setClasses,
}: IHomeScreen) => {
  const { enqueueSnackbar } = useSnackbar();
  const { companyID } = useParams();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleBranchClicked = (branch: IBranch) => {
    setSeletectedBranch(branch);
    handleMenuClose();
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { data: branches, isSuccess: branchesIsSuccess } =
    useGetCompanyBranchesQuery({ companyID });
  const [companyName, setCompanyName] = useState();
  const [companyNameAr, setCompanyNameAr] = useState();

  const beginRatingHandler = () => {
    if (!selectedBranch) {
      enqueueSnackbar("You should select a branch first", {
        variant: "warning",
      });
      return;
    }

    setClasses(["moveLeftCls", "moveLeftCls", "moveLeftCls"]);
  };

  useEffect(() => {
    if (branchesIsSuccess) {
      setCompanyName(branches.meta.companyName);
      setCompanyNameAr(branches.meta.companyNameAr);
    }
  }, [branchesIsSuccess]);

  return (
    <RatingHomePageContainer>
      {branches?.meta?.logo && (
        <img
          src={branches?.meta?.logo}
          style={{ maxWidth: "10rem" }}
          alt="Restaurant Logo"
        />
      )}
      <HeaderContainer>
        <HeaderText>{t("rateYourExperience")}</HeaderText>
        <span>
          {t("pleaseShareThoughts")} <br />
          <BranchName>
            {currentLanguage == "ar" ? companyNameAr : companyName}
          </BranchName>
        </span>
      </HeaderContainer>

      <SelectedBranchContainer
        style={{ cursor: "pointer" }}
        onClick={handleButtonClick}
      >
        <span>{t("viewing")}</span>
        <BranchName>
          {currentLanguage == "ar"
            ? selectedBranch?.nameAr
            : selectedBranch?.name}
        </BranchName>
        <ArrowDown style={{ margin: "0px 0px 0px auto" }} />
      </SelectedBranchContainer>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        sx={{ width: "365px" }}
      >
        {branches?.result?.map((branch: IBranch, idx: number) => {
          return (
            <MenuItem
              key={idx}
              sx={{ width: "355px", paddingRight: "40px" }}
              onClick={() => handleBranchClicked(branch)}
              dir={currentLanguage == "ar" ? "rtl" : "ltr"}
            >
              {currentLanguage == "ar" ? branch.nameAr : branch.name}
            </MenuItem>
          );
        })}
      </Menu>

      <FortuneContainer>
        <Box
          sx={{
            width: "75px",
            height: "75px",
            marginTop: "-95px",
          }}
        >
          <Lottie animationData={Cookie} />
        </Box>
        <SubHeader>{t("readyToGetStarted")}</SubHeader>
        <InfoContainer>
          <IconContainer>
            <Timer />
            {t("averageTime")}
          </IconContainer>
          <IconContainer>
            <Fortune />
            {t("readYourFortune")}
          </IconContainer>
        </InfoContainer>
        <Button width="303px" onClick={beginRatingHandler}>
          {t("beginRating")}
        </Button>
        {t("rateToUnlockFortune")}
      </FortuneContainer>
    </RatingHomePageContainer>
  );
};

export default HomeScreen;

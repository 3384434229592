import React, {useEffect, useState} from 'react';
import {Switch} from '@mui/material';
import {handleRTKError} from '../Shared/Functions/RTKErrorHandler.ts';
import {IBranch} from '../Shared/Interfaces/IBranch.ts';
import {ICompanyFullInfo} from '../Shared/Interfaces/ICompany.ts';
import {IUserDetails} from "../Shared/Interfaces/IUser.ts";


interface MutationFunction {
    (): [
        (params: { id: string; isActive: boolean }) => Promise<void>,
        { isError: boolean; isSuccess: boolean; error: unknown }
    ] | any;
}

interface CustomSwitchProps<T> {
    entity: T;
    mutation: MutationFunction;
}

const ActiveSwitch: React.FC<CustomSwitchProps<IBranch | ICompanyFullInfo | IUserDetails>> = ({entity, mutation}) => {
    const [checked, setChecked] = useState(entity?.isActive);
    const [activateDeactivateEntity, {isError, error, isSuccess}] = mutation()
    async function toggleEntityActivation(entity: IBranch | ICompanyFullInfo | IUserDetails) {
        activateDeactivateEntity({
            id: entity._id,
            isActive: !checked,
        });

    }
    useEffect(() => {
        setChecked(entity?.isActive);
    }, [entity?.isActive]);

    useEffect(() => {
        if (isError) {
            handleRTKError(isError, error);
        } 
        
    }, [isError, isSuccess]);
    return (
        <>
            <Switch
                checked={checked}
                color="primary"
                onChange={() => {
                    toggleEntityActivation(entity).then(() => setChecked(!checked))
                }}
            />
        </>
    );
};

export default ActiveSwitch;

export enum packageTypes {
  free = 1,
  starter = 2,
  booster = 3,
  premium = 4,
  custom = 5,
}

export enum categoryTypes {
  priceValue = 1,
  userExperience = 2,
  service = 3,
  settingOrAtmosphere = 4,
  foodQuality = 5,
}

export const userTypes = {
  ownerType: "owner",
  superCompanyAdminType: "superCompany",
  companyAdminType: "company",
  brandAdminType: "brand",
  branchAdminType: "branch",
};

export enum UserRoles {
  OWNER = "owner",
  COMPANY_ADMIN = "company",
  BRAND_ADMIN = "brand",
  BRANCH_ADMIN = "branch",
}

export enum ratingStatusTypes {
  complete = 1,
  inprogress = 2,
}

export enum WheelOptionType {
  PRIZE = 'prize',
  DISCOUNT = 'discount',
  COOKIE = 'cookie',
  GOOD_LUCK = 'good_luck',
}

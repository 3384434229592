/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSelector } from "react-redux";
import { getLanguage } from "../../../../Shared/Functions/language";
import { setTranslatedText } from "../../../../Shared/Functions/setTranslatedText";
import { useEffect, useState } from "react";
import Loader from "../../../Global/Loader";
import CustomDialog from "../../../Global/CustomDialog";
import { IFormField } from "../../../../Shared/Interfaces/IFormField";
import { PageHeaderContainer } from "../CompaniesPage/styledComponents";
import {
  FormFieldCardContainer,
  FormFieldCardHeaderContainer,
  FormFieldCardHeaderTextContainer,
  FormFieldCardHeaderTitle,
  FormFieldCardHeaderTitleData,
  FormFieldPosNegSuggestionsBigContainer,
  FormFieldPosNegSuggestionsListContainer,
  FormFieldPosNegSuggestionsTitle,
  FormFieldSuggestionsBigContainer,
  FormFieldsContainer,
  FormFieldPosNegSuggestionsListItem,
  FormFieldPosSuggestions,
  FormFieldNegSuggestions,
  TopLeftEditButton,
} from "./styledComponents";
import AddEditFormFieldSideForm from "./AddEditFormFieldSideForm";
import { useGetCompanyFormFieldsQuery } from "../../../../features/company/companyApiSlice.ts";
import { handleRTKError } from "../../../../Shared/Functions/RTKErrorHandler.ts";
import { RootState } from "../../../../Redux/store.ts";
import { IUserInfo } from "../../../../Shared/Interfaces/IUserInfo.ts";
import CTAButton from "../../../Global/Buttons/CTAButton/index.tsx";
import { UserRoles } from "../../../../Shared/Constants/enums.ts";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DefaultDialog from "../../../Global/DefaultDialog/index.tsx";
import { useDeleteFormFieldMutation } from "../../../../features/formField/formFieldApiSlice.ts";


interface ITranslation {
  addField: string;
  editField: string;
  fieldName: string;
  fieldType: string;
  category: string;
  positiveSuggestions: string;
  negativeSuggestions: string;
  actions: string;
}

function FormsPage() {
  const lang = getLanguage();
  const staticTextKeys = [
    "addField",
    "editField",
    "fieldName",
    "fieldType",
    "category",
    "actions",
    "positiveSuggestions",
    "negativeSuggestions",
  ];
  const staticText = setTranslatedText(lang, staticTextKeys) as ITranslation;
  const userInfo = useSelector(
    (state: RootState) => state.auth.userInfo
  ) as IUserInfo;

  const isOwner = userInfo.userRole === UserRoles.OWNER;
  const companyID = userInfo.companyID;

  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deletedFormFieldID, setDeletedFormFieldID] = useState("");
  const [selectedFormField, setSelectedFormField] = useState<IFormField | null>(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClose = (_event: any, reason: any) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
    setEdit(false);
    getAllFormFields();
  };

  const { data, refetch, error, isLoading, isError } =
    useGetCompanyFormFieldsQuery({companyID, isOwner});

  const [ deleteFormField ] = useDeleteFormFieldMutation();

  const canAddFormFields = 
    isOwner || !(data?.meta?.companyPackage?.configurations?.useDefaultForms);

  async function getAllFormFields() {
    await refetch();
    handleRTKError(isError, error);
  }

  function handleEditClick(formField: IFormField) {
    setSelectedFormField(formField);
    setEdit(true);
    setOpen(true);
  }

  const openDialConfirmation = (formFieldID: string) => {
    setDialogOpen(true);
    setDeletedFormFieldID(formFieldID);
  }

  const confirmDeleteFormField = () => {
    setDialogOpen(false);
    deleteFormField(deletedFormFieldID);
    refetch();
  }

  useEffect(() => {
    getAllFormFields();
  }, []);

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <DefaultDialog
            open={dialogOpen}
            setOpen={setDialogOpen}
            dialogTitle="Do You Want Delete This FormField ?"
            dialogText={`If you deleted a formfield, you won't able to restore it.
            and will remove all the rating related to this formfield`}
            confirmFn={confirmDeleteFormField}
          />
          <PageHeaderContainer>
            {canAddFormFields && (
              <CTAButton action={handleClickOpen} buttonText="Add Form Field" />
            )}
          </PageHeaderContainer>
          <FormFieldsContainer>
            {data?.result?.map((formField: IFormField, index: any) => {
              return (
                <FormFieldCardContainer key={formField._id || index}>
                  <FormFieldCardHeaderContainer>
                    <FormFieldCardHeaderTextContainer>
                      <FormFieldCardHeaderTitle>
                        {staticText.fieldName + ":"}
                      </FormFieldCardHeaderTitle>
                      <FormFieldCardHeaderTitleData>
                        {formField.name}
                      </FormFieldCardHeaderTitleData>
                    </FormFieldCardHeaderTextContainer>
                    <FormFieldCardHeaderTextContainer>
                      <FormFieldCardHeaderTitle>
                        {staticText.category + ":"}
                      </FormFieldCardHeaderTitle>
                      <FormFieldCardHeaderTitleData>
                        {formField.categoryRef.name}
                      </FormFieldCardHeaderTitleData>
                    </FormFieldCardHeaderTextContainer>
                  </FormFieldCardHeaderContainer>
                  <FormFieldSuggestionsBigContainer>
                    <FormFieldPosNegSuggestionsBigContainer>
                      <FormFieldPosNegSuggestionsTitle>
                        {staticText.positiveSuggestions}
                      </FormFieldPosNegSuggestionsTitle>
                      <FormFieldPosNegSuggestionsListContainer>
                        {formField.positiveSuggestions?.map(
                          (suggestion, index) => {
                            return (
                              <FormFieldPosNegSuggestionsListItem key={index}>
                                <FormFieldPosSuggestions>
                                  {(index + 1).toString() +
                                    ". " +
                                    suggestion.name}
                                </FormFieldPosSuggestions>
                              </FormFieldPosNegSuggestionsListItem>
                            );
                          }
                        )}
                      </FormFieldPosNegSuggestionsListContainer>
                    </FormFieldPosNegSuggestionsBigContainer>
                    <FormFieldPosNegSuggestionsBigContainer>
                      <FormFieldPosNegSuggestionsTitle>
                        {staticText.negativeSuggestions}
                      </FormFieldPosNegSuggestionsTitle>
                      <FormFieldPosNegSuggestionsListContainer>
                        {formField.negativeSuggestions?.map(
                          (suggestion, index) => {
                            return (
                              <FormFieldPosNegSuggestionsListItem key={index}>
                                <FormFieldNegSuggestions>
                                  {(index + 1).toString() +
                                    ". " +
                                    suggestion.name}
                                </FormFieldNegSuggestions>
                              </FormFieldPosNegSuggestionsListItem>
                            );
                          }
                        )}
                      </FormFieldPosNegSuggestionsListContainer>
                    </FormFieldPosNegSuggestionsBigContainer>
                    {canAddFormFields && 
                      <TopLeftEditButton>
                      <IconButton>
                        <DeleteIcon onClick={() => openDialConfirmation(formField?._id.toString() as string)} />
                      </IconButton>
                      <IconButton>
                        <EditIcon onClick={() => handleEditClick(formField)} />
                      </IconButton>
                      </TopLeftEditButton>
                    }
                  </FormFieldSuggestionsBigContainer>
                </FormFieldCardContainer>
              );
            })}
          </FormFieldsContainer>

          {/* Dialog Area */}
          <CustomDialog
            open={open}
            handleClose={handleClose}
            titleText={`${edit ? "Update" : "Add"} FormField`}
          >
            <AddEditFormFieldSideForm 
            isEdit={edit} 
            formFieldItem={selectedFormField as IFormField} 
            actionAfterSubmit={handleClose} />
          </CustomDialog>
        </>
      )}
    </div>
  );
}

export default FormsPage;

import { Box, Divider, MenuItem, Stack, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { IBranchInfo } from "../../../../../Shared/Interfaces/IAnalytics";
import { setCompareItems } from "../../../../../Redux/Reducers/analyticsReducer";
import { useEffect } from "react";
import { RootState } from "../../../../../Redux/store";

const CompareBranchesSelectComponent = () => {
  const dispatch = useDispatch();
  const branchesInfo = useSelector(
    (state: RootState) => state.analytics.branchesInfo
  ) as IBranchInfo[];
  const [compareItem1, compareItem2] = useSelector(
    (state: RootState) => state.analytics.compareItems
  );

  const onItem1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setCompareItems([event.target.value, compareItem2]));
  };

  const onItem2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setCompareItems([compareItem1, event.target.value]));
  };

  useEffect(() => {
    const compareItems = [
      branchesInfo?.length > 0 ? branchesInfo[0]?.name : "",
      "All Branches",
    ];
    dispatch(setCompareItems([...compareItems]));
  }, [branchesInfo]);

  return (
    <Stack width="600px" direction="row" gap={4}>
      <Box width="100%">
        <TextField
          label="Select Branch"
          select
          value={compareItem1}
          onChange={onItem1Change}
          fullWidth
        >
          {branchesInfo?.map((branch, idx) => {
            return (
              <MenuItem key={idx} value={branch?.name}>
                {branch?.name}
              </MenuItem>
            );
          })}
        </TextField>
      </Box>
      <Divider flexItem orientation="vertical" />
      <Box width="100%">
        <TextField
          label="Select Branch"
          select
          value={compareItem2}
          onChange={onItem2Change}
          fullWidth
        >
          <MenuItem value="All Branches">All Branches</MenuItem>
          {branchesInfo?.map((branch, idx) => {
            return (
              <MenuItem key={idx} value={branch?.name}>
                {branch?.name}
              </MenuItem>
            );
          })}
        </TextField>
      </Box>
    </Stack>
  );
};

export default CompareBranchesSelectComponent;

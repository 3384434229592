import {useSnackbar} from "notistack";
import {CustomDialogSubContainer} from "../../../Global/CustomDialog/styledComponents";
import Loader from "../../../Global/Loader";
import DefaultForm from "../../../Global/DefaultForm";
import DefaultInput from "../../../Global/DefaultInput";
import {zodResolver} from "@hookform/resolvers/zod";
import {Controller, useForm} from "react-hook-form";
import {z} from "zod";
import {useEffect, useState} from "react";
import {getLanguage} from "../../../../Shared/Functions/language";
import {setTranslatedText} from "../../../../Shared/Functions/setTranslatedText";
import CTAButton from "../../../Global/Buttons/CTAButton";
import {useParams} from "react-router-dom";
import {
    useAddCompanyBranchMutation,
    useUpdateBranchMutation
} from "../../../../features/branch/branchApiSlice.ts";
import {handleRTKError} from "../../../../Shared/Functions/RTKErrorHandler.ts";
import {DefaultAutocomplete} from "../../../Global/DefaultAutocomplete/styledComponents.tsx";
import {TextField} from "@mui/material";
import {useGetCompanyBrandsQuery} from "../../../../features/company/companyApiSlice.ts";
import {IBrand} from "../../../../Shared/Interfaces/IBrand.ts";
import {useSelector} from "react-redux";
import {RootState} from "../../../../Redux/store.ts";
import {IUserInfo} from "../../../../Shared/Interfaces/IUserInfo.ts";
import { IBranch } from "../../../../Shared/Interfaces/IBranch.ts";
import { UserRoles } from "../../../../Shared/Constants/enums.ts";

const addEditBranch = z.object({
    name: z.string().min(1, "required"),
    nameAr: z.string().min(1, "required"),
    googleMapsURL: z.string().optional(),
    // brandID: z.string().optional(),
});

interface ITranslation {
    name: string;
    nameAr: string;
    googleMapsURL: string;
    submit: string;
}
interface IProp {
    actionAfterSubmit: () => void;
    branchItem?: IBranch;
    isEdit?: boolean;
}

function AddEditBranchSideForm({actionAfterSubmit, branchItem, isEdit = false}: IProp) {
    const lang = getLanguage();
    const staticTextKeys = ["name", "googleMapsURL", "submit"];
    const staticText = setTranslatedText(lang, staticTextKeys) as ITranslation;
    const [brandID, setBrandID] = useState('');
    const userInfo = useSelector((state: RootState) => state.auth.userInfo) as IUserInfo;
    let companyID = useParams().companyID
    if ( userInfo.userRole !== UserRoles.OWNER){
        companyID = userInfo.companyID;
    }

    const {enqueueSnackbar} = useSnackbar();

    const {
        register,
        control,
        handleSubmit,
        formState,
        setValue,
    } = useForm({
        resolver: zodResolver(addEditBranch),
    });

    const {errors} = formState;

    const [addBranch, {
        isError: isAddBranchError,
        isLoading: isAddBranchLoading,
        isSuccess: isAddBranchSuccess,
        error: addBranchError
    }] = useAddCompanyBranchMutation();

    const [updateBranch] = useUpdateBranchMutation();

    const {data: brands} = useGetCompanyBrandsQuery(companyID)

    useEffect(() => {
        handleRTKError(isAddBranchError, addBranchError);
    }, [isAddBranchError, addBranchError]);

    async function handleAddEditBranch(formData: any) {
        if (isEdit){
            console.log("The data : ", );
            console.log(formData.name);
            console.log(formData.nameAr);
            console.log(formData.googleMapsURL);
            updateBranch({companyID, brandID, payload: {
                _id: branchItem?._id,
                name: formData.name,
                nameAr: formData.nameAr,
                locationURL: formData.googleMapsURL,
            }})
        } else {
            addBranch({
            companyID, brandID, payload: {
                name: formData.name,
                nameAr: formData.nameAr,
                locationURL: formData.googleMapsURL,
            }
        })
        }
        actionAfterSubmit();
    }

    useEffect(() => {
        if (isAddBranchSuccess) {
            enqueueSnackbar("success", {variant: "success"});
        }
    }, [isAddBranchSuccess]);

    useEffect(() => {
        if (isEdit && branchItem) {
            setValue("name", branchItem.name);
            setValue("nameAr", branchItem.nameAr);
            setValue("googleMapsURL", branchItem.locationURL);
            setValue("brand", branchItem.brandID);
            setBrandID(branchItem.brandID as string);
        }
    }, [isEdit, branchItem]);
    if (isAddBranchLoading) {
        return <Loader/>
    }
    return (
        <CustomDialogSubContainer>
            <DefaultForm onSubmit={handleSubmit(handleAddEditBranch)}>
                <DefaultInput
                    label={staticText.name}
                    register={register("name")}
                    error={errors.name?.message ? true : false}
                />
                <DefaultInput
                    label="NameAr"
                    register={register("nameAr")}
                    error={errors.nameAr?.message ? true : false}
                />
                <DefaultInput label={staticText.googleMapsURL} register={register("googleMapsURL")}/>
                <Controller
                    control={control}
                    name="brand"
                    render={({field}) => (
                        <DefaultAutocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={brands}
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            getOptionLabel={(brand: any) => brand?.name}
                            value={
                                brands?.find(
                                    (brand: IBrand) => brand?._id === field.value
                                ) || null
                            }
                            onChange={(_event: any, newValue: any) => {
                                field?.onChange(newValue?._id ?? null);
                                setBrandID(newValue?._id)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Brand"
                                />
                            )}
                        />
                    )}
                ></Controller>
                <CTAButton
                    buttonText={staticText.submit}
                    buttonType="submit"
                    action={() => {
                        console.log(errors);
                    }}
                    fullWidthCheck
                />
            </DefaultForm>
        </CustomDialogSubContainer>
    );
}

export default AddEditBranchSideForm;

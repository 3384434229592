import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


import CardContainer from "../CardContainer"
import { TitleText } from "../Text/TitleText"
import { IBarChartGraph } from '../../../Shared/Interfaces/IBarChart';
import { useSelector } from 'react-redux';
import { IBranchInfo } from '../../../Shared/Interfaces/IAnalytics';
import { RootState } from '../../../Redux/store';

const mainColor = import.meta.env.VITE_MAIN_COLOR;

const BarChartGraph = ({
  graphData,
  titleText,
  bar1DataKey,
  bar1Color = mainColor,
  bar2DataKey,
  bar2Color = mainColor,
  graphHeight = '500px',
  titleTextSize = '26px',
  xAxisDataKey,
  yAxisDataKey,
  barMaxSize = 120,
  twoBars,
  showRatingCount = false,
  onClickFn = () => {},
}: IBarChartGraph) => {

  const [compareItem] = useSelector((state: RootState) => state.analytics.compareItems);
  const branchesInfo = useSelector((state: RootState) => state.analytics.branchesInfo) as IBranchInfo[];

  return (
    <CardContainer height={graphHeight}>
      <TitleText fontSize={titleTextSize}>{titleText}</TitleText>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={graphData}
          margin={{
            top: 5,
            right: 5,
            left: 2,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xAxisDataKey} />
          {
            yAxisDataKey ? <YAxis dataKey={yAxisDataKey} /> : <YAxis type="number" domain={[0, 5]} />
          }
            <Tooltip
                formatter={(value, name, props) => (
                    showRatingCount ? [
                        `${name}: ${value}`,
                        `Rating Count: ${props.payload.totalRatings || 'N/A'}`,
                    ] : [ `${name}: ${value}`, ]
                )}
            />
          <Legend />
          <Bar cursor='pointer' dataKey={bar1DataKey} fill={twoBars ? branchesInfo.find(b => b.name === compareItem)?.color : bar1Color} maxBarSize={barMaxSize} onClick={onClickFn}/>
          {
            bar2DataKey &&
            bar2Color &&
            <Bar cursor='pointer' dataKey={bar2DataKey} fill={bar2Color} maxBarSize={barMaxSize} onClick={onClickFn}/>
          }
        </BarChart>
      </ResponsiveContainer>
    </CardContainer>
  )
}

export default BarChartGraph
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";


export const Tag = styled(Box)(({theme}) => ({
  textTransform: 'uppercase',
  paddingTop: '4px',
  paddingBottom: '4px',
  paddingLeft: '16px',
  paddingRight: '16px',
  backgroundColor: theme.palette.primary.light,
  borderWidth: '1px',
  borderColor: theme.palette.primary.main,
  borderRadius: '15px',
  borderStyle: 'solid',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.primary.main,
}));
import { RefObject, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid, Stack, Divider, Box, Typography } from "@mui/material";
import LunchDiningIcon from "@mui/icons-material/LunchDining";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ExploreIcon from "@mui/icons-material/Explore";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

import { IUserInfo } from "../../../../../Shared/Interfaces/IUserInfo";
import {
  IBranchInfo,
  ICategorySuggestion,
} from "../../../../../Shared/Interfaces/IAnalytics";

import { setCategoriesSuggestions } from "../../../../../Redux/Reducers/analyticsReducer";

import CardContainer from "../../../../Global/CardContainer";
import BarChartGraph from "../../../../Global/BarChart";
import { TitleText } from "../../../../Global/Text/TitleText";

import { RootState } from "../../../../../Redux/store";
import {
  useGetBranchTopSuggestionsQuery,
  useGetCategorySuggestionsQuery,
} from "../../../../../features/analytics/analyticsApiSlice.ts";
import { handleRTKError } from "../../../../../Shared/Functions/RTKErrorHandler.ts";
import Loader from "../../../../Global/Loader/index.tsx";
import { useGetAllCategoriesQuery } from "../../../../../features/category/categoryApiSlice.ts";

const mainColor = import.meta.env.VITE_MAIN_COLOR;

interface ITableRow {
  Icon: React.ReactNode;
  cell1Value: string | number;
  cell2Value: string | number;
  cell3Value: string | number;
  bgColor?: string;
  onCategoryClickFn?: () => void;
}

const TableRow = ({
  Icon,
  cell1Value,
  cell2Value,
  cell3Value,
  bgColor,
  onCategoryClickFn,
}: ITableRow) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      bgcolor={bgColor}
      color={bgColor && "common.white"}
      paddingY={bgColor && 4}
    >
      <Box
        sx={{
          width: "30%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          fontSize: "20px",
          fontWeight: "700",
          paddingLeft: "50px",
          gap: "10px",
          cursor: "pointer",
        }}
        onClick={onCategoryClickFn}
      >
        {Icon}
        <div>{cell1Value}</div>
      </Box>

      <Box
        sx={{
          width: "30%",
          display: "flex",
          justifyContent: "center",
          fontSize: "18px",
          fontWeight: "500",
        }}
      >
        {cell2Value}
      </Box>

      <Box
        sx={{
          width: "30%",
          display: "flex",
          justifyContent: "center",
          fontSize: "18px",
          fontWeight: "500",
        }}
      >
        {cell3Value}
      </Box>
    </Stack>
  );
};

interface ISection3Props {
  categorySuggestionsRef: RefObject<HTMLDivElement>;
  branchesInfo: IBranchInfo[];
}

const AnalyticsSection3 = ({
  categorySuggestionsRef,
  branchesInfo,
}: ISection3Props) => {
  const dispatch = useDispatch();
  const userInfo = useSelector(
    (state: RootState) => state.auth.userInfo
  ) as IUserInfo;

  const [fromDate, toDate] = useSelector(
    (state: RootState) => state.analytics.globalDateFilter
  );

  const [compareItem1, compareItem2] = useSelector(
    (state: RootState) => state.analytics.compareItems
  );

  const categoryID = useSelector((state: RootState) => state.analytics.categoriesSuggestionCategoryID);

  const [totalRating1, setTotalRating1] = useState(0);
  const [totalRating2, setTotalRating2] = useState(0);
  const {
    data: topSuggestions,
    isLoading,
    isError,
    refetch,
    error,
  } = useGetBranchTopSuggestionsQuery({
    branchID:
      branchesInfo
        ?.find((branch) => branch.name === compareItem1.toString())
        ?.id?.toString(),
    suggestionType: "positive",
    companyID: userInfo.companyID ,
    fromDate,
    toDate,
  });

  const { data: categories } = useGetAllCategoriesQuery({});
  
  useEffect(() => {
    handleRTKError(isError, error);
  }, [isError, error]);

  const compareTableRef = useRef<HTMLDivElement>(null);
  const { data: categorySuggestions } = useGetCategorySuggestionsQuery({
    companyID: userInfo.companyID,
    branchID: userInfo.branchID,
    categoryID: categoryID,
    fromDate: fromDate,
    toDate: toDate,
  });

  useEffect(() => {
  }, [categoryID]);

  const categoryName = useSelector(
    (state: RootState) => state.analytics.categoriesSuggestionCategoryName
  ) as string;

  const [graphData, setGraphData] = useState<
    { [key: string]: number | string | React.ReactNode }[]
  >([
    {
      catName: "Food",
      Icon: <LunchDiningIcon fontSize="large" />,
    },
    {
      catName: "Experience",
      Icon: <ExploreIcon fontSize="large" />,
    },
    {
      catName: "Price",
      Icon: <MonetizationOnIcon fontSize="large" />,
    },
    {
      catName: "Service",
      Icon: <EmojiEmotionsIcon fontSize="large" />,
    },
    {
      catName: "Atmosphere",
      Icon: <AcUnitIcon fontSize="large" />,
    },
  ]);

  const buildGraphData = (branchName: string) => {
    // food
    graphData[0] = {
      ...graphData[0],
      [branchName]: getCellData(branchName, "Food"),
    };
    // experience
    graphData[1] = {
      ...graphData[1],
      [branchName]: getCellData(branchName, "Experience"),
    };
    // price
    graphData[2] = {
      ...graphData[2],
      [branchName]: getCellData(branchName, "Price"),
    };
    // service
    graphData[3] = {
      ...graphData[3],
      [branchName]: getCellData(branchName, "Service"),
    };
    // atmoshere
    graphData[4] = {
      ...graphData[4],
      [branchName]: getCellData(branchName, "Atmosphere"),
    };
    setGraphData([...graphData]);
  };

  const getCellData = (branchName: string, catName: string) => {
    let catVal;
    if (branchName === "All Branches") {
      catVal = branchesInfo?.reduce((acc, branch) => {
        const val =
          branch?.categories?.find((cat) =>
            cat?.name?.toLowerCase()?.includes(catName?.toLowerCase())
          )?.totalAvg ?? 0;
        return val + acc;
      }, 0);
      catVal /= branchesInfo?.length;
    } else {
      catVal = branchesInfo
        ?.find((branch) => branch.name === branchName)
        ?.categories?.find((cat) =>
          cat?.name?.toLowerCase()?.includes(catName?.toLowerCase())
        )?.totalAvg;
    }

    return catVal?.toFixed(2);
  };

  const getBranchTopSuggestions = async () => {
    refetch();
  };

  useEffect(() => {
    setTotalRating1(
      branchesInfo?.find((branch) => branch?.name?.includes(compareItem1))
        ?.totalRatings ?? 0
    );
    setTotalRating2(
      compareItem2 === "All Branches"
        ? branchesInfo?.reduce((acc, branch) => acc + branch.totalRatings, 0)
        : branchesInfo?.find((branch) => branch?.name?.includes(compareItem2))
            ?.totalRatings ?? 0
    );

    buildGraphData(compareItem1);
    buildGraphData(compareItem2);

    getBranchTopSuggestions();
  }, [compareItem1, compareItem2]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const barOnClickFn = async (bar: any) => {
    const categoryName = bar?.catName?.toLowerCase() as string;

    const category = (
      categories as {
        _id: string;
        name: string;
        nameAr: string;
      }[]
    )?.find((category) =>
      category?.name?.toLowerCase()?.includes(categoryName?.toLowerCase())
    );

    const categoryID = category?._id.toString();

    dispatch(
      setCategoriesSuggestions({
        categoryName,
        categoryID,
      })
    );

    categorySuggestionsRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Grid container spacing={8}>
      {/* TABLE COMPARE */}
      {compareItem1 && <Grid ref={compareTableRef} item xs={8} sm={8} md={8} lg={8}>
        <CardContainer
          justifyContent="center"
          flexDirection="row"
          height="600px"
        >
          <Stack
            spacing={4}
            sx={{
              width: "100%",
              borderRadius: "5px",
            }}
          >
            {/* TOTAL RATINGS */}
            <TitleText
              sx={{
                width: "100%",
                textJustify: "center",
              }}
            >
              {compareItem1 && compareItem2
                ? `${compareItem1} VS ${compareItem2}`
                : compareItem1 || compareItem2
                ? compareItem1 || compareItem2
                : "Select Branch to Display Data."}
            </TitleText>
            <TableRow
              Icon={<TrendingUpIcon fontSize="large" />}
              cell1Value="Total Ratings"
              cell2Value={totalRating1}
              cell3Value={totalRating2}
              bgColor={mainColor}
            />
            {/* ROWS */}

            {graphData?.map((cat, idx) => {
              return (
                <>
                  <TableRow
                    Icon={cat.Icon}
                    key={idx}
                    cell1Value={cat.catName as string}
                    cell2Value={cat[compareItem1] as string}
                    cell3Value={cat[compareItem2] as string}
                    onCategoryClickFn={() => {
                      barOnClickFn({ catName: cat.catName });
                    }}
                  />
                  <Divider />
                </>
              );
            })}
          </Stack>
        </CardContainer>
      </Grid>}

      {/* TOP SUGGESTION */}
      <Grid item xs={4} sm={4} md={4} lg={4}>
        {compareItem1 && <CardContainer height="600px" justifyContent="flex-start">
          <TitleText>Top Suggetions ({compareItem1})</TitleText>
          <Stack paddingLeft={4} gap={8} alignItems="flex-start">
            {topSuggestions?.length === 0 && (
              <Typography variant="h5">No Suggestions</Typography>
            )}
            {topSuggestions?.length > 0 && (
              <Stack spacing={2} direction="column">
                <Typography variant="h6">Food</Typography>
                {topSuggestions
                  ?.find((category:ICategorySuggestion) =>
                    category?.categoryName?.toLowerCase()?.includes("food")
                  )
                  ?.suggestions?.map((suggestion:ICategorySuggestion, idx: number) => {
                    return (
                      <Typography key={idx} variant="body1" paddingLeft={4}>
                        {suggestion.suggestionText}
                      </Typography>
                    );
                  })}
                <Typography variant="h6">Experience</Typography>
                {topSuggestions
                  ?.find((category:ICategorySuggestion) =>
                    category?.categoryName
                      ?.toLowerCase()
                      ?.includes("experience")
                  )
                  ?.suggestions?.map((suggestion:ICategorySuggestion, idx: number) => {
                    return (
                      <Typography key={idx} variant="body1" paddingLeft={4}>
                        {suggestion.suggestionText}
                      </Typography>
                    );
                  })}
                <Typography variant="h6">Price</Typography>
                {topSuggestions
                  ?.find((category:ICategorySuggestion) =>
                    category?.categoryName?.toLowerCase()?.includes("price")
                  )
                  ?.suggestions?.map((suggestion:ICategorySuggestion, idx: number) => {
                    return (
                      <Typography key={idx} variant="body1" paddingLeft={4}>
                        {suggestion.suggestionText}
                      </Typography>
                    );
                  })}
                <Typography variant="h6">Service</Typography>
                {topSuggestions
                  ?.find((category:ICategorySuggestion) =>
                    category?.categoryName?.toLowerCase()?.includes("service")
                  )
                  ?.suggestions?.map((suggestion:ICategorySuggestion, idx: number) => {
                    return (
                      <Typography key={idx} variant="body1" paddingLeft={4}>
                        {suggestion.suggestionText}
                      </Typography>
                    );
                  })}
                <Typography variant="h6">Atmosphere</Typography>
                {topSuggestions
                  ?.find((category:ICategorySuggestion) =>
                    category?.categoryName
                      ?.toLowerCase()
                      ?.includes("atmosphere")
                  )
                  ?.suggestions?.map((suggestion:ICategorySuggestion, idx: number) => {
                    return (
                      <Typography key={idx} variant="body1" paddingLeft={4}>
                        {suggestion.suggestionText}
                      </Typography>
                    );
                  })}
              </Stack>
            )}
          </Stack>
        </CardContainer>}
      </Grid>

      { compareItem1 && <Grid item xs={12} sm={12} md={12} lg={12}>
        { <BarChartGraph
          graphData={graphData}
          titleText={
            compareItem1 && compareItem2
              ? `${compareItem1} VS ${compareItem2}`
              : compareItem1 || compareItem2
              ? compareItem1 || compareItem2
              : "Select Branch to Display Data."
          }
          bar1DataKey={compareItem1}
          bar2DataKey={compareItem2}
          bar1Color="green"
          xAxisDataKey="catName"
          graphHeight="600px"
          twoBars={true}
          onClickFn={barOnClickFn}
        />}
      </Grid>}

      {/* CATEGORY SUGGESTION GRAPH */}
      {categorySuggestions?.length > 0 && (
        <Grid ref={categorySuggestionsRef} item xs={12} sm={12} md={12} lg={12}>
          <BarChartGraph
            graphData={
              categorySuggestions as unknown as {
                [key: string]: string | number;
              }[]
            }
            titleText={`${categoryName} Category Suggestions for ${
              categoryName.startsWith("*")
                ? "All Branches"
                : compareItem1 + " Branch"
            }`}
            bar1DataKey="count"
            xAxisDataKey="suggestionText"
            graphHeight="600px"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default AnalyticsSection3;

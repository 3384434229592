import {combineReducers} from "@reduxjs/toolkit";
import authReducer from "./Reducers/authReducer.ts";
// import companyReducer from "./Reducers/companyReducer.ts";
import userReducer from "./Reducers/userReducer.ts";
import ratingPageReducer from "./Reducers/ratingPageReducer.ts";
import dashboardReducer from "./Reducers/dashboardReducer.ts";
import analyticsReducer from "./Reducers/analyticsReducer.ts";
import {apiSlice} from "../features/apiSlice.ts";



const reducers_collection = {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    // company: companyReducer,
    user: userReducer,
    ratingPage: ratingPageReducer,
    dashboard: dashboardReducer,
    analytics: analyticsReducer
}

export default combineReducers(reducers_collection);
import FormFieldRating from "../../../Global/FormFieldRating";
import { Box, Stack, Typography } from "@mui/material";
import {
  CustomerSectoinContainer,
  Divider,
  FormFieldContainer,
  RatingFormContainer,
  Button,
} from "./styledComponents";
import TextArea from "../../../Global/TextArea";

import EvalurateCopyRight from "../../../Global/EvalurateCopyRight";
import { useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";

import { Link, useParams } from "react-router-dom";

import { ICustomerFormField } from "../../../../Shared/Interfaces/ICustomerRatingPage";

import "./styles.css";
import { IBranch } from "../../../../Shared/Interfaces/IBranch.ts";
import { useGetCompanyFormFieldsQuery } from "../../../../features/company/companyApiSlice.ts";
import { handleRTKError } from "../../../../Shared/Functions/RTKErrorHandler.ts";
import { useAddRatingMutation } from "../../../../features/customerRating/customerRatingApiSlice.ts";
import { useDispatch, useSelector } from "react-redux";
import { setFormFields } from "../../../../Redux/Reducers/ratingPageReducer.ts";
import Loader from "../../../Global/Loader";
import { useTranslation } from "react-i18next";

import CustomerDataForm from "./customerDataForm.tsx";
import WheelPage from "./wheelPage.tsx";
import RatingHeader from "./ratingHeader.tsx";
import HomeScreen from "./homeScreen.tsx";
import ThankYouPage from "./thankYouPage.tsx";
import { IRating } from "../../../../Shared/Interfaces/IRating.ts";
import { RootState } from "../../../../Redux/store.ts";

const RatingPage = () => {
  const { t } = useTranslation();

  const { companyID } = useParams();
  // const companyID= "656fae9915ecd7877194ce52"
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [classes, setClasses] = useState(["", "", ""]);
  const [rating, setRating] = useState<IRating | null>(null);
  const [customerDataFilled, setCustomerDataFilled] = useState(false);
  const emailRef = useRef<HTMLInputElement | null>(null);
  const commentRef = useRef<HTMLTextAreaElement | null>(null);
  const fullNameRef = useRef<HTMLInputElement | null>(null);
  const mobileNumberRef = useRef<HTMLInputElement | null>(null);
  const [selectedBranch, setSeletectedBranch] = useState<IBranch | null>(null);
  const formFields = useSelector(
    (state: RootState) => state.ratingPage.ratingPageFormFields
  ) as ICustomerFormField[];

  const { data, isLoading, isSuccess } = useGetCompanyFormFieldsQuery({
    companyID,
  });
  useEffect(() => {
    dispatch(setFormFields(data?.result));
  }, [isSuccess]);

  const [
    addRating,
    {
      isError: submitIsError,
      error: submitError,
      isSuccess: submitIsSuccess,
      isLoading: submitIsLoading,
    },
  ] = useAddRatingMutation();

  useEffect(() => {
    handleRTKError(submitIsError, submitError);
  }, [submitIsError, submitError]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const submitFormHandler = async () => {
    const check = formFields.find(
      (formField: ICustomerFormField) =>
        !formField.ratingProvided || formField.ratingProvided <= 0
    );
    if (check) {
      enqueueSnackbar("You should complete the form before submitting...", {
        variant: "warning",
      });
      return;
    }

    const submission = {
      companyID: companyID,
      // brandID: 'your-brand-id', // TODO: replace with the actual brand ID
      branchID: selectedBranch?._id,
      avgRating:
        formFields.reduce((acc, rate) => acc + rate.ratingProvided, 0) /
        formFields.length,
      ratingData: formFields?.map((formField: ICustomerFormField) => ({
        formFieldID: formField._id,
        formFieldText: formField.name,
        ratingValue: formField.ratingProvided,
        categoryID: formField.categoryRef._id,
        positiveSuggestionsIDs: formField.positiveSuggestions
          ?.filter((suggestion) => suggestion.selected)
          ?.map((suggestion) => suggestion._id),
        negativeSuggestionsIDs: formField.negativeSuggestions
          ?.filter((suggestion) => suggestion.selected)
          ?.map((suggestion) => suggestion._id),
      })),
      createdAt: new Date(),
      comment: commentRef.current?.value,
      customerInfo: {
        name: fullNameRef.current?.value,
        email: emailRef.current?.value,
        phone: mobileNumberRef.current?.value,
      },
      status: true,
    };
    const rateResponse = (await addRating(submission)) as { data: IRating };
    setRating(rateResponse?.data);
    if (
      submission.customerInfo.email &&
      submission.customerInfo.name &&
      submission.customerInfo.phone
    ) {
      setCustomerDataFilled(true);
    }
  };

  useEffect(() => {
    if (submitIsSuccess || submitIsLoading) {
      scrollToTop();
      setClasses(["moveLeftCls2", "moveLeftCls2", "moveLeftCls2"]);
      enqueueSnackbar("Ratings have been submitted, Thank You", {
        variant: "success",
      });
    }
  }, [submitIsSuccess, submitIsLoading]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <Stack direction="column" className="structure">
      {/* HEADER */}
      <Box>
        <RatingHeader />
      </Box>
      {/* BODY */}
      <Box
        sx={{
          overflow: "hidden",
          height: "100%",
        }}
      >
        <Stack
          direction="row"
          alignItems="flex-start"
          gap="20px"
          width="100%"
          className="body"
        >
          {/* {wheelVisible && (
            <Box>
              <WheelPage setWheelVisible={setWheelVisible} />
            </Box>
          )} */}

          <Box className={classes[0]} style={{ height: "100%" }}>
            <HomeScreen
              selectedBranch={selectedBranch}
              setSeletectedBranch={setSeletectedBranch}
              setClasses={setClasses}
            />
          </Box>

          <Box className={classes[1]}>
            {classes[1] !== "moveLeftCls" ? (
              <RatingFormContainer
                sx={{
                  height: "50px",
                }}
              />
            ) : (
              <RatingFormContainer>
                <FormFieldContainer>
                  {isLoading && <Loader />}
                  {formFields?.map(
                    (formField: ICustomerFormField, idx: number) => (
                      <FormFieldRating
                        key={idx}
                        formFieldIdx={idx}
                        counter={`${idx + 1} / ${formFields.length}`}
                        question={
                          currentLanguage == "ar"
                            ? (formField.nameAr as string)
                            : (formField.name as string)
                        }
                        suggestionQuestion={t("helpUsProvideBetterExperience")}
                        positiveSuggestions={formField.positiveSuggestions}
                        negativeSuggestions={formField.negativeSuggestions}
                      />
                    )
                  )}
                  <TextArea ref={commentRef} />
                </FormFieldContainer>
                <Divider />

                <CustomerSectoinContainer
                  dir={currentLanguage == "ar" ? "rtl" : "ltr"}
                >
                  <CustomerDataForm
                    fullNameRef={fullNameRef}
                    mobileNumberRef={mobileNumberRef}
                    emailRef={emailRef}
                  />

                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 400,
                      fontSize: "14px",
                      letterSpacing: "-0.5px",
                      textAlign: "center",
                      lineHeight: "24px",
                      color: "#5c5c5c",
                    }}
                  >
                    {t("byReadingThisStatement")}
                    {/* <br /> */}
                    <Link to={"/terms-and-conditions"}>
                      <span
                        style={{
                          fontWeight: 600,
                          color: "#425D48",
                          textDecoration: "underline",
                          paddingLeft: "5px",
                          cursor: "pointer",
                        }}
                      >
                        {t("termsAndConditions")}
                      </span>
                    </Link>
                  </Typography>

                  <Button onClick={submitFormHandler}>
                    {t("submitAndCrackACookie")}
                  </Button>
                </CustomerSectoinContainer>
              </RatingFormContainer>
            )}
          </Box>

          <Box className={classes[2]}>
            <WheelPage
              rating={rating as IRating}
              customerDataFilled={customerDataFilled}
            />
          </Box>

          <Box className={classes[2]}>
            <ThankYouPage />
          </Box>
        </Stack>
      </Box>

      {/* FOOTER */}
      <Box>
        <EvalurateCopyRight />
      </Box>
    </Stack>
  );
};

export default RatingPage;

import {
  // LineChart,
  // Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";

import CardContainer from "../CardContainer";
import { TitleText } from "../Text/TitleText";
import { ILineChartGraph } from "../../../Shared/Interfaces/ILineChart";
import { Box, IconButton, Menu, MenuItem, Stack } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import { useEffect, useState } from "react";
import { Tag } from "../Tag/styledComponents";
import { useSelector } from "react-redux";
import { IUserInfo } from "../../../Shared/Interfaces/IUserInfo";
import dayjs from "dayjs";
import { RootState } from "../../../Redux/store";
import { CategoricalChartState } from "recharts/types/chart/generateCategoricalChart";
import { useGetTotalBranchesAvgQuery } from "../../../features/analytics/analyticsApiSlice.ts";
import { handleRTKError } from "../../../Shared/Functions/RTKErrorHandler.ts";
import Loader from "../Loader/index.tsx";

const enum FilterOption {
  week = 1,
  month = 2,
  year = 3,
}

const LineChartGraph = ({
  titleText,
  line1DataKey,
  // line1Color = '#425d48',
  // line2DataKey,
  // line2Color,
  graphHeight = "500px",
  titleTextSize = "26px",
  xAxisDataKey,
  yAxisDataKey,
}: ILineChartGraph) => {
  const [selectedFilterOption, setSelectedFilterOption] = useState(1);
  const [fromDate, toDate] = useSelector(
    (state: RootState) => state.analytics.globalDateFilter
  ) as Date[];
  const [lineGraphDate, setLineGraphDate] = useState([new Date(), new Date()]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [tag, setTag] = useState("week");

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const userInfo = useSelector(
    (state: RootState) => state.auth.userInfo
  ) as IUserInfo;

  const {
    data: _graphData,
    isLoading,
    isError,
    error,
  } = useGetTotalBranchesAvgQuery({
    entityID: `${userInfo.branchID || userInfo.brandID || userInfo.companyID}`,
    fromDate: lineGraphDate[0], //.toISOString(),
    toDate: lineGraphDate[1], //.toISOString(),
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    unit: selectedFilterOption,
  });

  const getTotalBranchesAvg = async (_fromDate: Date, _toDate: Date) => {
    setLineGraphDate([_fromDate, _toDate]);
  };

  const selectedOptionHandler = async (option?: FilterOption) => {
    let _fromDate: Date = dayjs(fromDate).startOf("day").toDate();
    const _toDate: Date = dayjs(toDate).endOf("day").toDate();
    let _tagText;

    let diff = 0;

    switch (option) {
      case FilterOption.month:
        setSelectedFilterOption(FilterOption.month);
        diff = dayjs(toDate).add(1, "second").diff(fromDate, "month");
        // if (diff < 1 ){
        //   enqueueSnackbar('Short Range, No Data will be displayed ...', {variant: 'info'})
        //   _setGraphData([]);
        //   setTag('');
        //   return;
        // }
        if (diff >= 1)
          _fromDate = dayjs(_toDate)
            .subtract(1, "month")
            .add(1, "second")
            .toDate();
        _tagText = `month (${dayjs(_fromDate).format("DD/MM/YYYY")} - ${dayjs(
          _toDate
        ).format("DD/MM/YYYY")})`;
        break;
      case FilterOption.year:
        setSelectedFilterOption(FilterOption.year);
        diff = dayjs(toDate).add(1, "second").diff(fromDate, "year");
        // if (diff < 1 ){
        //   enqueueSnackbar('Short Range, No Data will be displayed ...', {variant: 'info'})
        //   _setGraphData([]);
        //   setTag('');
        //   return;
        // }
        if (diff >= 1)
          _fromDate = dayjs(_toDate)
            .subtract(1, "year")
            .add(1, "second")
            .add(1, "month")
            .toDate();
        _tagText = `year (${dayjs(_fromDate).format("DD/MM/YYYY")} - ${dayjs(
          _toDate
        ).format("DD/MM/YYYY")})`;
        break;
      case FilterOption.week:
      default:
        setSelectedFilterOption(FilterOption.week);
        diff = dayjs(_toDate).add(1, "second").diff(_fromDate, "week");
        // if (diff < 1 ){
        //   enqueueSnackbar('Short Range, No Data will be displayed ...', {variant: 'info'})
        //   _setGraphData([]);
        //   setTag('');
        //   return;
        // }
        if (diff >= 1)
          _fromDate = dayjs(_toDate)
            .subtract(1, "week")
            .add(1, "second")
            .toDate();
        _tagText = `week (${dayjs(_fromDate).format("DD/MM/YYYY")} - ${dayjs(
          _toDate
        ).format("DD/MM/YYYY")})`;
        break;
    }
    if (!option) option = FilterOption.week;

    setTag(_tagText);
    getTotalBranchesAvg(_fromDate, _toDate);
    handleMenuClose();
  };

  const handleLineGraphClick = async ({
    activeLabel,
  }: CategoricalChartState) => {
    if (tag.toLowerCase().includes("week")) return;

    const months: { [key: string]: number } = {
      Jan: 0,
      Feb: 1,
      Mar: 2,
      Apr: 3,
      May: 4,
      Jun: 5,
      Jul: 6,
      Aug: 7,
      Sep: 8,
      Oct: 9,
      Nov: 10,
      Dec: 11,
    };

    let _fromDate: Date = fromDate;
    let _toDate: Date = dayjs(_fromDate).add(1, "week").toDate();
    let tagText: string = "week";

    if (tag.toLowerCase().includes("year")) {
      const month = activeLabel;
      const selectedMonth = months[month as string] as number;
      let year = toDate.getFullYear();
      const currentMonth = toDate.getMonth();
      if (selectedMonth > currentMonth) {
        year = dayjs(toDate).subtract(1, "year").toDate().getFullYear();
      }

      setSelectedFilterOption(FilterOption.month);
      _fromDate = new Date(year, selectedMonth, 1);
      _toDate = dayjs(_fromDate).endOf("month").toDate();
      tagText = `${month} ${year}`;
    } else {
      const weekNumber = +(activeLabel as string)?.split(" ")[1] as number;
      setSelectedFilterOption(FilterOption.week);

      if (tag.toLowerCase().includes("month")) {
        const dateParts = tag.split(" ");
        const _from = dateParts[1].slice(1);
        const _to = dateParts[3].slice(0, -1);

        const [day, month, year] = _from.split("/");
        _fromDate = dayjs(new Date(+year, +month - 1, +day))
          .add(weekNumber - 1, "week")
          .startOf("day")
          .toDate();
        if (weekNumber === 4) {
          const [_day, _month, _year] = _to.split("/");
          _toDate = dayjs(new Date(+_year, +_month - 1, +_day))
            .endOf("day")
            .toDate();
        } else {
          _toDate = dayjs(_fromDate).add(6, "day").endOf("day").toDate();
        }
      } else if (
        Object.keys(months).find(
          (month) => month.toLowerCase() === tag.split(" ")[0].toLowerCase()
        )
      ) {
        const [month, year] = tag.split(" ");
        const monthIdx = months[month];

        const date = new Date(+year, monthIdx, 1);

        _fromDate = dayjs(date)
          .add(weekNumber - 1, "week")
          .toDate();
        if (weekNumber === 4)
          _toDate = dayjs(_fromDate).endOf("month").toDate();
        else _toDate = dayjs(_fromDate).add(6, "day").endOf("day").toDate();
      }

      tagText = `week (${dayjs(_fromDate).format("DD/MM/YYYY")} - ${dayjs(
        _toDate
      ).format("DD/MM/YYYY")})`;
    }

    setTag(tagText);
    getTotalBranchesAvg(_fromDate, _toDate);
    handleMenuClose();
  };

  useEffect(() => {
    selectedOptionHandler(FilterOption.week);
  }, [fromDate, toDate]);

  useEffect(() => {
    handleRTKError(isError, error);
  }, [isError, error]);

  if (_graphData?.length < 1)
    return <div></div>
    
  return isLoading ? (
    <Loader />
  ) : (
    <CardContainer height={graphHeight}>
      <Stack direction="row" justifyContent="space-between">
        <TitleText fontSize={titleTextSize}>{titleText}</TitleText>
        <Stack direction="row-reverse">
          <IconButton
            sx={{
              width: "50px",
              height: "50px",
            }}
            onClick={handleButtonClick}
          >
            <TuneIcon />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "50px",
            }}
          >
            <Tag>{tag}</Tag>
          </Box>
        </Stack>

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            sx={{ width: "200px" }}
            onClick={() => selectedOptionHandler(FilterOption.week)}
          >
            Week
          </MenuItem>
          <MenuItem
            sx={{ width: "200px" }}
            onClick={() => selectedOptionHandler(FilterOption.month)}
          >
            Month
          </MenuItem>
          <MenuItem
            sx={{ width: "200px" }}
            onClick={() => selectedOptionHandler(FilterOption.year)}
          >
            Year
          </MenuItem>
        </Menu>
      </Stack>

      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={300}
          data={_graphData?.length > 0 ? _graphData : []}
          margin={{
            top: 5,
            right: 5,
            left: 2,
            bottom: 5,
          }}
          style={{
            cursor: "pointer",
          }}
          onClick={handleLineGraphClick}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.2} />
              {/* <stop offset="5%" stopColor={line1Color} stopOpacity={0.8}/> */}
              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xAxisDataKey} />
          {yAxisDataKey ? <YAxis dataKey={yAxisDataKey} /> : <YAxis />}
          <Tooltip />
          <Legend />
          <Area
            type="monotone"
            dataKey={line1DataKey}
            stroke="#82ca9d"
            fillOpacity={1}
            fill="url(#colorUv)"
          />
          {/* {
            line1DataKey && 
            line2Color && 
            <Line type="monotone" dataKey={line2DataKey} stroke={line2Color} />
          } */}
        </AreaChart>
      </ResponsiveContainer>
    </CardContainer>
  );
};

export default LineChartGraph;

import { createSlice } from "@reduxjs/toolkit";
import { IUserInfo } from "../../Shared/Interfaces/IUserInfo";

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        userInfo: {} as IUserInfo,
    },
    reducers: {
        setUserInfo: (state, action) => {
            const { accessToken, pages, ...decoded } = action.payload;
            state.userInfo = {
                ...decoded,
                accessToken,
                pages,
            };
        },
        unsetUserInfo: (state) => {
            state.userInfo = {} as IUserInfo;
        },
    },
});

export const { setUserInfo, unsetUserInfo } = authSlice.actions;

export default authSlice.reducer;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { CustomDialogSubContainer } from "../../../Global/CustomDialog/styledComponents";
import DefaultForm from "../../../Global/DefaultForm";
import Loader from "../../../Global/Loader";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSnackbar } from "notistack";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { IconButton, TextField } from "@mui/material";
import { DefaultAutocomplete } from "../../../Global/DefaultAutocomplete/styledComponents";
import DefaultInput from "../../../Global/DefaultInput";
import { IFormField } from "../../../../Shared/Interfaces/IFormField";
import { SubTitleText } from "../../../Global/Text/SubtitleText";
import {
  AddEditCompanyFormListsContainer,
  AddEditCompanyFormListsSubContainer,
} from "../CompaniesPage/styledComponents";
import { Delete } from "@mui/icons-material";
import CTAButton from "../../../Global/Buttons/CTAButton";
import { useGetAllCategoriesQuery } from "../../../../features/category/categoryApiSlice";
import { useAddCompanyFormFieldMutation } from "../../../../features/company/companyApiSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Redux/store";
import {ICategorySuggestion} from "../../../../Shared/Interfaces/IAnalytics.ts";
import { UserRoles } from "../../../../Shared/Constants/enums.ts";
import {useEffect} from "react";
import { useUpdateFormFieldMutation } from "../../../../features/formField/formFieldApiSlice.ts";
import { handleRTKError } from "../../../../Shared/Functions/RTKErrorHandler.ts";

const fieldSuggestionSchema = z.object({
  _id: z.string().optional(),
  name: z.string().min(1, "Required"),
  nameAr: z.string().min(1, "Required"),
  //   active: z.boolean().default(false),
});

const addUpdateformFieldSchema = z.object({
  _id: z.string().optional(),
  name: z.string().min(1, "Required"),
  nameAr: z.string().min(1, "Required"),
  categoryRef: z.string(),
  positiveSuggestions: z.array(fieldSuggestionSchema),
  negativeSuggestions: z.array(fieldSuggestionSchema),
});

interface IProp { 
  isEdit: boolean; 
  actionAfterSubmit?: (...params: any) => void; 
  formFieldItem?: IFormField 
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function AddEditFormFieldSideForm({ isEdit, actionAfterSubmit, formFieldItem }: IProp) {
  const { enqueueSnackbar } = useSnackbar();

  const userInfo = useSelector((state: RootState) => state.auth.userInfo);

  const isOwner = userInfo.userRole === UserRoles.OWNER ? true : false;
  const companyID = userInfo.companyID;

  const allowedSuggestionsNumber = 5;

  const {
    register, 
    control, 
    handleSubmit, 
    reset, 
    setValue, 
    getValues,
    formState: { isValid, errors} 
  } = useForm({
    resolver: zodResolver(addUpdateformFieldSchema),
  });

  const { data: categories, isLoading } = useGetAllCategoriesQuery({});

  const {
    fields: positiveSuggestionsFields,
    append: positiveSuggestionsAppend,
    remove: positiveSuggestionsRemove,
  } = useFieldArray({
    control,
    name: "positiveSuggestions",
  });

  const {
    fields: negativeSuggestionsFields,
    append: negativeSuggestionsAppend,
    remove: negativeSuggestionsRemove,
  } = useFieldArray({
    control,
    name: "negativeSuggestions",
  });

  const [
    addCompanyFormField,
    { isLoading: addingFormFieldLoading, isError: addingFormFieldError, isSuccess:addingFormFieldSuccess },
  ] = useAddCompanyFormFieldMutation();

  const [updateFormField, {isError: updateFormFieldIsError, error: updateFormFieldError, isSuccess: updateFormFieldSuccess}] = useUpdateFormFieldMutation();

  function submit(formData: any) {
    const body = {} as IFormField;
    body.name = formData.name;
    body.nameAr = formData.nameAr;
    body.categoryRef = formData.categoryRef;
    body.positiveSuggestions = formData.positiveSuggestions ?? [];
    body.negativeSuggestions = formData.negativeSuggestions ?? [];

    if (isValid){
      if (isEdit) {
        updateFormField({...body, _id: formFieldItem?._id})
      } else {
        addCompanyFormField({ companyID, payload: body, isOwner });
      }
    }
  }

  useEffect(() => {

    handleRTKError(updateFormFieldIsError, updateFormFieldError);
    
    if (updateFormFieldSuccess){
      enqueueSnackbar("Updated Successfully ...", { variant: "success" });
      reset();
      actionAfterSubmit && actionAfterSubmit()
    }
  }, [updateFormFieldIsError, updateFormFieldError, updateFormFieldSuccess]);

  useEffect(() => {
    if (addingFormFieldError) {
      enqueueSnackbar("Error happened while submitting ...", {
        variant: "error",
      });
    } else if (addingFormFieldSuccess){
      enqueueSnackbar("Added Successfully ...", { variant: "success" });
      reset();
      actionAfterSubmit && actionAfterSubmit()
    }
  }, [addingFormFieldError, addingFormFieldSuccess]);


  function setError(fieldName: string, error: string) {
    return fieldName + " is " + error;
  }

    useEffect(() => {
      if (formFieldItem) {
      setValue("_id", formFieldItem._id);
      setValue("name", formFieldItem.name);
      setValue("nameAr", formFieldItem.nameAr);
      setValue("categoryRef", formFieldItem.categoryRef._id?.toString());

      formFieldItem.positiveSuggestions?.forEach((suggestion) => {
        if (
          !getValues()?.positiveSuggestions?.find(
            (sug: any) => sug._id === suggestion._id
          )
        ) {
          positiveSuggestionsAppend({
            _id: suggestion._id,
            name: suggestion.name,
            nameAr: suggestion.nameAr,
          });
        }
      });

      formFieldItem.negativeSuggestions?.forEach((suggestion) => {
        if (
          !getValues()?.negativeSuggestions?.find(
            (sug: any) => sug._id === suggestion._id
          )
        ) {
          negativeSuggestionsAppend({
            _id: suggestion._id,
            name: suggestion.name,
            nameAr: suggestion.nameAr,
            // active: suggestion.active ?? true,
          });
        }
      });
      }
    }, [formFieldItem]);

  return (
    <CustomDialogSubContainer>
      {(isLoading || addingFormFieldLoading) && <Loader/>}
        <DefaultForm onSubmit={handleSubmit(submit)}>
          <DefaultInput
            label={"Name"}
            register={register("name")}
            error={errors.name?.message ? true : false}
            errorText={
              errors.name?.message
                ? setError("Form Field Name", errors.name?.message.toString())
                : ""
            }
          />
          <DefaultInput label={"NameAr"} register={register("nameAr")}
                        error={errors.nameAr?.message ? true : false}/>
          <Controller
            control={control}
            name="categoryRef"
            render={({ field }) => (
              <DefaultAutocomplete
                disablePortal
                id="combo-box-demo"
                options={categories}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                getOptionLabel={(category: any) => category.name}
                value={
                  categories?.find(
                    (category:ICategorySuggestion) => category?._id === field.value
                  ) || null
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(_event: any, newValue: any) => {
                  field.onChange(newValue?._id ?? null);
                }}
                renderInput={(params) => (
                  <TextField
                    error={errors.category?.message ? true : false}
                    helperText={
                      errors.category?.message
                        ? setError(
                            "Category",
                            errors.category?.message.toString()
                          )
                        : ""
                    }
                    {...params}
                    label="Category"
                  />
                )}
              />
            )}
          ></Controller>
          {/* Area For Adding Positive Suggestion */}
          <SubTitleText>Positive Suggestions</SubTitleText>
          {positiveSuggestionsFields.map((_suggestion, index) => {
            return (
              <AddEditCompanyFormListsContainer key={index}>
                <AddEditCompanyFormListsSubContainer
                  style={{ width: isEdit ? "calc(100% - 20px)" : "" }}
                >
                  <DefaultInput
                    label={"Name"}
                    register={register(
                      "positiveSuggestions." + index + ".name"
                    )}
                  />
                  <DefaultInput
                    label={"NameAr"}
                    register={register(
                      "positiveSuggestions." + index + ".nameAr"
                    )}
                  />
                </AddEditCompanyFormListsSubContainer>
                {!isEdit && (
                  <IconButton
                    onClick={() => {
                      positiveSuggestionsRemove(index);
                    }}
                  >
                    <Delete color="error" />
                  </IconButton>
                )}
              </AddEditCompanyFormListsContainer>
            );
          })}
          <CTAButton
            fullWidthCheck={false}
            action={() => {
              if (
                positiveSuggestionsFields.length < allowedSuggestionsNumber
              ) {
                positiveSuggestionsAppend({
                  // _id: "",
                  name: "New Positive Suggestion",
                  nameAr: "",
                });
              } else {
                enqueueSnackbar("Can't add more suggestions", {
                  variant: "warning",
                });
              }
            }}
            buttonText="Add New Positive Suggestion"
          />
          {/* Area For Adding Positive Suggestion */}
          {/* Area For Adding Negative Suggestion */}
          <SubTitleText>Negative Suggestions</SubTitleText>
          {negativeSuggestionsFields.map((_suggestion, index) => {
            return (
              <AddEditCompanyFormListsContainer key={index}>
                <AddEditCompanyFormListsSubContainer
                  style={{ width: isEdit ? "calc(100% - 20px)" : "" }}
                >
                  <DefaultInput
                    label={"Name"}
                    register={register(
                      "negativeSuggestions." + index + ".name"
                    )}
                  />
                  <DefaultInput
                    label={"NameAr"}
                    register={register(
                      "negativeSuggestions." + index + ".nameAr"
                    )}
                  />
                </AddEditCompanyFormListsSubContainer>
                {!isEdit && (
                  <IconButton
                    onClick={() => {
                      negativeSuggestionsRemove(index);
                    }}
                  >
                    <Delete color="error" />
                  </IconButton>
                )}
              </AddEditCompanyFormListsContainer>
            );
          })}
          <CTAButton
            fullWidthCheck={false}
            action={() => {
              if (
                negativeSuggestionsFields.length < allowedSuggestionsNumber
              ) {
                negativeSuggestionsAppend({
                  // _id: "",
                  name: "New Negative Suggestion",
                  nameAr: "",
                });
              } else {
                enqueueSnackbar("Can't add more suggestions", {
                  variant: "warning",
                });
              }
            }}
            buttonText="Add New Negative Suggestion"
          />
          {/* Area For Adding Negative Suggestion */}
          <CTAButton
            buttonText="Submit"
            buttonType="submit"
            action={() => {
              console.log("errors", errors);
            }}
            fullWidthCheck
          />
        </DefaultForm>
    </CustomDialogSubContainer>
  );
}

export default AddEditFormFieldSideForm;

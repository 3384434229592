import { useEffect } from 'react'
import { TitleText } from '../../../Global/Text/TitleText';
import Loader from '../../../Global/Loader';
import DefaultForm from '../../../Global/DefaultForm';
import CTAButton from '../../../Global/Buttons/CTAButton';
import DefaultInput from '../../../Global/DefaultInput';
import { ForgotPasswordBoxContainer } from './styledComponents';
import './styles.css';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useChangePasswordMutation, useForgotPasswordMutation } from '../../../../features/auth/authApiSlice';
import { handleRTKError } from '../../../../Shared/Functions/RTKErrorHandler';
import { enqueueSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';

const forgotPasswordFormSchema = z.object({
  email: z.string().email('invalid email').optional(),
  password: z.string().optional(),
  confirmPassword: z.string().optional(),
});

const ForgotPasswordPage = () => {

  const { identifier, token } = useParams();
  
  const navigate = useNavigate();

  const { register, handleSubmit} = useForm({
    resolver: zodResolver(forgotPasswordFormSchema),
  });

  const [forgotPassword, { isLoading, isError, error, isSuccess }] = useForgotPasswordMutation();
  const [changePassword, { 
    isLoading: changePasswordIsLoading, 
    isError: changePasswordIsError, 
    error: changePasswordError, 
    isSuccess: changePasswordIsSuccess 
  }] = useChangePasswordMutation();

  const handleForgotPasswordClick = (formData: any) => {
    
    console.log("THE DATA : ", formData);
    if (formData.password !== formData.confirmPassword) {
      enqueueSnackbar("Passwords doesn't match ...", {variant: 'success'});
      return;
    }
    if (token) {
      changePassword({newPassword: formData.password, identifier, token})
    } else {
      forgotPassword({ email: formData.email });
    }
  }

  useEffect(() => {
    handleRTKError(isError, error);
  }, [isError, error])

  useEffect(() => {
    handleRTKError(changePasswordIsError, changePasswordError);
  }, [changePasswordIsError, changePasswordError]);

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Check your email for the reset URL', { variant: 'success' })
    }
  }, [isSuccess]);

  useEffect(() => {
    if (changePasswordIsSuccess) {
      enqueueSnackbar('Password has been changed', {variant: 'success'});
      navigate("/login");
    }
  }, [changePasswordIsSuccess])

  return (
    <div className="forgotPasswordPageContainer">
        { (isLoading || changePasswordIsLoading) && <Loader />}
        <ForgotPasswordBoxContainer>
          <div className="forgotPasswordHeaderContainer">
              <TitleText>Forgot Password</TitleText>
          </div>
          <DefaultForm onSubmit={handleSubmit(handleForgotPasswordClick)}>
            {!token && <DefaultInput
              label="Email"
              register={register("email")}
            />}

            { token && <>
              <DefaultInput
                label="Password"
                register={register("password")}
                passwordCheck={true}
              />
              <DefaultInput
                label="Confirm Password"
                register={register("confirmPassword")}
                passwordCheck={true}
              />
            </>}
              
            <CTAButton
                buttonType="submit"
                buttonText="Submit"
                fullWidthCheck={true}
                fontSize="18px" 
            />
          </DefaultForm>
        </ForgotPasswordBoxContainer>
    </div>
  );
}

export default ForgotPasswordPage
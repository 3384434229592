import { apiSlice } from '../apiSlice';

export const formFieldsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        addDefaultFormField: builder.mutation({
            query: (payload) => ({
                url: '/form-fields',
                method: 'POST',
                body: payload,
            }),
        }),
        getAllDefaultFormFields: builder.query({
            query: () => '/form-fields',
        }),
        updateFormField: builder.mutation({
            query: (payload) => ({
                url: 'form-fields',
                method: 'PUT',
                body: payload,
            })
        }),
        deleteFormField: builder.mutation({
            query: (formFieldID) => ({
                url: `form-fields/${formFieldID}`,
                method: 'DELETE'
            })
        }),
    }),
});

export const {
    useAddDefaultFormFieldMutation,
    useGetAllDefaultFormFieldsQuery,
    useUpdateFormFieldMutation,
    useDeleteFormFieldMutation,
} = formFieldsApiSlice;

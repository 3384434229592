import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const DashboardDataWhiteContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(3),
    gap: theme.spacing(2),
    borderRadius: theme.spacing(4),
    boxSizing: "border-box",
    backgroundColor: theme.palette.common.white,
    boxShadow: "0px 4px 20px rgba(153, 153, 153, 0.4)",
    width: "100%",
    height: "400px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
}));

export const DashboardDataColoredContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(8),
    borderRadius: theme.spacing(4),
    boxSizing: "border-box",
    backgroundColor: theme.palette.primary.main,
    boxShadow: "0px 4px 20px rgba(153, 153, 153, 0.4)",
    width: "100%",
    height: "150px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.palette.common.white,
}));

export const DashboardDataColoredLogoContiainer = styled("div")(({ theme }) => ({
    border: "3px solid white",
    borderRadius: "50%",
    padding: theme.spacing(4),
    boxSizing: "border-box",
}));

export const DashboardDataColoredDataContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
}));

export const DashboardDataColoredDataHeaderText = styled(Typography)(({ theme }) => ({
    fontSize: "14px",
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
}));

export const DashboardDataColoredDataValueText = styled(Typography)(({ theme }) => ({
    fontSize: "50px",
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
}));

export const DashboardDataWhitePieChartContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(3),
    borderRadius: theme.spacing(4),
    boxSizing: "border-box",
    backgroundColor: theme.palette.common.white,
    boxShadow: "0px 4px 20px rgba(153, 153, 153, 0.4)",
    width: "100%",
    minHeight: "400px",
}));

export const DashboardSuggestionsContainer = styled("div")(() => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "column"
}));

export const DashboardSingleSuggestionContainer = styled("div")(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: theme.spacing(3),
    boxSizing: "border-box"
}));
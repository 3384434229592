/* eslint-disable @typescript-eslint/no-explicit-any */
import jwtDecode from "jwt-decode";
import { unsetUserInfo } from "../../Redux/Reducers/authReducer";

export function setAccessToken(token: string) {
    localStorage.setItem("accessToken", token);
}

export function getAccessToken() {
    return localStorage.getItem("accessToken");
}

export function clearAccessToken(dispatch: any) {
    localStorage.removeItem("accessToken");
    dispatch(unsetUserInfo());
}

interface ITokenPayload {
    exp: number;
    iat: number;
    userID: string;
}

export function validateAccessToken(dispatch: any) {
    try {
        const token = getAccessToken();
        const payload = jwtDecode(token as string) as ITokenPayload;
        if (payload?.exp < Date.now() / 1000) {
            clearAccessToken(dispatch);
            return false;
        } else {
            return true;
        }
    } catch (error) {
        return false;
    }
}

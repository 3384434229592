import { Navigate, Route } from "react-router-dom";
import DashboardPage from "../../Components/Pages/AdminPages/DashboardPage";
import AnalyticsPage from "../../Components/Pages/AdminPages/AnalyticsPage";
import CompAnalyticsPage from "../../Components/Pages/AdminPages/CompAnalyticsPage";
import SuperCompaniesPage from "../../Components/Pages/AdminPages/SuperCompaniesPage";
import FormsPage from "../../Components/Pages/AdminPages/FormsPage";
import UsersPage from "../../Components/Pages/AdminPages/UsersPage";
import CompaniesPage from "../../Components/Pages/AdminPages/CompaniesPage";
import RatingsPage from "../../Components/Pages/AdminPages/RatingsPage";
import BranchesPage from "../../Components/Pages/AdminPages/BranchesPage";
import ContactsPage from "../../Components/Pages/AdminPages/ContactsPage";
import BrandsPage from "../../Components/Pages/AdminPages/BrandsPage/BrandsPage.tsx";
import CategoryPage from "../../Components/Pages/AdminPages/CategoriesPage/index.tsx";
import PackagePage from "../../Components/Pages/AdminPages/PackagesPage/index.tsx";

export const AdminRoutes = [
    <Route key="adminIndex" index element={<Navigate to={"dashboard"} />} />,
    <Route key="dashboard" path="dashboard" element={<DashboardPage />} />,
    <Route key="analytics" path="analytics" element={<AnalyticsPage />} />,
    <Route key="comparative" path="comparative" element={<CompAnalyticsPage />} />,
    <Route key="supercompanies" path="supercompanies" element={<SuperCompaniesPage />} />,
    <Route key="companies" path="companies" element={<CompaniesPage />} />,
    <Route key="ratings" path="ratings" element={<RatingsPage />} />,
    <Route key="contacts" path="contacts" element={<ContactsPage />} />,
    <Route key="formfields" path="formfields" element={<FormsPage />} />,
    <Route key="formfieldswithid" path="formfields/:companyID" element={<FormsPage />} />,
    <Route key="branches" path="branches" element={<BranchesPage />} />,
    <Route key="companyBranches" path="branches/:companyID" element={<BranchesPage />} />,
    <Route key="brands" path="brands/" element={<BrandsPage />} />,
    <Route key="users" path="users" element={<UsersPage />} />,
    <Route key="categories" path="categories" element={<CategoryPage />} />,
    <Route key="packages" path="packages" element={<PackagePage />} />,
];

import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

export const PageContainer = styled(Stack)(({ theme }) => ({
    width: "100%",
    minHeight: "100vh",
    overflowY: "auto",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingBottom: theme.spacing(20),
    backgroundColor: theme.palette.primary.light,
}));

export const EvalurateHeader = styled("div")(({ theme }) => ({
    boxSizing: "border-box",
    width: "100%",
    minHeight: "80px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
}));

export const CompanyHeader = styled("div")(({ theme }) => ({
    boxSizing: "border-box",
    width: "100%",
    minHeight: "50px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: theme.palette.common.white,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
}));

export const RatingsAreaContainer = styled(Stack)(({ theme }) => ({
    minWidth: "50%",
    backgroundColor: theme.palette.common.white,
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    boxShadow: "0px 0px 4px 1px grey",
    justifyContent: "flex-start",
    alignItems: "center",
    borderRadius: "16px",
    gap: theme.spacing(5)
}));

export const SingleRatingContainer = styled("div")(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(3)
}))

export const StarsContainer = styled("div")(({theme}) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(3)
}))

export const SuggestionsContainer = styled("div")(({theme}) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    maxWidth: "60vw",
    gap: theme.spacing(3),

    [theme.breakpoints.down("md")]: {
        maxWidth: "90vw",
    },
}))

export const SingleSelectedSuggestionsContainer = styled("div")(({theme}) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderRadius: theme.spacing(4),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderStyle: "solid",
    borderWidth: "thin",
    borderColor: theme.palette.primary.main,
    cursor: "pointer",
    fontFamily: theme.typography.fontFamily
}))

export const SingleUnselectedSuggestionsContainer = styled("div")(({theme}) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderRadius: theme.spacing(4),
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    borderStyle: "solid",
    borderWidth: "thin",
    borderColor: theme.palette.primary.main,
    cursor: "pointer",
    fontFamily: theme.typography.fontFamily,

    "&.negative": {
        backgroundColor: theme.palette.error.light,
        color: "white",
        border: "none",
    },
}))

export const CommentsContainer = styled("div")(({theme}) => ({
    width: "95%",
    boxSizing: "border-box",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderRadius: theme.spacing(4),
    backgroundColor: theme.palette.grey[200],
    borderStyle: "solid",
    borderWidth: "thin",
    borderColor: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily
}))

export const CommentsTextArea = styled("textarea")(({theme}) => ({
    minWidth: "100%",
    boxSizing: "border-box",
    color: theme.palette.secondary.dark,
    fontFamily: theme.typography.fontFamily,
    borderRadius: theme.spacing(4),
    border: "none",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    overflow: "auto",
    outline: "none",
    boxShadow: "none",
    resize: "none",
    fontSize: "19px",
    caretColor: theme.palette.primary.main
}))
import { Dispatch, SetStateAction, createContext, useContext, useState } from "react";

interface LoaderContextType {
    loading: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
}
const LoadingContext = createContext<LoaderContextType>({
    loading: true,
    setLoading: () => {},
});

export function LoadingProvider({ children }: { children: React.ReactNode }) {
    const [loading, setLoading] = useState(true);
    const value = { loading, setLoading };
    return <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>;
}

export function useLoading() {
    const context = useContext(LoadingContext);
    if (!context) {
        throw new Error("useLoading must be used within LoadingProvider");
    }
    return context;
}

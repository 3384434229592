import { Route, createBrowserRouter, createRoutesFromChildren } from "react-router-dom";
import LoginPage from "../../Components/Pages/LoginSignupPages/loginPage";
import RootLayout from "../../Components/Global/RootLayout";
import Protected from "./Protected";
import { AdminRoutes } from "./AdminRoutes";
// import CustomerRatingPage from "../../Components/Pages/CustomerPages/CustomerRatingPage";
import SignupPage from "../../Components/Pages/LoginSignupPages/SignupPage";
import RatingPage from "../../Components/Pages/CustomerPages/RatingPage";
import TermsAndConditions from "../../Components/Pages/CustomerPages/TermsAndConditions/TermsAndConditions.tsx";
import ForgotPasswordPage from "../../Components/Pages/LoginSignupPages/forgotPasswordPage/index.tsx";

const RootRouter = createBrowserRouter(
    createRoutesFromChildren(
        <Route path="/" element={<RootLayout />}>
            <Route path="/admin" element={<Protected />}>
                {AdminRoutes.map((item) => item)}
            </Route>
            <Route path="/login" element={<LoginPage />}></Route>
            <Route path="/signup" element={<SignupPage />}></Route>
            <Route path="/forgot-password/:identifier?/:token?" element={<ForgotPasswordPage />}></Route> 
            {/* <Route path="/rating/:companyID" element={<CustomerRatingPage />}></Route> */}
            <Route path="/rating/:companyID" element={<RatingPage />}></Route>
            <Route path="/terms-and-conditions" element={<TermsAndConditions />}></Route>
        </Route>
    )
);

export default RootRouter;

import { apiSlice } from "../apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/login",
        method: "POST",
        body: {
          email: credentials.email,
          password: credentials.password,
        },
      }),
    }),
    sendOtp: builder.mutation({
      query: (email) => ({
        url: "/users/send-otp",
        method: "POST",
        body: {
          email,
        },
      }),
    }),
    verifyOtp: builder.mutation({
      query: ({email, otp}) => ({
        url: "/users/verify-otp",
        method: "POST",
        body: {
          email,
          otp,
        },
      }),
    }),
    signup: builder.mutation({
      query: (body) => ({
        url: "/users/signup",
        method: "POST",
        body: {
          firstName: body.firstName,
          lastName: body.lastName,
          email: body.email,
          password: body.password,
        },
      }),
    }),
    forgotPassword: builder.mutation({
      query: (payload) => ({
        url: "/forgot-password",
        method: "POST",
        body: payload,
      })
    }),
    changePassword: builder.mutation({
      query: (payload) => ({
        url: "/change-password",
        method: "POST",
        body: payload,
      })
    }),
  })
});
export const { useLoginMutation,
  useSignupMutation,
  useVerifyOtpMutation,
  useSendOtpMutation,
  useForgotPasswordMutation,
  useChangePasswordMutation,
} = authApiSlice;

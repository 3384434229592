import {CustomDialogSubContainer} from "../../../Global/CustomDialog/styledComponents";
import DefaultForm from "../../../Global/DefaultForm";
import DefaultInput from "../../../Global/DefaultInput";
import {z} from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import {userTypes} from "../../../../Shared/Constants/enums";
import {useSnackbar} from "notistack";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import CTAButton from "../../../Global/Buttons/CTAButton";
import {IAddUser} from "../../../../Shared/Interfaces/IUser";
import {MenuItem} from "@mui/material";
import {useSelector} from "react-redux";
import {RootState} from "../../../../Redux/store";
import {
    useGetAllCompaniesQuery,
    useGetCompanyBrandsQuery
} from "../../../../features/company/companyApiSlice.ts";
import {handleRTKError} from "../../../../Shared/Functions/RTKErrorHandler.ts";
import {useAddUserMutation} from "../../../../features/user/userApiSlice.ts";
import {IUserInfo} from "../../../../Shared/Interfaces/IUserInfo.ts";
import {useGetCompanyBranchesQuery} from "../../../../features/branch/branchApiSlice.ts";
import {IBranch} from "../../../../Shared/Interfaces/IBranch.ts";
import {IBrand} from "../../../../Shared/Interfaces/IBrand.ts";
import Loader from "../../../Global/Loader";
import {ICompanyFullInfo} from "../../../../Shared/Interfaces/ICompany.ts";

const addUpdateUserSchema = z.object({
    firstName: z.string().min(1, "Required"),
    lastName: z.string().min(1, "Required"),
    email: z.string().min(1, "Required").email("Invalid"),
    userType: z.string().min(1, "Required"),
    superCompanyID: z.string().optional(),
    companyID: z.string().optional(),
    brandID: z.string().optional(),
    branchID: z.string().optional(),
});

type FormType = z.infer<typeof addUpdateUserSchema>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function AddEditUserSideForm({existingUsers, handleClose = () => {}}:{ existingUsers: number, handleClose?: (_event?: any, reason?: any) => void }) {
    const {enqueueSnackbar} = useSnackbar();
    const [addedUserType, setAddedUserType] = useState<string | null>(null);
    const userInfo = useSelector((state: RootState) => state.auth.userInfo) as IUserInfo;
    const [addUser, {
        isLoading: isAddUserLoading,
        isError: isAddUserError, error: addUserError, isSuccess: isAddUserSuccess
    },
    ] = useAddUserMutation();

    useEffect(() => {
        handleRTKError(isAddUserError, addUserError);
    }, [isAddUserError, addUserError]);

    const canAddUser = async () => {
        if (userInfo.userRole === "owner") {
            return true
        }
        let maxUsers = userInfo.meta.package.configurations.numberOfUsers
        return maxUsers > existingUsers;
    }

    const {register, handleSubmit, formState:{errors, isValid}, reset} = useForm<FormType>({
        resolver: zodResolver(addUpdateUserSchema),
    });

    async function handleAddEditCUser(formData: FormType) {
        let body = {} as IAddUser;
        if (userInfo.userRole === "owner") {
            if (addedUserType === 'company') {
                body.companyID = formData.companyID;
            }
            if (addedUserType === 'brand' || addedUserType === 'branch') {
                body.brandID = formData?.brandID;
                body.companyID = formData.companyID;
            } else if (addedUserType === 'branch') {
                body.branchID = formData?.branchID;
            }
        } else {
            if (addedUserType === 'brand' || addedUserType === 'branch') {
                body.companyID = userInfo?.companyID;
                body.brandID = formData?.brandID;
            } else if (addedUserType === 'branch') {
                body.branchID = formData?.branchID;
            }
            if (addedUserType !== 'owner' && !(await canAddUser())) {
                body = {} as IAddUser;
                enqueueSnackbar("Can't add user, upgrade your bundle to add more users", {variant: 'info'});
                return;
            }
        }

        body.active = false;
        body.email = formData.email;
        body.firstName = formData.firstName;
        body.lastName = formData.lastName;
        body.userRole = formData.userType;
        if (isValid){
            addUser(body)
        }
    }

    useEffect(() => {
        if (isAddUserSuccess) {
            enqueueSnackbar("Added Successfully ...", {variant: "success"});
            reset();
            handleClose();
        }
    }, [isAddUserSuccess]);
    function setError(name: string, message: string) {
        return name + " is " + message;
    }

    const [companyIDState, setCompanyIDState] = useState(userInfo.companyID)
    const [brandIDState, setBrandIDState] = useState(null)
    const {data: companies} = useGetAllCompaniesQuery("")
    const {data: brands, refetch: refetchBrands} = useGetCompanyBrandsQuery(companyIDState)
    const {data: branches, refetch: refetchBranches} = useGetCompanyBranchesQuery({companyID: companyIDState, brandID: brandIDState })
    useEffect(() => {
        if (userInfo.userRole === "owner") {
            if (addedUserType === 'brand') {
                refetchBrands()
            } else if (addedUserType === 'branch') {
                refetchBranches()
            }
        }
    }, [companyIDState]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleUserTypeChange = (data: any) => {
        const userType = data?.target?.value;

        switch (userType) {
            case 'company':
                setAddedUserType(userType);
                break;
            case 'brand':
                setAddedUserType(userType);
                break;
            case 'branch':
                setAddedUserType(userType);
                break;
            default:
                setAddedUserType(null);
                break;
        }
    }

    return (
        <CustomDialogSubContainer>
            {isAddUserLoading && <Loader/>}
            <DefaultForm onSubmit={handleSubmit(handleAddEditCUser)}>
                <DefaultInput
                    label={"First Name"}
                    register={register("firstName")}
                    error={errors.firstName?.message ? true : false}
                    errorText={errors.firstName?.message ? setError("First Name", errors.firstName?.message.toString()) : ""}
                />
                <DefaultInput
                    label={"Last Name"}
                    register={register("lastName")}
                    error={errors.lastName?.message ? true : false}
                    errorText={errors.lastName?.message ? setError("Last Name", errors.lastName?.message.toString()) : ""}
                />
                <DefaultInput
                    label={"Email"}
                    register={register("email")}
                    error={errors.email?.message ? true : false}
                    errorText={errors.email?.message ? setError("Email", errors.email?.message.toString()) : ""}
                />
                <DefaultInput
                    label="User Type"
                    register={register("userType")}
                    select={true}
                    onChange={handleUserTypeChange}
                    error={errors.userType?.message ? true : false}
                    errorText={errors.userType?.message ? setError("User Type", errors.userType?.message.toString()) : "+++"}
                >
                    {userInfo.userRole === userTypes.ownerType &&
                        <MenuItem value={userTypes.ownerType}>Owner</MenuItem>}
                    {/* <MenuItem value={userTypes.superCompanyAdminType}>Super Company Admin</MenuItem> */}
                    {userInfo.userRole === userTypes.ownerType &&
                        <MenuItem value={userTypes.companyAdminType}>Company Admin</MenuItem>}
                    <MenuItem value={userTypes.brandAdminType}>Brand Admin</MenuItem>
                    <MenuItem value={userTypes.branchAdminType}>Branch Admin</MenuItem>
                </DefaultInput>
                {(userInfo.userRole === "owner" && (addedUserType === "branch" || addedUserType === "brand")) &&
                    <DefaultInput
                        label={`Select Company`}
                        register={register("companyID")}
                        select={true}
                        onChange={(e) => setCompanyIDState(e.target.value)}
                    >
                        {companies?.result?.map((company: ICompanyFullInfo, idx: number) => {
                            return (
                                <MenuItem key={idx} value={company._id}>{company.name}</MenuItem>
                            )
                        })
                        }</DefaultInput>}
                {(userInfo.userRole === "owner" && addedUserType === "branch") &&
                    <DefaultInput
                        label={`Select Brand`}
                        register={register("brandID")}
                        select={true}
                        onChange={(e) => setBrandIDState(e.target.value)}
                    >
                        {brands?.map((brand: IBrand, idx: number) => {
                            return (
                                <MenuItem key={idx} value={brand._id}>{brand.name}</MenuItem>
                            )
                        })
                        }
                    </DefaultInput>}
                {addedUserType && <DefaultInput
                    label={`Select ${addedUserType}`}
                    register={register(addedUserType === "company" ? "companyID" : addedUserType === "brand" ? "brandID" : addedUserType === "branch" ? "branchID" : "superCompanyID")}
                    select={true}
                >
                    {addedUserType === 'company' && companies &&
                        companies?.result?.map((company: ICompanyFullInfo, idx: number) => {
                            return (
                                <MenuItem key={idx} value={company._id}>{company.name}</MenuItem>
                            )
                        })
                    }
                    {addedUserType === 'brand' && brands &&
                        brands?.map((brand: IBrand, idx: number) => {
                            return (
                                // {/* <MenuItem disabled>{company.companyName}</MenuItem> */}
                                <MenuItem key={idx} value={brand._id}>{brand.name}</MenuItem>
                            )
                        })
                    }
                    {addedUserType === 'branch' && branches &&
                        branches?.result?.map((branch: IBranch, idx: number) => {
                            return (
                                <MenuItem key={idx} value={branch._id}>{branch.name}</MenuItem>
                            )
                        })
                    }
                </DefaultInput>}


                <CTAButton
                    buttonText="Submit"
                    buttonType="submit"
                    fullWidthCheck
                />
            </DefaultForm>
        </CustomDialogSubContainer>
    );
}

export default AddEditUserSideForm;

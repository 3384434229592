import { apiSlice } from "../apiSlice";

export const analyticsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCategorySuggestions: builder.query({
      query: ({ companyID, branchID, categoryID, fromDate, toDate }) =>
        `/analytics/category-suggestions?companyID=${companyID}&branchID=${branchID}&categoryID=${categoryID}&fromDate=${fromDate}&toDate=${toDate}`,
    }),
    getBranchesInfo: builder.query({
      query: ({ companyID, fromDate, toDate }) =>
        `/analytics/branches-info?companyID=${companyID}&fromDate=${fromDate}&toDate=${toDate}`,
    }),
    getBranchTopSuggestions: builder.query({
      query: ({ companyID, branchID, suggestionType, fromDate, toDate }) =>
        `/analytics/branch-top-suggestions?companyID=${companyID}&branchID=${branchID}&suggestionType=${suggestionType}&fromDate=${fromDate}&toDate=${toDate}`,
    }),
    getTotalBranchesAvg: builder.query({
      query: ({ entityID, fromDate, toDate, timeZone, unit }) =>
        `/analytics/total-branches-avg?entityID=${entityID}&fromDate=${fromDate}&toDate=${toDate}&timeZone=${timeZone}&unit=${unit}`,
    }),
  }),
});

export const {
  useGetCategorySuggestionsQuery,
  useGetBranchesInfoQuery,
  useGetBranchTopSuggestionsQuery,
  useGetTotalBranchesAvgQuery,
} = analyticsApiSlice;

import { apiSlice } from '../apiSlice';

export const branchesApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        addCompanyBranch: builder.mutation({
            query: ({ companyID, brandID, payload }) => ({
                url: `/companies/${companyID}/brands/${brandID}/branches`,
                method: "POST",
                body: payload,
            }),
        }),
        getAllBranches: builder.query({
            query: () => '/branches',
        }),
        getCompanyBranches: builder.query({
            query: ({companyID, brandID, queryParams}) => {
                if (brandID){
                    return `/companies/${companyID}/brands/${brandID}/branches?${queryParams}`
                }else {
                    return `/companies/${companyID}/branches?${queryParams}`
                }
            },
        }),
        activateDeactivateBranch: builder.mutation({
            query: ({ id, isActive }) => ({
                url: `/branches/${id}/active/${isActive}`,
                method: 'PATCH',
            }),
        }),
        updateBranch: builder.mutation({
            query: ({ companyID, brandID, payload }) => ({
                url: `/companies/${companyID}/brands/${brandID}/branches`,
                method: 'PUT',
                body: payload,
            })
        })
    }),
});

export const {
    useAddCompanyBranchMutation,
    useGetAllBranchesQuery,
    useGetCompanyBranchesQuery,
    useActivateDeactivateBranchMutation,
    useUpdateBranchMutation,
} = branchesApiSlice;

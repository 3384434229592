import { staticTextAR, staticTextEN } from "../Constants/staticText";

export function setTranslatedText(lang: string, stringKeys: string[]) {
    const translatedStrings: string[] = [];
    if (lang === "ar") {
        stringKeys.forEach((stringKey) => {
            const i = Object.keys(staticTextAR).indexOf(stringKey);
            if (i >= 0) {
                translatedStrings.push(Object.values(staticTextAR)[i]);
            }
        });
    } else {
        stringKeys.forEach((stringKey) => {
            const i = Object.keys(staticTextEN).indexOf(stringKey);
            if (i >= 0) {
                translatedStrings.push(Object.values(staticTextEN)[i]);
            }
        });
    }
    const translatedObject = {};
    stringKeys.forEach((stringKey, index) => {
        Object.defineProperty(translatedObject, stringKey, {
            value: translatedStrings[index],
            writable: true,
        });
    });
    return translatedObject;
}

import { Stack, Typography } from "@mui/material";
import { LegacyRef, forwardRef } from "react";
import { useTranslation } from "react-i18next";

const TextArea = forwardRef((_props, ref) => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    return (
        <Stack width="100%" gap="4px" dir={currentLanguage=="ar"?"rtl":"ltr"}>
            <Typography
                sx={{
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "24px",
                    letterSpacing: "-0.5px",
                    textTransform: "capitalize",
                }}
            >
                {t("otherRemarksAboutThePlace")}
                <Typography component="span" color="#AEAEAE" sx={{ marginLeft: "5px" }}>
                    {t("optional")}
                </Typography>
            </Typography>
            <textarea
                ref={ref as LegacyRef<HTMLTextAreaElement>}
                placeholder={t('anythingElseYouLikeToMention')}
                style={{
                    boxSizing: "border-box",
                    border: "1px solid #E6E6E6",
                    borderRadius: "16px",
                    padding: "16px",
                    backgroundColor: "#f0f0f088",
                    minHeight: "122px",
                    // fontWeight: 400,
                    // fontSize: "16px",
                    lineHeight: "24px",
                    letterSpacing: "-0.5px",
                }}
            />
        </Stack>
    );
});

export default TextArea;

import { useEffect, useState } from "react";
import { SideBarListItem, SideBarListItemText, SideBarWebContainer } from "./styledComponents";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    AccountTree,
    Analytics,
    Business,
    Dashboard,
    DynamicForm,
    KeyboardDoubleArrowLeft,
    KeyboardDoubleArrowRight,
    Person,
    Reviews,
    Spoke,
    PermPhoneMsg,
    BrandingWatermarkRounded,
    Category,
    Inventory,
} from "@mui/icons-material";
import { IUserInfo } from "../../../Shared/Interfaces/IUserInfo";

import { getLanguage } from "../../../Shared/Functions/language";
import { RootState } from "../../../Redux/store";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import StorefrontIcon from "@mui/icons-material/Storefront";

const urlIconsList = [
    { key: "dashboard", icon: <Dashboard color="primary" fontSize="large"></Dashboard> },
    { key: "analytics", icon: <Analytics color="primary" fontSize="large"></Analytics> },
    { key: "comparative", icon: <AccountTree color="primary" fontSize="large"></AccountTree> },
    { key: "supercompanies", icon: <Spoke color="primary" fontSize="large"></Spoke> },
    { key: "companies", icon: <Business color="primary" fontSize="large"></Business> },
    { key: "ratings", icon: <Reviews color="primary" fontSize="large"></Reviews> },
    { key: "contacts", icon: <PermPhoneMsg color="primary" fontSize="large"></PermPhoneMsg> },
    { key: "brands", icon: <BrandingWatermarkRounded color="primary" fontSize="large"></BrandingWatermarkRounded> },
    { key: "branches", icon: <StorefrontIcon color="primary" fontSize="large"></StorefrontIcon> },
    { key: "formfields", icon: <DynamicForm color="primary" fontSize="large"></DynamicForm> },
    { key: "users", icon: <Person color="primary" fontSize="large"></Person> },
    { key: "categories", icon: <Category color="primary" fontSize="large"></Category> },
    { key: "packages", icon: <Inventory color="primary" fontSize="large"></Inventory> },
];

function SideBarWeb() {
    const [showSideBar, setShowSideBar] = useState(false);
    const [expandSideBar, setExpandSideBar] = useState(true);
    const lang = getLanguage();
    useEffect(() => {
        if (window.innerWidth < 800) {
            setExpandSideBar(false);
        }
    }, []);
    const location = useLocation();
    // const dispatch = useDispatch();
    const userInfo = useSelector((state: RootState) => state.auth.userInfo) as IUserInfo;

    useEffect(() => {
        if (location.pathname.includes("login") || location.pathname.includes("signup")) {
            setShowSideBar(false);
        } else {
            setShowSideBar(true);
        }
    }, [location]);

    // function handleLogout() {
    //     clearAccessToken(dispatch);
    //     navigate("/login");
    // }

    return (
        <>
            {showSideBar === true && (
                <SideBarWebContainer style={{ minWidth: expandSideBar ? "" : "80px", overflow: expandSideBar ? "" : "hidden", }}>
                    <div
                        style={{
                            alignSelf: expandSideBar ? "flex-end" : "center",
                            cursor: "pointer"
                        }}
                        onClick={() => setExpandSideBar(!expandSideBar)}>
                        {expandSideBar && (
                            <KeyboardDoubleArrowLeft color="primary" fontSize="large"></KeyboardDoubleArrowLeft>
                        )}
                        {!expandSideBar && (
                            <KeyboardDoubleArrowRight color="primary" fontSize="large"></KeyboardDoubleArrowRight>
                        )}
                    </div>

                    {userInfo?.pages?.map((item) => {
                        const key = item.pageUrl.replace("admin/", "");
                        if ((item.pageUrl.includes('analytics') && userInfo?.package as unknown as number < 3) ||
                            (item.pageUrl.includes('ratings') && userInfo?.package as unknown as number === 1))
                            return;
                        return (
                            <SideBarListItem
                                to={item.pageUrl}
                                key={item.pageUrl}
                                style={{
                                    backgroundColor: document.location.pathname.includes(item.pageUrl) ? "#FEF6E8" : "",
                                    justifyContent: !expandSideBar ? "center" : "",
                                }}>
                                {urlIconsList.find((i) => key.includes(i.key))?.icon}
                                {expandSideBar && (
                                    <SideBarListItemText>
                                        {lang === "ar" ? item.pageNameAr : item.pageName}
                                    </SideBarListItemText>
                                )}
                            </SideBarListItem>
                        );
                    })}
                    { userInfo.companyID && 
                        <SideBarListItem
                            to={`rating/${userInfo.companyID}`}
                            style={{
                                    backgroundColor: "#d7d7d7",
                                    justifyContent: !expandSideBar ? "center" : "",
                                }}
                        >
                            <TrendingUpIcon color="primary" fontSize="large" />
                            {expandSideBar &&
                                <SideBarListItemText color='white'>
                                    {`Rate ${lang === "ar" ? userInfo.companyNameAr : userInfo.companyName}`}
                                </SideBarListItemText>
                            }
                        </SideBarListItem>
                    }
                </SideBarWebContainer>
            )}
        </>
    );
}

export default SideBarWeb;

import { Grid } from "@mui/material";

import AnalyticsSection from "./AnalyticsSection";
import AnalyticsSection1 from "./Section1";
import AnalyticsSection2 from "./Section2";
import AnalyticsSection3 from "./Section3";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";

import { RootState } from "../../../../Redux/store";
import { handleRTKError } from "../../../../Shared/Functions/RTKErrorHandler.ts";
import { useGetBranchesInfoQuery } from "../../../../features/analytics/analyticsApiSlice.ts";
import Loader from "../../../Global/Loader/index.tsx";
import { setBranchesInfo } from "../../../../Redux/Reducers/analyticsReducer.ts";
import { IUserInfo } from "../../../../Shared/Interfaces/IUserInfo.ts";
import { IBranch } from "../../../../Shared/Interfaces/IBranch.ts";

function AnalyticsPage() {
  const userInfo = useSelector(
    (state: RootState) => state.auth.userInfo
  ) as IUserInfo;

  const section3Ref = useRef<HTMLDivElement>(null);
  const categorySuggestionsRef = useRef<HTMLDivElement>(null);

  const [fromDate, toDate] = useSelector(
    (state: RootState) => state.analytics.globalDateFilter
  ) as Date[];

  const { data, isLoading, isError, error } = useGetBranchesInfoQuery({
    companyID: userInfo?.companyID,
    fromDate,
    toDate,
  });

  const colors = useSelector((state: RootState) => state.analytics.colors);
  const branches = data?.map((branch: IBranch, idx: number) => ({
    ...branch,
    color: colors[idx % colors.length],
  }));
  const dispatch = useDispatch();
  dispatch(setBranchesInfo(branches));

  useEffect(() => {
    handleRTKError(isError, error);
  }, [isError, error]);

  return isLoading ? (
    <Loader />
  ) : (
    <Grid
      container
      style={{ maxWidth: "100%", marginLeft: 0, marginTop: 0 }}
      spacing={4}
      gap={12}
      justifyContent="flex-start"
    >
      <AnalyticsSection>
        {branches?.length > 0 && 
        <AnalyticsSection1
          section3Ref={section3Ref}
          categorySuggestionsRef={categorySuggestionsRef}
          branchesInfo={branches}
        />}
      </AnalyticsSection>

      <AnalyticsSection>
        {branches?.length > 0 && <AnalyticsSection2 section3Ref={section3Ref} branchesInfo={branches} />}
      </AnalyticsSection>

      <AnalyticsSection>
        { <div ref={section3Ref}>
          <AnalyticsSection3
            categorySuggestionsRef={categorySuggestionsRef}
            branchesInfo={branches}
          />
        </div>}
      </AnalyticsSection>

    </Grid>
  );
}

export default AnalyticsPage;

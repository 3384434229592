// customerRatingApiSlice.js
import { apiSlice } from "../apiSlice";

export const customerRatingApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addRating: builder.mutation({
      query: (submission) => ({
        url: `/branches/${submission.branchID}/ratings`,
        method: "POST",
        body: submission,
      }),
    }),
    getAllReportersData: builder.query({
      query: (currentPage) => ({
        url: `/ratings/reporters?pageNumber=${currentPage}&pageSize=10`,
        method: "GET",
      }),
    }),
    getFortuneCookie: builder.query({
      query: () => ({
        url: "fortunes",
        method: "GET",
      }),
    }),
    getWheelOptions: builder.query({
      query: () => ({
        url: "wheel-options",
        method: "GET",
      }),
    }),
    // update only the customer info and the wheel option
    updateRating: builder.mutation({
      query: ({ ratingID, payload }) => ({
        url: `/ratings/${ratingID}`,
        method: "PATCH",
        body: payload,
      }),
    }),
    sendingPrizeToEmail: builder.mutation({
      query: ({ email, prizeType }) => ({
        url: "send-prize",
        method: "POST",
        body: {
          email,
          prizeType,
        },
      }),
    }),
  }),
});

export const {
  useAddRatingMutation,
  useGetAllReportersDataQuery,
  useGetFortuneCookieQuery,
  useGetWheelOptionsQuery,
  useUpdateRatingMutation,
  useSendingPrizeToEmailMutation,
} = customerRatingApiSlice;

import { IconButton } from '@mui/material';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from 'react'
import { handleRTKError } from '../../../../Shared/Functions/RTKErrorHandler';
import { IPackage } from '../../../../Shared/Interfaces/IPackage';
import CTAButton from '../../../Global/Buttons/CTAButton';
import CustomDialog from '../../../Global/CustomDialog';
import DefaultDialog from '../../../Global/DefaultDialog';
import { DefaultTableBox, DefaultTableHeaderContainer, DefaultTableHeader, DefaultTable, DefaultTableRow, DefaultTableCell } from '../../../Global/DefaultTable/styledComponents';
import Loader from '../../../Global/Loader';
import { PageHeaderContainer } from '../CompaniesPage/styledComponents';
import { useDeleteCategoryMutation, useGetAllCategoriesQuery } from '../../../../features/category/categoryApiSlice';
import AddEditCategorySideForm from './AddEditCategorySideForm';
import { ICategory } from '../../../../Shared/Interfaces/ICategory';

const CategoryPage = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteCategoryID, setDeletedCategoryID] = useState("");
  const [openSideForm, setOpenSideForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [categoryItem, setCategoryItem] = useState<IPackage | null>(null);

  const {data, refetch, isError, error, isFetching} = useGetAllCategoriesQuery("");
  const [deleteCategory, {isError: isDeleteError, error: deleteError, isLoading }] = useDeleteCategoryMutation();

  
 
  const confirmDeleteCategory = () => {
    setDialogOpen(false);
    deleteCategory(deleteCategoryID);
    refetch();
  }

  const openDialConfirmation = (categoryID: string) => {
    setDialogOpen(true);
    setDeletedCategoryID(categoryID);
  } 

  const onEditCategory = (categoryID: string) => {
    const categoryData = data.find((cat: ICategory) => cat?._id?.toString() === categoryID);
    setOpenSideForm(true);
    setIsEdit(true);
    setCategoryItem(categoryData);
  }

  const actionAfterSubmit = () => {
    setOpenSideForm(false);
    setIsEdit(false);
    refetch();
  }

   useEffect(() => {
    handleRTKError(isError, error);
  }, [isError, error]);

  useEffect(() => {
    handleRTKError(isDeleteError, deleteError);
  }, [isDeleteError, deleteError])

  return (
    <>
      {(isFetching || isLoading) && <Loader />}
      <DefaultDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        dialogTitle="Do You Want Delete This Category ?"
        dialogText="If you deleted a category, you won't able to restore it"
        confirmFn={confirmDeleteCategory}
      />
      <PageHeaderContainer>
        <CTAButton action={() => setOpenSideForm(true)} buttonText="Add Category" />
      </PageHeaderContainer>
      <DefaultTableBox>
        <DefaultTableHeaderContainer>
          <DefaultTableHeader style={{ width: "calc(100% / 3)" }}>
            Category Name
          </DefaultTableHeader>
          <DefaultTableHeader style={{ width: "calc(100% / 3)" }}>
            Category Name Ar
          </DefaultTableHeader>
          <DefaultTableHeader style={{ width: "calc(100% / 3)" }}>
            Actions
          </DefaultTableHeader>
        </DefaultTableHeaderContainer>
        <DefaultTable>
          <tbody>
            {
              data && data.map((cat: ICategory, idx: number) => (
                <DefaultTableRow key={idx}>
                  <DefaultTableCell>{cat.name}</DefaultTableCell>
                  <DefaultTableCell>{cat.nameAr}</DefaultTableCell>
                  <DefaultTableCell>
                    <IconButton onClick={() => openDialConfirmation(cat?._id?.toString() as string)}>
                      <DeleteIcon />
                    </IconButton>
                    <IconButton >
                      <EditIcon onClick={() => onEditCategory(cat?._id?.toString() as string)}/>
                    </IconButton>
                  </DefaultTableCell>
                </DefaultTableRow>
              ))
            }
          </tbody>
        </DefaultTable>
        {/* <DefaultTableFooter count={data?.pagesCount} setPageNumber={setPageNumber} /> */}
      </DefaultTableBox>

      <CustomDialog open={openSideForm} handleClose={() => {setOpenSideForm(false); setIsEdit(false)}} titleText={`${isEdit ? "Edit" : "Add"} Package`}>
        <AddEditCategorySideForm actionAfterSubmit={actionAfterSubmit} isEdit={isEdit} categoryItem={categoryItem as ICategory} />
      </CustomDialog>
    </>
  )
}

export default CategoryPage
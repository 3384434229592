import { useEffect, useState } from "react";
// import "./App.css";
import { ThemeProvider, createTheme } from "@mui/material";
import { RouterProvider } from "react-router-dom";
import RootRouter from "./Shared/Routes/rootRouter";
import {useTranslation} from "react-i18next";
import "./i18n.ts"

const mainColor = import.meta.env.VITE_MAIN_COLOR;
const themeEn = createTheme({
    typography: {
        fontFamily: ["Noto Sans", "sans-serif"].join(","),
    },
    palette: {
        primary: {
            // main: "#5F8575",
            main:  mainColor,
            light: "#EFFCEF",
            dark: "#AE6F12",
        },
        secondary: {
            main: "#F2F2F2",
            light: "#E6E6E6",
            dark: "#4D4D4D",
        },
    },
    spacing: 4,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    direction: "ltr",
});

const themeAr = createTheme({
    direction: "rtl",
    typography: {
        fontFamily: ["Tajawal", "sans-serif"].join(","),
    },
    palette: {
        primary: {
            // main: "#5F8575",
            main: mainColor,
            light: "#EFFCEF",
            dark: "#AE6F12",
        },
        secondary: {
            main: "#F2F2F2",
            light: "#E6E6E6",
            dark: "#4D4D4D",
        },
    },
    spacing: 4,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
});

// const cacheLtr = createCache({
//     key: "muiltr",
// });
//
// const cacheRtl = createCache({
//     key: "muirtl",
//     stylisPlugins: [prefixer, rtlPlugin],
// });

function App() {
    const [theme, setTheme] = useState(themeEn);
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;

    useEffect(() => {
        if (currentLanguage === "ar") {
            setTheme(themeAr);
        } else {
            setTheme(themeEn);
            document.body.setAttribute("dir", "ltr");
        }
    }, [currentLanguage]);

    return (
        <ThemeProvider theme={theme}>
                <RouterProvider router={RootRouter} />
        </ThemeProvider>
    );
}

export default App;

import { apiSlice } from '../apiSlice';


export const dashboardApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getDashboard: builder.query({
            query: () => `/dashboard`,
        }),

    }),
});

export const {
    useGetDashboardQuery,
} = dashboardApiSlice;

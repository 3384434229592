import { useEffect, useState } from 'react';
import CTAButton from '../../../Global/Buttons/CTAButton';
import { DefaultTableBox, DefaultTableHeaderContainer, DefaultTableHeader, DefaultTable, DefaultTableRow, DefaultTableCell } from '../../../Global/DefaultTable/styledComponents';
import { PageHeaderContainer } from '../CompaniesPage/styledComponents';
import { useDeletePackageMutation, useGetAllPackagesQuery } from '../../../../features/package/packageApiSlice';
import Loader from '../../../Global/Loader';
import { handleRTKError } from '../../../../Shared/Functions/RTKErrorHandler';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import DefaultDialog from '../../../Global/DefaultDialog';
import CustomDialog from '../../../Global/CustomDialog';
import AddEditPackageSideForm from './AddEditPackageSideForm';
import { IPackage } from '../../../../Shared/Interfaces/IPackage';

const PackagePage = () => {

  const [dialogOpen, setDialogOpen] = useState(false);
  const [deletePackageID, setDeletedPackageID] = useState("");
  const [openSideForm, setOpenSideForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [packageItem, setPackageItem] = useState<IPackage | null>(null);

  const {data, refetch, isError, error, isFetching} = useGetAllPackagesQuery("");
  const [deletePackage, {isError: isDeleteError, error: deleteError, isLoading }] = useDeletePackageMutation();

  
 
  const confirmDeletePackage = () => {
    setDialogOpen(false);
    deletePackage(deletePackageID);
    refetch();
  }

  const openDialConfirmation = (packageID: string) => {
    setDialogOpen(true);
    setDeletedPackageID(packageID);
  } 

  const onEditPackage = (packageID: string) => {
    const packageData = data.find((pack: IPackage) => pack?._id?.toString() === packageID);
    setOpenSideForm(true);
    setIsEdit(true);
    setPackageItem(packageData);
  }

  const actionAfterSubmit = () => {
    setOpenSideForm(false);
    setIsEdit(false);
    refetch();
  }

   useEffect(() => {
    handleRTKError(isError, error);
  }, [isError, error]);

  useEffect(() => {
    handleRTKError(isDeleteError, deleteError);
  }, [isDeleteError, deleteError])

  return (
    <>
      {(isFetching || isLoading) && <Loader />}
      <DefaultDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        dialogTitle="Do You Want Delete This Package ?"
        dialogText="If you deleted a package, you won't able to restore it"
        confirmFn={confirmDeletePackage}
      />
      <PageHeaderContainer>
        <CTAButton action={() => setOpenSideForm(true)} buttonText="Add Package" />
      </PageHeaderContainer>
      <DefaultTableBox>
        <DefaultTableHeaderContainer>
          <DefaultTableHeader style={{ width: "calc(100% / 6)" }}>
            Package
          </DefaultTableHeader>
          <DefaultTableHeader style={{ width: "calc(100% / 6)" }}>
            Branches
          </DefaultTableHeader>
          <DefaultTableHeader style={{ width: "calc(100% / 6)" }}>
            Users
          </DefaultTableHeader>
          <DefaultTableHeader style={{ width: "calc(100% / 6)" }}>
            Custom Form Fields
          </DefaultTableHeader>
          <DefaultTableHeader style={{ width: "calc(100% / 6)" }}>
            Logo
          </DefaultTableHeader>
          <DefaultTableHeader>
            Actions
          </DefaultTableHeader>
        </DefaultTableHeaderContainer>
        <DefaultTable>
          <tbody>
            {
              data && data.map((pack: IPackage, idx: number) => (
                <DefaultTableRow key={idx}>
                  <DefaultTableCell>{pack.name}</DefaultTableCell>
                  <DefaultTableCell>
                    {pack.configurations?.numberOfBranches}
                  </DefaultTableCell>
                  <DefaultTableCell>
                    {pack.configurations?.numberOfUsers}
                  </DefaultTableCell>
                  <DefaultTableCell>
                    {pack.configurations?.useDefaultForms ? "False" : "True"}
                  </DefaultTableCell>
                  <DefaultTableCell>
                    {pack.configurations?.customizeLogo ? "True" : "False"}
                  </DefaultTableCell>
                  <DefaultTableCell>
                    <IconButton onClick={() => openDialConfirmation(pack?._id?.toString() as string)}>
                      <DeleteIcon />
                    </IconButton>
                    <IconButton>
                      <EditIcon onClick={() => onEditPackage(pack?._id?.toString() as string)}/>
                    </IconButton>
                  </DefaultTableCell>
                </DefaultTableRow>
              ))
            }
          </tbody>
        </DefaultTable>
        {/* <DefaultTableFooter count={data?.pagesCount} setPageNumber={setPageNumber} /> */}
      </DefaultTableBox>

      <CustomDialog open={openSideForm} handleClose={() => {setOpenSideForm(false); setIsEdit(false)}} titleText={`${isEdit ? "Edit" : "Add"} Package`}>
        <AddEditPackageSideForm actionAfterSubmit={actionAfterSubmit} isEdit={isEdit} packageItem={packageItem as IPackage} />
      </CustomDialog>
    </>
  )
}

export default PackagePage
import { useEffect, useState } from "react";
import CustomDialog from "../../../Global/CustomDialog";
import AddEditCompanySideForm from "./AddEditCompanySideForm";
import {
    DefaultTable,
    DefaultTableBox,
    DefaultTableCell,
    DefaultTableHeader,
    DefaultTableHeaderContainer,
    DefaultTableRow,
} from "../../../Global/DefaultTable/styledComponents.tsx";
import DefaultTableFooter from "../../../Global/DefaultTable/DefaultPaginator";
import {useDispatch, useSelector} from "react-redux";
import {IUserInfo} from "../../../../Shared/Interfaces/IUserInfo";
import {ICompanyFullInfo} from "../../../../Shared/Interfaces/ICompany";
import {useNavigate} from "react-router-dom";
import Loader from "../../../Global/Loader";
import {IconButton, MenuItem} from "@mui/material";
import {PageHeaderContainer} from "./styledComponents";
import CTAButton from "../../../Global/Buttons/CTAButton";
import {userTypes} from "../../../../Shared/Constants/enums";
import {customNavigator} from "../../../../Shared/Functions/customNavigator";
import ActionsMenu from "../../../Global/ActionsMenu";
import {RootState} from "../../../../Redux/store";
import LaunchIcon from '@mui/icons-material/Launch';
import {
    useActivateDeactivateCompanyMutation,
    useGetAllCompaniesQuery
} from "../../../../features/company/companyApiSlice.ts";
import {handleRTKError} from "../../../../Shared/Functions/RTKErrorHandler.ts";
import ActiveSwitch from "../../../../features/ActiveSwitch.tsx";

function CompaniesPage() {
    const [open, setOpen] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize] = useState(10);

    const userInfo = useSelector((state: RootState) => state.auth.userInfo) as IUserInfo;
    const dispatch = useDispatch();
    const navigate = useNavigate();


    function handleMenuClick(location?: string, companyID?: string) {
        if (location === "branches") {
            customNavigator(navigate, dispatch, "/admin/branches/" + companyID);
        } else if (location === "formfields") {
            customNavigator(navigate, dispatch, "/admin/formfields/" + companyID);
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (_event: any, reason: any) => {
        if (reason && reason === "backdropClick") return;
        setOpen(false);
        getCompanies();
    };
    let queryParams = "?pageSize=" + pageSize + "&pageNumber=" + pageNumber;
    if (userInfo.userRole === userTypes.superCompanyAdminType) {
        queryParams += "&superCompanyID=" + userInfo.companyID;
    }

  const {
        data, error, isLoading,
        isError, refetch
    } = useGetAllCompaniesQuery(queryParams);
    async function getCompanies() {
        refetch();
    }
    useEffect(() => {
        handleRTKError(isError, error)
    }, [isError, error])

    useEffect(() => {
        getCompanies();
    }, [pageNumber]);

    return (
        <div>
            {isLoading && <Loader/>}
            <PageHeaderContainer>
                {userInfo.userRole === "owner" && <CTAButton action={handleClickOpen} buttonText="Add Company"/>}
            </PageHeaderContainer>
            <DefaultTableBox>
                <DefaultTableHeaderContainer>
                    <DefaultTableHeader style={{width: "calc(100% / 5)"}}>Company Name</DefaultTableHeader>
                    <DefaultTableHeader style={{width: "calc(100% / 5)"}}>Package</DefaultTableHeader>
                    <DefaultTableHeader style={{width: "calc(100% / 5)"}}>Number Of Branches</DefaultTableHeader>
                    <DefaultTableHeader style={{width: "calc(100% / 5)"}}>Active</DefaultTableHeader>
                    <DefaultTableHeader style={{width: "calc(100% / 5)"}}>Actions</DefaultTableHeader>
                    <DefaultTableHeader style={{width: "calc(100% / 5)"}}>Rate</DefaultTableHeader>
                </DefaultTableHeaderContainer>
                <DefaultTable>
                    <tbody>
                    {data?.result && data.result.map((company:ICompanyFullInfo) => {
                        const totalBranches: number = new Set(
                            (company.brands || []).flatMap(brand => brand.branchesRefs || [])
                        ).size;
                        return (
                            <DefaultTableRow key={company._id}>
                                <DefaultTableCell>{company.name}</DefaultTableCell>
                                <DefaultTableCell>{company?.packageRef?.name}</DefaultTableCell>
                                <DefaultTableCell>{totalBranches}</DefaultTableCell>
                                <DefaultTableCell>
                                    <ActiveSwitch entity={company} mutation={useActivateDeactivateCompanyMutation}/>
                                </DefaultTableCell>
                                <DefaultTableCell>
                                    <ActionsMenu>
                                        <MenuItem onClick={() => handleMenuClick("branches", company._id)}>
                                            Branches
                                        </MenuItem>
                                        <MenuItem onClick={() => handleMenuClick("formfields", company._id)}>
                                            Form Fields
                                        </MenuItem>
                                    </ActionsMenu>
                                </DefaultTableCell>
                                <DefaultTableCell>
                                    <IconButton size="medium" onClick={() => {
                                        navigate(`/rating/${company._id}`);
                                    }}>
                                        <LaunchIcon fontSize="inherit"/>
                                    </IconButton>
                                </DefaultTableCell>
                            </DefaultTableRow>
                        );
                    })}
                    </tbody>
                </DefaultTable>
                <DefaultTableFooter count={data?.pagesCount} setPageNumber={setPageNumber}/>
            </DefaultTableBox>

            {/* Dialog Area */}
            <CustomDialog open={open} handleClose={handleClose} titleText="Add Company">
                <AddEditCompanySideForm handleClose={handleClose}/>
            </CustomDialog>
        </div>
    );
}

export default CompaniesPage;

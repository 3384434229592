import { Button } from "@mui/material";

interface ICTAButtonProps {
    buttonText: string;
    action?: () => void;
    fullWidthCheck?: boolean;
    fontSize?: string;
    padding?: string;
    icon?: React.ReactNode;
    buttonType?: string;
}

function CTAButton({
    buttonText,
    action = () => {},
    fullWidthCheck = false,
    fontSize = "",
    padding = "24px 32px",
    icon,
    buttonType = "button",
}: ICTAButtonProps) {
    return (
        <Button
            id="button"
            type={buttonType === "submit" ? "submit" : "button"}
            variant="contained"
            fullWidth={fullWidthCheck}
            style={{
                borderRadius: "100px",
                textTransform: "none",
                lineHeight: "0",
                padding: padding,
                fontSize: fontSize,
                gap: "16px",
            }}
            onClick={action}>
            {icon}
            {buttonText}
        </Button>
    );
}

export default CTAButton;

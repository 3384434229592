import { Grid } from "@mui/material";
import RatingCard from "./RatingCard";
import { useEffect, useState } from "react";
import { IUserInfo } from "../../../../Shared/Interfaces/IUserInfo";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Redux/store";
import { useGetCompanyRatingsQuery } from "../../../../features/company/companyApiSlice";
import { IRating } from "../../../../Shared/Interfaces/IRating.ts";
import { handleRTKError } from "../../../../Shared/Functions/RTKErrorHandler.ts";
import Loader from "../../../Global/Loader";
import DefaultTableFooter from "../../../Global/DefaultTable/DefaultPaginator/index.tsx";

function RatingsPage() {
  const userInfo = useSelector(
    (state: RootState) => state.auth.userInfo
  ) as IUserInfo;

  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const { data, isError, isFetching, isLoading, error } =
    useGetCompanyRatingsQuery({
      companyID: userInfo.companyID,
      branchID: userInfo.branchID,
      queryParams: `?pageNumber=${currentPageNumber}&pageSize=9`,
    });

  useEffect(() => {
    handleRTKError(isError, error);
  }, [isError, error]);

  return (
    <>
      {(isLoading || isFetching) && <Loader />}
      <Grid container spacing={3} style={{ display: 'flex', flexWrap: 'wrap' }}>
        {data?.result?.map((rating: IRating, index: number) => (
          <Grid item key={index} xs={12} sm={6} md={4} lg={4}>
            <RatingCard
              branchName={rating?.branchName as string}
              avgRating={+rating?.avgRating.toFixed(2) as number}
              createdAt={rating?.createdAt as Date}
              reporterName={`${rating?.reporterFullName ?? "--"}`}
              reporterPhone={rating?.reporterPhone as string}
              comment={(rating?.comment as string) ?? "--"}
              positiveSuggestions={
                rating?.positiveSuggestions?.filter(
                  (suggestion) => suggestion
                ) as string[]
              }
              negativeSuggestions={
                rating?.negativeSuggestions?.filter(
                  (suggestion) => suggestion
                ) as string[]
              }
            />
          </Grid>
        ))}
      </Grid>
      <DefaultTableFooter
        count={data?.pagesCount ?? 1}
        setPageNumber={setCurrentPageNumber}
      />
    </>
  );
}

export default RatingsPage;

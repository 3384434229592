import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SideBar from "../Sidebar";
import "./styles.css";
import { SnackbarProvider } from "notistack";
import { LoadingProvider } from "../LoadingProvider";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { customNavigator } from "../../../Shared/Functions/customNavigator";
import Header from "../Header";

function RootLayout() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        if (location.pathname === "/admin" || location.pathname === "/admin/" || location.pathname === "/") {
            customNavigator(navigate, dispatch, "/admin/dashboard");
        }
    }, [location]);

    return (
        <SnackbarProvider
            maxSnack={4}
            preventDuplicate={true}
            autoHideDuration={3000}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}>
            <LoadingProvider>
                <div className="layoutContainer">
                    {location.pathname.startsWith("/admin") && <SideBar></SideBar>}
                    <div style={{ flexGrow: "1" }}>
                        {/* <div style={{ width: "100vw" }}> */}
                        {location.pathname.startsWith("/admin") && <Header></Header>}
                        <div
                            style={{
                                height: location.pathname.startsWith("/admin") ? "calc(100% - 82px)" : "100%",
                                display: "flex",
                                flexDirection: "column",
                                overflow: "hidden",
                            }}>
                            <div
                                className={
                                    location.pathname.startsWith("/admin")
                                        ? "adminOutletContainer"
                                        : "nonAdminOutletContainer"
                                }>
                                <Outlet></Outlet>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingProvider>
        </SnackbarProvider>
    );
}

export default RootLayout;

import { Close } from "@mui/icons-material";
import { CustomDialogHeaderDivider, CustomDialogTitle, CustomDialogTitleText } from "./styledComponents";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import { getLanguage } from "../../../Shared/Functions/language";

const dialogPaper = {
    position: "fixed",
    top: 0,
    right: 0,
    m: 0,
    minHeight: "100vh",
    maxHeight: "100vh",
    minWidth: "600px",
    maxWidth: "600px",
    overflow: "hidden",
};
function CustomDialog({
    children,
    open,
    handleClose,
    titleText,
}: {
    children: React.ReactNode;
    open: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleClose: (...params: any) => void;
    titleText: string;
}) {
    const lang = getLanguage();

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            PaperProps={{
                sx: dialogPaper,
            }}>
            <CustomDialogTitle>
                <IconButton
                    onClick={handleClose}
                    style={{ marginLeft: lang === "ar" ? "16px" : 0, marginRight: lang === "ar" ? 0 : "16px" }}>
                    <Close fontSize="large" color="secondary" />
                </IconButton>
                <CustomDialogHeaderDivider></CustomDialogHeaderDivider>
                <CustomDialogTitleText>{titleText}</CustomDialogTitleText>
            </CustomDialogTitle>

            <DialogContent style={{ maxWidth: "calc(100% - 32px)", width: "calc(100% - 32px)", padding: "24px 16px" }}>
                {children}
            </DialogContent>
        </Dialog>
    );
}

export default CustomDialog;

import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Label } from 'recharts';
import { IBranchInfo } from '../../../Shared/Interfaces/IAnalytics';


interface IPieChartGraph {
  dataGraph: IBranchInfo[];
  // colors: string[];
  totalRatings: number;
  pieDataKey: string;
}


const PieChartGraph = ({
  dataGraph,
  // colors,
  totalRatings,
  pieDataKey,
}: IPieChartGraph) => {

  const totalRatingsText = `Total Ratings ${totalRatings} Ratings`

  // branch name and it's percentage of ratings
  const renderLabel = (entry: IBranchInfo) => {
    return `${((entry.totalRatings / totalRatings) * 100).toFixed(2)}% - ${entry.name}`;
  }


  return (
    <div>
      <ResponsiveContainer width='100%' height={500} >
        <PieChart width={800} height={400}>
          <Tooltip contentStyle={{ background: "white", borderRadius: "5px" }} />
          <Pie
            data={dataGraph}
            innerRadius={"70%"}
            outerRadius={"90%"}
            fill="#8884d8"
            paddingAngle={5}
            dataKey={pieDataKey}
            label={renderLabel}
          >
            {dataGraph?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color as string} />
            ))}
          <Label width={150} position='center' style={{ fontSize: '22px', fontWeight: '700' }}>{totalRatingsText}</Label>
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  )
}

export default PieChartGraph
import {CustomDialogSubContainer} from "../../../Global/CustomDialog/styledComponents";
import DefaultForm from "../../../Global/DefaultForm";
import DefaultInput from "../../../Global/DefaultInput";
import {z} from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import {useSnackbar} from "notistack";
import {useEffect} from "react";
import {useForm} from "react-hook-form";
import CTAButton from "../../../Global/Buttons/CTAButton";
import {useSelector} from "react-redux";
import {RootState} from "../../../../Redux/store";
import {handleRTKError} from "../../../../Shared/Functions/RTKErrorHandler.ts";
import {IBrand} from "../../../../Shared/Interfaces/IBrand.ts";
import Loader from "../../../Global/Loader";
import {useAddCompanyBrandMutation, useUpdateBrandMutation} from "../../../../features/brands/brandApiSlice.ts";
import {IUserInfo} from "../../../../Shared/Interfaces/IUserInfo.ts";
import {Button} from "@mui/material";
import {UploadFile} from "@mui/icons-material";

const addUpdateBrandSchema = z.object({
    name: z.string().min(1, "Required"),
    nameAr: z.string().min(1, "Required"),
    logoPath: z.any(),
    brandAdminEmail: z.string().min(1, "Required").email("Invalid"),
    companyID: z.string().optional(),
});

type FormType = z.infer<typeof addUpdateBrandSchema>;

interface IProp {
    handleClose: () => void;
    existingBrands: number;
    brandItem?: IBrand;
    isEdit?: boolean;
}
const AddEditBrandSideForm = ({handleClose, brandItem, isEdit = false, existingBrands}: IProp) => {
    const {enqueueSnackbar} = useSnackbar();
    const userInfo = useSelector((state: RootState) => state.auth.userInfo) as IUserInfo;
    const [addBrand, {
        isLoading: isAddBrandLoading,
        isError: isAddBrandError, error: addBrandError, isSuccess: isAddBrandSuccess
    },
    ] = useAddCompanyBrandMutation();
    const [updateBrand, {isSuccess: isEditBrandSuccess}] = useUpdateBrandMutation();
    // const [finishEdit, setFinishEdit] = useState(!isEdit);

    useEffect(() => {
        handleRTKError(isAddBrandError, addBrandError);
    }, [isAddBrandError, addBrandError]);

    const canAddBrand = () => {
        if (userInfo.userRole === "owner") {
            return true
        }
        const maxBrands = userInfo.meta.package.configurations.numberOfBrands
        return maxBrands > existingBrands;
    }

    const {register, handleSubmit, setValue, formState:{errors, isValid} , reset, watch} = useForm<FormType>({
        resolver: zodResolver(addUpdateBrandSchema),
    });
    const logoPath = watch('logoPath', '');

    async function handleAddEditCBrand(formData: FormType) {
        let body = {} as IBrand;
        if (!canAddBrand()) {
            body = {} as IBrand;
            enqueueSnackbar("Can't add brand, upgrade your bundle to add more brands", {variant: 'info'});
            return;
        }
        body.logoPath = formData.logoPath;
        body.name = formData.name;
        body.nameAr = formData.nameAr;
        body.companyID = userInfo.companyID;
        body.brandAdminEmail = formData.brandAdminEmail;
        console.log(errors)
        if (isValid) {
            if (isEdit)
                updateBrand({...body, id: brandItem?._id});
            else {
                addBrand(body)
            }
        }
    }
    useEffect(() => {
        if (isEdit && brandItem) {
            setValue("name", brandItem?.name as string);
            setValue("nameAr", brandItem?.nameAr as string);
            setValue("brandAdminEmail", brandItem?.brandAdminEmail as string);
            setValue("logoPath", brandItem?.logoPath);
        }
    }, [isEdit, brandItem]);
    useEffect(() => {
        if (isAddBrandSuccess || isEditBrandSuccess) {
            enqueueSnackbar("Processed Successfully ...", {variant: "success"});
            reset();
            handleClose();
        }
    }, [isAddBrandSuccess, isEditBrandSuccess]);

    function setError(name: string, message: string) {
        return name + " is " + message;
    }


    return (
        <CustomDialogSubContainer>
            {isAddBrandLoading && <Loader/>}
            <DefaultForm onSubmit={handleSubmit(handleAddEditCBrand)}>
                <DefaultInput
                    label={"Brand Name"}
                    register={register("name")}
                    error={!!errors.name?.message}
                    errorText={errors.name?.message ? setError("Name", errors.name?.message.toString()) : ""}
                />
                <DefaultInput
                    label={"Arabic Name"}
                    register={register("nameAr")}
                    error={!!errors.nameAr?.message}
                    errorText={errors.nameAr?.message ? setError("name Ar", errors.nameAr?.message.toString()) : ""}
                />
                <DefaultInput
                    label={"Brand Admin Email"}
                    register={register("brandAdminEmail")}
                    error={!!errors.brandAdminEmail?.message}
                    errorText={errors.brandAdminEmail?.message ? setError("Brand Admin Email", errors.brandAdminEmail?.message.toString()) : ""}
                />
                <>
                    <input
                        accept="image/*"
                        style={{display: "none"}}
                        id="image"
                        type="file"
                        multiple={false}
                        {...register('logoPath')}
                    />
                    <label htmlFor="image">
                        <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            startIcon={<UploadFile/>}
                        >
                            Choose Image
                        </Button>
                    </label>
                    <p>Selected File: {logoPath[0]?.name}</p>
                </>
                <CTAButton
                    buttonText="Submit"
                    buttonType="submit"
                    fullWidthCheck
                />
            </DefaultForm>
        </CustomDialogSubContainer>
    );
}

export default AddEditBrandSideForm;

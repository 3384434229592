import { Pagination } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ChangeEvent, useState } from "react";

const DefaultTableFooterCSS = styled("div")(() => ({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "24px",
    paddingRight: "24px",
    marginTop: "16px",
    marginBottom: "16px",
    paddingBottom: "16px",
    boxSizing: "border-box"
}));


function DefaultTableFooter({count, setPageNumber}: {count: number, setPageNumber: React.Dispatch<React.SetStateAction<number>>}) {
    const [page, setPage] = useState(1);

    function handleChange(_event: ChangeEvent<unknown>, value: number){
        setPage(value);
        setPageNumber(value);
    }

    return (
        <DefaultTableFooterCSS>
            <Pagination color="primary" page={page} count={count} onChange={handleChange} />
        </DefaultTableFooterCSS>
    );
}

export default DefaultTableFooter;

import {enqueueSnackbar} from "notistack";

export const handleRTKError = (isError:boolean, error:any, isSuccess?:boolean) => {
    if (isError && error) {
        if ('status' in error) {
            const errorMessage = 'error' in error ? error.error.message : JSON.stringify(error.data.message)
            enqueueSnackbar(errorMessage ?? error.error, {variant: "error"});
        }
    }
    if (isSuccess) {
            enqueueSnackbar("success", {variant: "success"});
    }
}
import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

export const HeaderContainer = styled("div")(({ theme }) => ({
    width: "100%",
    height: "82px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingInlineEnd: theme.spacing(5),
    boxSizing: "border-box",
    backgroundColor: theme.palette.common.white,
    position: "relative",
    gap: theme.spacing(4),
  boxShadow: "0px 4px 20px rgba(153, 153, 153, 0.4)",

}));

export const UserIconBox = styled("div")(({ theme }) => ({
    width: "50px",
    height: "50px",
    borderRadius: "6px",
    backgroundColor: theme.palette.primary.light,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: theme.typography.fontFamily,
    fontSize: "32px",
    color: theme.palette.primary.main,
    textTransform: "capitalize",
    cursor: "pointer",
}));

export const UserDetailsBoxContainer = styled("div")(({ theme }) => ({
    width: "300px",
    backgroundColor: theme.palette.common.white,
    borderRadius: "8px",
    boxShadow: "0px 4px 20px rgba(153, 153, 153, 0.4)",
    position: "absolute",
    top: "66px",
    right: "82px",
    zIndex:"999999",
}));

export const UserDetailsBoxUserName = styled("div")(({ theme }) => ({
    width: "100%",
    padding: theme.spacing(8),
    fontFamily: theme.typography.fontFamily,
    fontSize: "20px",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    borderRadius: "8px",
    borderBottom: "1px solid rgba(153, 153, 153, 0.4)",
    textTransform: "capitalize",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
}));

export const UserDetailsBoxBottomListItem = styled("div")(({ theme }) => ({
    width: "100%",
    padding: theme.spacing(5),
    fontFamily: theme.typography.fontFamily,
    fontSize: "20px",
    color: "rgba(153, 153, 153)",
    backgroundColor: theme.palette.common.white,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
}));


export const AnalyticsFilterContainer = styled(Stack)(({theme}) => ({
    flexGrow: 1,
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
}))

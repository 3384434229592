import { Menu, MenuItem, Button, Stack, Typography, Box } from "@mui/material"
import { useEffect, useState } from "react"
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { DefaultDatePicker } from "../../../../Global/DefaultDatePicker/styledComponents";
import dayjs, { Dayjs } from "dayjs";
import { enqueueSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalFilterDate } from "../../../../../Redux/Reducers/analyticsReducer";
import { IUserInfo } from "../../../../../Shared/Interfaces/IUserInfo";
import { userTypes } from "../../../../../Shared/Constants/enums";
import { Tag } from "../../../../Global/Tag/styledComponents";
import { RootState } from "../../../../../Redux/store";
import {useGetBranchesInfoQuery} from "../../../../../features/analytics/analyticsApiSlice.ts";
import {handleRTKError} from "../../../../../Shared/Functions/RTKErrorHandler.ts";


enum FilterOptions {
  AllTime = 1,
  Today = 2,
  Yesterday = 3,
  Week = 4,
  Month = 5,
  Year = 6
}

const GlobalAnalyticsFilter = () => {


  const dispatch = useDispatch();    
  const userInfo = useSelector((state: RootState) => state.auth.userInfo) as IUserInfo;


  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl);

  const [tag, setTag] = useState<string | null>('All Times')

  let fromDate: null | Dayjs = null;
  let toDate: null | Dayjs = null;

  const getBranchesInfo = async () => {

        let companyID: string | number = '';
        switch (userInfo.userRole) {
        case userTypes.superCompanyAdminType:
            case userTypes.companyAdminType:
            companyID = userInfo.companyID as string;
                break;
            case userTypes.brandAdminType:
            break;
            case userTypes.branchAdminType:
            break;
            default:
                break;
        }

      const { isError, error } = useGetBranchesInfoQuery({
          companyID,
          userType: userInfo.userRole,
          fromDate: fromDate?.toDate(),
          toDate: toDate?.toDate(),
      });

      useEffect(() => {
          handleRTKError(isError, error);
      }, [isError, error]);

  }

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
  }

  const handleFromDateSelected = (selectedFromDate: Dayjs | Date | string | unknown) => {
    fromDate = (selectedFromDate as Dayjs).startOf('day');
  }

  const handleToDateSelected = (selectedToDate: Dayjs | Date | string | unknown) => {
    toDate = (selectedToDate as Dayjs).endOf('day');
  }

  const handleFilterClicked = (customFilter?: boolean) => {
    if (!fromDate || !toDate){
      enqueueSnackbar('Filter Dates are Invalid', {variant: 'error'});
      return;
    }
    
    getBranchesInfo();
    if (!customFilter) setTag(`${fromDate.format('DD/MM/YYYY')} - ${toDate.format('DD/MM/YYYY')}`)
    dispatch(setGlobalFilterDate([fromDate.toDate(), toDate.toDate()]))
    handleMenuClose();
    fromDate = null;
    toDate = null;
  }

  const handleFilterOption = (option: FilterOptions) => {

    toDate = dayjs(new Date()).endOf('day');

    switch(option){
      case FilterOptions.Today:
        fromDate = dayjs(new Date()).startOf('day');
        setTag('Today');
        break;
      case FilterOptions.Yesterday:
        fromDate = dayjs(new Date()).subtract(1, 'day').startOf('day');
        toDate = fromDate.endOf('day');
        setTag('Yesterday');
        break;
      case FilterOptions.Week:
        fromDate = toDate.subtract(1, 'week').add(1, 'second');
        setTag('Week');
        break;
      case FilterOptions.Month:
        fromDate = toDate.subtract(1, 'month').add(1, 'second');
        setTag('Month');
        break;
      case FilterOptions.Year:
        fromDate = toDate.subtract(1, 'year').add(1, 'second');
        setTag('Year');
        break;
      case FilterOptions.AllTime:
      default:
        fromDate = dayjs(new Date(1970, 0, 1));
        setTag('All Times');
        break;
    }

    handleFilterClicked(true);
  }

  useEffect(() => {
    handleFilterOption(FilterOptions.AllTime);
  }, []);

  return (
    <Stack direction='row-reverse' gap={4}>
      <Button 
        disableRipple
        variant="contained" 
        size="large" 
        onClick={handleButtonClick}
        endIcon={<CalendarMonthIcon />}

      >Filter</Button>
      <Menu 
        anchorEl={anchorEl} 
        open={open} 
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem 
          divider={true}  
          sx={{
            '&:hover' : {
              backgroundColor: 'transparent'
            }
          }}
        >
          <Stack gap={4}>
            <Stack direction='row' gap={4}>
              <Stack sx={{
                width: '200px'
              }}>
                <Typography variant="subtitle1">From</Typography>
                <DefaultDatePicker onChange={handleFromDateSelected} maxDate={dayjs(new Date())}  />
              </Stack>
              <Stack sx={{
                width: '200px'
              }}>
                <Typography variant="subtitle1">To</Typography>
                <DefaultDatePicker onChange={handleToDateSelected} maxDate={dayjs(new Date())} />
              </Stack>
            </Stack>
            <Button 
              disableRipple 
              variant="outlined"
              onClick={() => {
                handleFilterClicked();
              }}
            >Filter</Button>
          </Stack>
        </MenuItem>
        <MenuItem
        onClick={() => handleFilterOption(FilterOptions.AllTime)}>All Times</MenuItem>
        <MenuItem 
        onClick={() => handleFilterOption(FilterOptions.Today)}>Today</MenuItem>
        <MenuItem 
        onClick={() => handleFilterOption(FilterOptions.Yesterday)}>yesterday</MenuItem>
        <MenuItem 
        onClick={() => handleFilterOption(FilterOptions.Week)}>This Week</MenuItem>
        <MenuItem 
        onClick={() => handleFilterOption(FilterOptions.Month)}>This Month</MenuItem>
        <MenuItem 
        onClick={() => handleFilterOption(FilterOptions.Year)}>This Year</MenuItem>
      </Menu>

      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Tag>{tag}</Tag>
      </Box>
    </Stack>
  )
}

export default GlobalAnalyticsFilter
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { CustomDialogSubContainer } from "../../../Global/CustomDialog/styledComponents";
import DefaultInput from "../../../Global/DefaultInput";
import DefaultForm from "../../../Global/DefaultForm";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import CTAButton from "../../../Global/Buttons/CTAButton";
import { DefaultDatePicker } from "../../../Global/DefaultDatePicker/styledComponents";
import { FormControlLabel, IconButton, Switch, TextField } from "@mui/material";
import { DefaultAutocomplete } from "../../../Global/DefaultAutocomplete/styledComponents";

import {
  AddEditCompanyFormListsContainer,
  AddEditCompanyFormListsSubContainer,
} from "./styledComponents";
import { Delete } from "@mui/icons-material";
import { ICompanyFullInfo } from "../../../../Shared/Interfaces/ICompany";
import { useSnackbar } from "notistack";
import Loader from "../../../Global/Loader";
import { SubTitleText } from "../../../Global/Text/SubtitleText";
import { IBrand } from "../../../../Shared/Interfaces/IBrand";
import { IBranch } from "../../../../Shared/Interfaces/IBranch";
import { useAddCompanyMutation } from "../../../../features/company/companyApiSlice.ts";
import { useGetAllPackagesQuery } from "../../../../features/package/packageApiSlice.ts";
import { useEffect, useState } from "react";
import { handleRTKError } from "../../../../Shared/Functions/RTKErrorHandler.ts";

const brandSchema = z.object({
  name: z.string().min(1, "Required"),
  nameAr: z.string().min(1, "Required"),
  iVoiceUpURLCode: z.string(),
  email: z.string(),
});
const branchSchema = z.object({
  name: z.string().min(1, "Required"),
  nameAr: z.string().min(1, "Required"),
  googleMapsURL: z.string(),
  brandID: z.object({}).required(),
  email: z.string(),
});

const addUpdateCompanySchema = z.object({
  name: z.string().min(1, "Required"),
  nameAr: z.string().min(1, "Required"),
  email: z.string().min(5, "Required"),
  package: z.object({}),
  subStartDate: z.date().optional(),
  subEndDate: z.date().optional(),
  isSubbedToIVoiceUp: z.boolean().default(false),
  iVoiceUpURL: z.string().optional(),

  numberOfBrands: z.string().default("1"),
  numberOfBranches: z.string().default("1"),
  numberOfUsers: z.string().default("1"),
  useCustomForms: z.boolean().default(false),
  customizeLogo: z.boolean().default(false),
  // branchCanHaveShifts: z.boolean().default(false),

  brand: z.array(brandSchema),
  branch: z.array(branchSchema),
});

function AddEditCompanySideForm({
  handleClose = () => {},
}: {
  handleClose?: (_event?: any, reason?: any) => void;
}) {
  const [brandID, setBrandID] = useState(0);
  const [
    addCompany,
    {
      isLoading: isAddCompanyLoading,
      isError: isAddCompanyError,
      error: addCompanyError,
      isSuccess: isAddCompanySuccess,
    },
  ] = useAddCompanyMutation();
  useEffect(() => {
    handleRTKError(isAddCompanyError, addCompanyError);
  }, [isAddCompanyError, addCompanyError]);
  useEffect(() => {
    if (isAddCompanySuccess) {
      enqueueSnackbar("Added Successfully ...", { variant: "success" });
      reset();
      handleClose();
    }
  }, [isAddCompanySuccess]);
  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    getValues,
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    resolver: zodResolver(addUpdateCompanySchema),
  });
  const {
    fields: brandFields,
    append: brandAppend,
    remove: brandRemove,
  } = useFieldArray({
    control,
    name: "brand",
  });

  const {
    fields: branchFields,
    append: branchAppend,
    remove: branchRemove,
  } = useFieldArray({
    control,
    name: "branch",
  });

  const showIVoiceUpURL = useWatch({
    name: "isSubbedToIVoiceUp",
    control: control,
  });
  const companyPackage = useWatch({ name: "package", control: control });
  const brandsCreated = useWatch({ name: "brand", control: control });

  function setBrands(brands: any[], branches: any[]) {
    const newBrands = [] as IBrand[];

    brands.forEach((brand, index) => {
      const newBrand = {} as IBrand;
      // newBrand.brandID = index;
      newBrand.brandName = brand.name;
      newBrand.brandNameAr = brand.nameAr;
      newBrand.iVoiceUpURLCode = brand.iVoiceUpURLCode;
      newBrand.brandAdminEmail = brand.email;

      const brandBranches = branches?.filter(
        (branch) => branch.brandID.index === index
      );

      newBrand.branchesRefs = setBranches(brandBranches);
      newBrands.push(newBrand);
    });
    return newBrands;
  }
  function setBranches(branches: any[]) {
    const newBranches = [] as IBranch[];
    branches.forEach((branch) => {
      const newBranch = {} as IBranch;
      newBranch.name = branch.name;
      newBranch.nameAr = branch.nameAr;
      newBranch.locationURL = branch.googleMapsURL;
      newBranch.isActive = true;
      newBranch.branchAdminEmail = branch.email;
      newBranches.push(newBranch);
    });
    return newBranches;
  }

  function handleAddEditCompany() {
    const formData = getValues();
    const body = {} as ICompanyFullInfo;
    body.name = formData.name;
    body.nameAr = formData.nameAr;
    body.companyAdminEmail = formData.email;
    body.subscriptionStartDate = new Date(formData.subStartDate);
    body.subscriptionEndDate = new Date(formData.subEndDate);
    body.isSubcribedToIVoiceUp = formData.isSubbedToIVoiceUp;
    if (body.isSubcribedToIVoiceUp && !formData.iVoiceUpURL) {
      enqueueSnackbar("Must Include iVoiceUp URL if Company is Subscribed.", {
        variant: "error",
      });
      return;
    }
    body.iVoiceUpURL = formData.iVoiceUpURL;
    body.isActive = true;
    body.packageRef = companyPackage; // companyPackage.id || companyPackage._id;

    body.brands = setBrands([...formData.brand], [...formData.branch]);

    const totalBranches = body.brands.reduce(
      (total: number, brand: IBrand) => total + brand.branchesRefs.length,
      0
    );
    if (
      totalBranches > companyPackage?.configurations?.numberOfBranches ||
      (companyPackage?.name?.toLowerCase()?.includes("custom") &&
        totalBranches > +formData.numberOfBranches)
    ) {
      enqueueSnackbar(
        "Number Of Branches Exceed Limit. Please Remove Extra Branches",
        { variant: "error" }
      );
      return;
    }
    if (
      body.brands.length > companyPackage?.configurations?.numberOfBrands ||
      (companyPackage?.name?.toLowerCase()?.includes("custom") &&
        body.brands.length > +formData.numberOfBrands)
    ) {
      enqueueSnackbar(
        "Number Of Brands Exceed Limit. Please Remove Extra Brands",
        { variant: "error" }
      );
      return;
    }
    body.configuration = {
      numberOfBrands: +formData.numberOfBrands,
      numberOfBranches: +formData.numberOfBranches,
      numberOfUsers: +formData.numberOfUsers,
      // branchCanHaveShifts: formData.branchCanHaveShifts,
      customizeLogo: formData.customizeLogo,
      useDefaultForms: !formData.useCustomForms, // || true,
    };
    console.log(errors);

    if (isValid) {
      console.log("THE BODY : ", body);
      addCompany(body);
    }
  }

  function setError(name: string, message: string) {
    return name + " is " + message;
  }

  function addBranch() {
    const branchCount = companyPackage?.configurations?.numberOfBranches;
    if (branchFields?.length < branchCount) {
      branchAppend({ name: "New Branch", googleMapsURL: "" });
    } else {
      enqueueSnackbar("Can't Add Any More Branches", { variant: "error" });
    }
  }
  function addBrand() {
    const brandCount = companyPackage?.configurations?.numberOfBrands;
    if (brandFields?.length < brandCount) {
      setBrandID((brandID) => brandID + 1);
      brandAppend({
        name: "New Brand",
        nameAr: "",
        iVoiceUpURLCode: "",
        index: brandID,
      });
    } else {
      enqueueSnackbar("Can't Add Any More Brands", { variant: "error" });
    }
  }
  const { data: packages } = useGetAllPackagesQuery({});
  return (
    <CustomDialogSubContainer>
      {isAddCompanyLoading && <Loader />}
      <DefaultForm onSubmit={handleSubmit(handleAddEditCompany)}>
        <DefaultInput
          label={"Name"}
          register={register("name")}
          error={!!errors.name?.message}
          errorText={
            errors.name?.message
              ? setError("Company Name", errors.name?.message.toString())
              : ""
          }
        />
        <DefaultInput
          label={"NameAr"}
          register={register("nameAr")}
          error={!!errors.nameAr?.message}
          errorText={
            errors.nameAr?.message
              ? setError("Company Name Ar", errors.nameAr?.message.toString())
              : ""
          }
        />
        <Controller
          control={control}
          name="subStartDate"
          render={({ field }) => (
            <DefaultDatePicker
              label="Subscription Start Date"
              onChange={(date: any) => field.onChange(date.$d)}
            />
          )}
        ></Controller>
        <Controller
          control={control}
          name="subEndDate"
          render={({ field }) => (
            <DefaultDatePicker
              label="Subscription End Date"
              onChange={(date: any) => field.onChange(date.$d)}
            />
          )}
        ></Controller>
        <DefaultInput
          label={"Company Admin Email"}
          register={register("email")}
          error={!!errors.email?.message}
        />
        <FormControlLabel
          control={<Switch />}
          {...register("isSubbedToIVoiceUp")}
          label="Subscribed To iVoiceUp"
        />
        {showIVoiceUpURL === true && (
          <DefaultInput
            label={"iVoiceUp URL"}
            register={register("iVoiceUpURL")}
          />
        )}
        <Controller
          control={control}
          name="package"
          render={({ field }) =>
            packages && (
              <DefaultAutocomplete
                disablePortal
                id="combo-box-demo"
                options={packages}
                getOptionLabel={(item: any) => item.name}
                value={field.value}
                onChange={(_, newValue) => {
                  field.onChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    error={!!errors.package?.message}
                    helperText={
                      errors.package?.message
                        ? setError(
                            "Package",
                            errors.package?.message.toString()
                          )
                        : ""
                    }
                    {...params}
                    label="Package"
                  />
                )}
              />
            )
          }
        ></Controller>
        {/* Area For Custom Package */}
        {companyPackage?.name?.toLowerCase().includes("custom") && (
          <>
            <DefaultInput
              label={"Number Of Brands"}
              register={register("numberOfBrands")}
              type="number"
            />
            <DefaultInput
              label={"Number Of Branches"}
              register={register("numberOfBranches")}
              type="number"
            />
            <DefaultInput
              label={"Number Of Users"}
              register={register("numberOfUsers")}
              type="number"
            />
            <FormControlLabel
              control={<Switch defaultChecked={true} />}
              {...register("useCustomForms")}
              label="Use Custom Forms"
            />
            <FormControlLabel
              control={<Switch defaultChecked={true} />}
              {...register("customizeLogo")}
              label="Customize Logo"
            />
            {/* <FormControlLabel
                            control={<Switch/>}
                            {...register("branchCanHaveShifts")}
                            label="Branches Can Have Shifts"
                        /> */}
          </>
        )}
        {/* Area For Custom Package */}
        {companyPackage !== null && companyPackage !== undefined && (
          <>
            {/* Area For Brands */}
            <SubTitleText>Brands</SubTitleText>
            {brandFields.map((_field, index: number) => {
              return (
                <AddEditCompanyFormListsContainer key={index}>
                  <AddEditCompanyFormListsSubContainer>
                    <DefaultInput
                      label={"Name"}
                      register={register("brand." + index + ".name")}
                    />
                    <DefaultInput
                      label={"NameAr"}
                      register={register("brand." + index + ".nameAr")}
                      // @ts-ignore
                      //   error={!!errors?.brand?.[index]?.nameAr?.message}
                      //   // @ts-ignore
                      //   errorText={
                      //     errors?.["brand"]?.[index]?.nameAr?.message
                      //       ? (setError(
                      //           "branch Name Ar",
                      //           errors?.brand?.[index]?.nameAr?.message.toString()
                      //         ),
                      //         "Error occurred")
                      //       : ""
                      //   }
                    />
                    <DefaultInput
                      label={"Brand Admin Email"}
                      register={register("brand." + index + ".email")}
                    />
                    {showIVoiceUpURL && (
                      <DefaultInput
                        label={"iVoiceUp URL Code"}
                        register={register(
                          "brand." + index + ".iVoiceUpURLCode"
                        )}
                      />
                    )}
                  </AddEditCompanyFormListsSubContainer>
                  <IconButton
                    onClick={() => {
                      brandRemove(index);
                    }}
                  >
                    <Delete color="error" />
                  </IconButton>
                </AddEditCompanyFormListsContainer>
              );
            })}
            <CTAButton
              fullWidthCheck={false}
              action={addBrand}
              buttonText="Add New Brand"
            />
            {/* Area For Brands */}
            {/* Area For Branches */}
            <SubTitleText>Branches</SubTitleText>
            {branchFields.map((_field, index) => {
              return (
                <AddEditCompanyFormListsContainer key={index}>
                  <AddEditCompanyFormListsSubContainer>
                    <DefaultInput
                      label={"Name"}
                      register={register("branch." + index + ".name")}
                    />
                    <DefaultInput
                      label={"Arabic Name"}
                      register={register(`branch.${index}.nameAr`)}
                      // @ts-ignore
                      //   error={!!errors?.branch?.[index]?.nameAr?.message}
                      //   // @ts-ignore
                      //   errorText={
                      //     errors?.branch?.[index]?.nameAr?.message
                      //       ? (setError(
                      //           "branch Name Ar",
                      //           errors?.branch?.[
                      //             index
                      //           ]?.nameAr?.message.toString()
                      //         ),
                      //         "Error occurred")
                      //       : ""
                      //   }
                    />
                    <DefaultInput
                      label={"Google Maps Link"}
                      register={register("branch." + index + ".googleMapsURL")}
                    />
                    {brandsCreated?.length > 0 && (
                      <Controller
                        control={control}
                        name={"branch." + index + ".brandID"}
                        render={({ field }) => (
                          <DefaultAutocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={brandsCreated}
                            getOptionLabel={(item: any) => item.name}
                            value={field.value}
                            onChange={(_event: any, newValue: any) => {
                              field.onChange(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Brand" />
                            )}
                          />
                        )}
                      ></Controller>
                    )}
                    <DefaultInput
                      label={"Branch Admin Email"}
                      register={register("branch." + index + ".email")}
                    />
                  </AddEditCompanyFormListsSubContainer>
                  <IconButton
                    onClick={() => {
                      branchRemove(index);
                    }}
                  >
                    <Delete color="error" />
                  </IconButton>
                </AddEditCompanyFormListsContainer>
              );
            })}

            <CTAButton
              fullWidthCheck={false}
              action={() => {
                addBranch();
              }}
              buttonText="Add New Branch"
            />
          </>
        )}
        <CTAButton buttonText="Submit" buttonType="submit" fullWidthCheck />
      </DefaultForm>
    </CustomDialogSubContainer>
  );
}

export default AddEditCompanySideForm;

import { createSlice } from "@reduxjs/toolkit";
import { IBranchDropdown, ICustomerFormField } from "../../Shared/Interfaces/ICustomerRatingPage";

export const ratingPageSlice = createSlice({
    name: "ratingPage",
    initialState: {
        ratingPageCompanyName: "",
        ratingPageCompanyNameAr: "",
        ratingPageBranches: [] as IBranchDropdown[],
        ratingPageFormFields: [] as ICustomerFormField[],
    },
    reducers: {
        setCompanyName: (state, action) => {
            state.ratingPageCompanyName = action.payload.name;
            state.ratingPageCompanyNameAr = action.payload.nameAr;
        },
        setRatingPageBranches: (state, action) => {
            state.ratingPageBranches = action.payload;
        },
        setFormFields: (state, action) => {
            state.ratingPageFormFields = action.payload;
        },
        setRatingValue: (state, action) => {
            state.ratingPageFormFields[action.payload.index].ratingProvided = action.payload.ratingProvided;
        },
        setSuggestionBool: (state, action) => {
            const suggestionsName = (action.payload.posneg === "positive" || action.payload.posneg === "pos") ? 
                "positiveSuggestions" : "negativeSuggestions"
            
            const suggestions = state.ratingPageFormFields[action.payload.index][suggestionsName].map((suggestion, idx) => {
                return {...suggestion, selected: action.payload.suggestions[idx]};
            });
            state.ratingPageFormFields[action.payload.index][suggestionsName] = suggestions;
        },
    },
});

export const { setCompanyName, setRatingPageBranches, setFormFields, setRatingValue, setSuggestionBool } =
    ratingPageSlice.actions;

export default ratingPageSlice.reducer;

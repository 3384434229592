import { Box, Typography } from "@mui/material";

const EvalurateCopyRight = () => {
  return (
    <Box>
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: "14px",
          textAlign: "center",
          color: "#5C5C5C",
          lineHeight: "20px",
          paddingTop: "10px",
        }}
      >
        © Evalurate {new Date().getFullYear()}. All Rights Reserved
      </Typography>
    </Box>
  )
}

export default EvalurateCopyRight;
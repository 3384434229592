import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { TitleText } from "../../../../Global/Text/TitleText";
import { DashboardDataWhiteContainer } from "../styledComponents";

const mainColor = import.meta.env.VITE_MAIN_COLOR;

function DashboardBarchart({
    data,
    xAxisDataKey,
    barDataKey,
    titleText,
}: {
    data: {[key: string]: string | number}[];
    xAxisDataKey: string;
    barDataKey: string;
    titleText: string;
}) {
    return (
        <DashboardDataWhiteContainer>
            <TitleText style={{ marginInlineStart: "16px" }}>{titleText}</TitleText>
            <ResponsiveContainer>
                <BarChart width={600} height={275} data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={xAxisDataKey} />
                    <YAxis />
                    <Tooltip />
                    <Bar cursor="pointer" maxBarSize={120} dataKey={barDataKey} fill={mainColor} />
                </BarChart>
            </ResponsiveContainer>
        </DashboardDataWhiteContainer>
    );
}

export default DashboardBarchart;

import {useEffect, useState} from 'react'
import {CustomDialogSubContainer} from '../../../Global/CustomDialog/styledComponents'
import Loader from '../../../Global/Loader'
import DefaultInput from '../../../Global/DefaultInput'
import DefaultForm from '../../../Global/DefaultForm'
import CTAButton from '../../../Global/Buttons/CTAButton'
import {useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {z} from 'zod'
import {FormControlLabel, Switch, Typography} from '@mui/material'
import {IPackage} from '../../../../Shared/Interfaces/IPackage'
import {useAddPackageMutation, useUpdatePackageMutation} from '../../../../features/package/packageApiSlice'
import {handleRTKError} from '../../../../Shared/Functions/RTKErrorHandler'

const addUpdateUserSchema = z.object({
    packageName: z.string().min(1, "Required"),
    packageNameAr: z.string().min(1, "Required"),
    numberOfBrands: z.string().optional(),
    numberOfBranches: z.string().optional(),
    numberOfUsers: z.string().optional(),
    customeFormFields: z.boolean().default(true).optional(),
    customeLogo: z.boolean().default(false).optional(),
    branchCanHaveShifts: z.boolean().default(false).optional(),
});

type FormType = z.infer<typeof addUpdateUserSchema>;

interface IProp {
    actionAfterSubmit: () => void;
    packageItem?: IPackage;
    isEdit?: boolean;
}

const AddEditPackageSideForm = ({actionAfterSubmit, packageItem, isEdit = false}: IProp) => {

    const [customeFormFields, setCustomeFormFields] = useState(false);
    const [customeLogo, setCustomeLogo] = useState(false);
    const [branchCanHaveShifts, setBranchCanHaveShifts] = useState(false);
    const [finishEdit, setFinishEdit] = useState(!isEdit);

    const [addPackage, {isError, error, isLoading, isSuccess}] = useAddPackageMutation();
    const [updatePackage] = useUpdatePackageMutation();

    const {register, handleSubmit, getValues, setValue, formState: {isValid}} = useForm<FormType>({
        resolver: zodResolver(addUpdateUserSchema),
    });


    const handleAddEditPackage = () => {
        const payload: IPackage = {
            name: getValues('packageName'),
            nameAr: getValues('packageNameAr'),
            configurations: {
                numberOfBrands: getValues('numberOfBrands') as unknown as number,
                numberOfBranches: getValues('numberOfBranches') as unknown as number,
                numberOfUsers: getValues('numberOfUsers') as unknown as number,
                useDefaultForms: !customeFormFields,
                customizeLogo: customeLogo,
                branchCanHaveShifts: branchCanHaveShifts,
            }
        };
        console.log(payload);
        if (isValid) {
            if (isEdit)
                updatePackage({...payload, id: packageItem?.id});
            else {
                addPackage(payload);
            }
        }
    }
    useEffect(() => {
        if (isSuccess) {
            actionAfterSubmit();
        }
    }, [isSuccess])
    useEffect(() => {

        if (isEdit && packageItem) {
            setValue("packageName", packageItem?.name as string);
            setValue("packageNameAr", packageItem?.nameAr as string);
            setValue("numberOfBrands", packageItem?.configurations.numberOfBrands as unknown as string);
            setValue("numberOfBranches", packageItem?.configurations.numberOfBranches as unknown as string);
            setValue("numberOfUsers", packageItem?.configurations.numberOfUsers as unknown as string);
            setCustomeFormFields(!packageItem?.configurations.useDefaultForms);
            setCustomeLogo(packageItem?.configurations.customizeLogo as boolean);
            setBranchCanHaveShifts(packageItem?.configurations.branchCanHaveShifts as boolean);
            setFinishEdit(true);
        }
    }, [isEdit, packageItem]);

    useEffect(() => {
        handleRTKError(isError, error);
    }, [isError, error])

    return (
        <>
            {isLoading && <Loader/>}
            {finishEdit &&
                <CustomDialogSubContainer>
                    {/* {isAddUserLoading && <Loader/>} */}
                    <DefaultForm onSubmit={handleSubmit(handleAddEditPackage)}>
                        <DefaultInput
                            label={"Package Name"}
                            register={register("packageName")}
                        />
                        <DefaultInput
                            label={"Last Name"}
                            register={register("packageNameAr")}

                        />
                        <Typography variant='h5'>Configurations</Typography>
                        <DefaultInput
                            label={"Number Of Brands"}
                            register={register("numberOfBrands")}
                        />
                        <DefaultInput
                            label={"Number Of Branches"}
                            register={register("numberOfBranches")}
                        />
                        <DefaultInput
                            label={"Number Of Users"}
                            register={register("numberOfUsers")}
                        />
                        <FormControlLabel
                            control={<Switch defaultChecked={customeFormFields}
                                             onChange={(e) => setCustomeFormFields(e.target.checked)}/>}
                            label="Can use custom form fields"
                        />
                        <FormControlLabel
                            control={<Switch defaultChecked={customeLogo}
                                             onChange={(e) => setCustomeLogo(e.target.checked)}/>}
                            label="Custom Logo"
                        />
                        <FormControlLabel
                            control={<Switch defaultChecked={branchCanHaveShifts}
                                             onChange={(e) => setBranchCanHaveShifts(e.target.checked)}/>}
                            label="Can branches have shifts"
                        />

                        <CTAButton
                            buttonText="Submit"
                            buttonType="submit"
                            fullWidthCheck
                        />
                    </DefaultForm>
                </CustomDialogSubContainer>
            }
        </>
    )
}

export default AddEditPackageSideForm
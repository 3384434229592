import { Box, Rating, Stack, Typography } from "@mui/material";
import { Card } from "./styledComponents";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { SingleUnselectedSuggestionsContainer } from "../../../CustomerPages/CustomerRatingPage/styledComponents";
import { Tag } from "../../../../Global/Tag/styledComponents";

interface IRatingCard {
  branchName: string;
  avgRating: number;
  createdAt: Date;
  reporterName: string;
  reporterPhone: string;
  positiveSuggestions: string[];
  negativeSuggestions: string[];
  comment: string;
}

const RatingCard = ({
  branchName,
  avgRating,
  createdAt,
  reporterName,
  reporterPhone,
  positiveSuggestions = [],
  negativeSuggestions = [],
  comment,
}: IRatingCard) => {
  return (
    <Card height={"100%"}>
      <Stack direction="column">
        <Tag>
          {new Intl.DateTimeFormat('en-US', {
            year: 'numeric', 
            month: 'short',
            day: 'numeric', 
            hour: 'numeric', 
            minute: 'numeric', 
            second: 'numeric', 
            // timeZoneName: 'short'
          }).format(new Date(createdAt))}
        </Tag>
        <br/>
        <Stack direction="row" alignItems="center" gap={2}>
          <StorefrontIcon fontSize="large" />
          <Typography variant="h4" sx={{ fontWeight: "700" }}>
            {branchName}
          </Typography>
        </Stack>
        <Stack direction="row" gap={2}>
          <Rating value={avgRating} precision={0.1} readOnly />
          <Typography variant="body1" color="gray">
            {avgRating}
          </Typography>
        </Stack>
      </Stack>
      <Stack>
        <Stack direction="column" gap={2}>
          <Typography variant="subtitle1">Reporter Name: </Typography>
          <Typography variant="h6" sx={{ paddingLeft: 4, fontWeight: "700" }}>
            {reporterName ?? "--"}
          </Typography>
        </Stack>
        <Stack direction="column" gap={2}>
          <Typography variant="subtitle1">Reporter Phone: </Typography>
          <Typography variant="h6" sx={{ paddingLeft: 4, fontWeight: "700" }}>
            {reporterPhone ?? "--"}
          </Typography>
        </Stack>
      </Stack>
      <Stack gap={4}>
        <Typography variant="h6">Positive Suggestions</Typography>
        <Stack
          direction="row"
          gap={2}
          sx={{
            flexWrap: "wrap",
          }}
        >
          {positiveSuggestions.map((sug, idx) => {
            return (
              <SingleUnselectedSuggestionsContainer key={idx} className={"positive"}>
                {sug ?? "--"}
              </SingleUnselectedSuggestionsContainer>
            );
          })}
        </Stack>
      </Stack>
      <Stack gap={4}>
        <Typography variant="h6">Negative Suggestions</Typography>
        <Stack
          direction="row"
          gap={2}
          sx={{
            flexWrap: "wrap",
          }}
        >
          {negativeSuggestions.map((sug, idx) => {
            return (
              <SingleUnselectedSuggestionsContainer key={idx} className={'negative'}>
                {sug ?? "--"}
              </SingleUnselectedSuggestionsContainer>
            );
          })}
        </Stack>
      </Stack>
      <Stack>
        <Typography variant="h6">Comments</Typography>
        <Box
          sx={{
            border: "1px solid #acd3e3",
            padding: 2,
            borderRadius: 2,
          }}
        >
          {comment ?? "--"}
        </Box>
      </Stack>
    </Card>
  );
};

export default RatingCard;

import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

interface ITitleText {
    fontSize?: string;
}

export const TitleText = styled(Typography, {
    shouldForwardProp: () => true,
    name: 'TitleText',
    slot: 'root'
})<ITitleText>(({ fontSize = "32px", theme }) => ({
    fontSize: fontSize,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.secondary.dark,
}));
